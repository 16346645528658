import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Redux States
import { GET_IMPORT, LIST_IMPORT } from "./actionType";

import {
  listImportSuccess,
  listImportFail,
  getImportDetailsSuccess,
  getImportDetailsFail,
  getImportSuccess,
  getImportFail,
} from "./action";

import { getImportDetailsAPI, listImportsAPI } from "../../../../helpers/service_helper";

function* listImport({ payload: { ...res } }) {
  try {
    const response = yield call(listImportsAPI, { ...res });
    yield put(listImportSuccess(response));
  } catch (error) {
    yield put(listImportFail(error));
  }
}

function* getImport({ payload: id }) {
  try {
    const response = yield call(getImportDetailsAPI, id);
    yield put(getImportSuccess(response));
  } catch (error) {
    yield put(getImportFail(error));
  }
}

export function* watchGetImports() {
  yield takeEvery(LIST_IMPORT, listImport);
}

export function* watchGetImport() {
  yield takeEvery(GET_IMPORT, getImport);
}

function* importsSaga() {
  yield all([fork(watchGetImports), fork(watchGetImport)]);
}

export default importsSaga;
