import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row } from "reactstrap";

import withRouter from "../../../../Components/Common/withRouter";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";

import { listInstalledChannel, listSaleChannel } from "../../../../store/actions";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import { useDebounce } from "../../../../Components/Hooks/UseDebounce";
import SearchInput from "../../../../Components/Common/SearchInput";
import { value } from "jsonpath";
import ListContainerCustom from "../../../../Components/Common/ListContainerCustom";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import ActionDropdown from "../../../../Components/Common/ActionDropdown";
import { ChannelStatusOptions } from "../../../../Components/constants/common";
import Skeleton from "../../../../Components/Common/Skeleton";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";

const SaleChannelPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  const dispatch = useDispatch();
  document.title = `${t("Channel List")} | OptiWarehouse`;

  const [viewMode, setViewMode] = useState("grid");
  const types = [
    { name: "All", id: "ALL" },
    { name: "eCommerce", id: "ECOMMERCE" },
    { name: "OMS", id: "OMS" },
    { name: "WMS", id: "WMS" },
    { name: "PIM", id: "PIM" },
    { name: "ERP", id: "ERP" },
    { name: "Marketplace", id: "MARKETPLACE" },
    { name: "Marketing", id: "MARKETING" },
    { name: "Payment", id: "PAYMENT" },
    { name: "Invoice", id: "INVOICE" },
    { name: "Shipping provider", id: "SHIPPING_PROVIDER" },
  ];
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [query, setQuery] = useState("");
  const debouncedInputValue = useDebounce(query, 1000);
  const [filteredChannels, setFilteredChannels] = useState([]);

  useEffect(() => {
    dispatch(listSaleChannel({}));
  }, [dispatch]);

  const { saleChannels, saleChannelsLoading, saleChannelsSuccess, saleChannelsError } = useMemoizedSelector(
    (state) => ({
      saleChannels: state.SaleChannels.saleChannels,
      saleChannelsLoading: state.SaleChannels.loading,
      saleChannelsSuccess: state.SaleChannels.success,
      saleChannelsError: state.SaleChannels.error,
    }),
  );

  const {
    installedChannels,
    installedChannelsLoading,
    installedChannelsSuccess,
    installedChannelsError,
    installedChannelsLimit,
    installedChannelsPage,
    installedChannelsTotal,
  } = useMemoizedSelector((state) => ({
    installedChannels: state.InstalledChannels.installedChannels,
    installedChannelsLoading: state.InstalledChannels.loading,
    installedChannelsSuccess: state.InstalledChannels.success,
    installedChannelsError: state.InstalledChannels.error,
    installedChannelsLimit: state.InstalledChannels.limit,
    installedChannelsPage: state.InstalledChannels.page,
    installedChannelsTotal: state.InstalledChannels.total,
  }));

  useEffect(() => {
    !saleChannelsLoading && setFilteredChannels(saleChannels);
  }, [saleChannels, saleChannelsLoading]);

  useEffect(() => {
    const newData = saleChannels.filter((item) => {
      if (selectedTypes.length === 0) {
        return item.name.includes(debouncedInputValue);
      } else {
        return item.name.includes(debouncedInputValue) && selectedTypes.includes(item.channel_type);
      }
    });
    setFilteredChannels(newData);
  }, [debouncedInputValue, selectedTypes]);

  const renderChannelCard = (channel) => (
    <Card key={channel.key} className={`border ${viewMode === "list" ? "tw-flex" : ""}`}>
      <CardHeader className={viewMode === "list" ? "tw-flex-shrink-0" : ""}>
        <CardTitle className="tw-flex tw-items-center tw-justify-center">
          <ImageCustom image={channel.logo} name={channel.name} avatarSize={"avatar-md"} isPreview={false} />
        </CardTitle>
      </CardHeader>
      <CardBody className={viewMode === "list" ? "tw-flex-grow" : ""}>
        <h3 className="tw-text-lg tw-font-semibold">{channel.name}</h3>
        <p className="tw-text-sm tw-text-gray-600">{channel.description}</p>
        <ButtonTheme
          className="btn btn-primary"
          onClick={() => {
            navigate(`/channels/${channel.key}/new`);
          }}
        >
          <i className="ri-add-fill me-1 align-bottom"></i>
          {t("Install")}
        </ButtonTheme>
      </CardBody>
    </Card>
  );

  const renderSkeletonCard = () => (
    <Card className={`border ${viewMode === "list" ? "tw-flex" : ""}`}>
      <CardHeader className={viewMode === "list" ? "tw-flex-shrink-0" : ""}>
        <CardTitle className="tw-flex tw-items-center tw-justify-center">
          <div className="tw-w-16 tw-h-16 tw-rounded-full tw-bg-gray-200 tw-animate-pulse" />
        </CardTitle>
      </CardHeader>
      <CardBody className={viewMode === "list" ? "tw-flex-grow" : ""}>
        <div className="tw-h-6 tw-w-3/4 tw-bg-gray-200 tw-rounded tw-mb-2 tw-animate-pulse" />
        <div className="tw-h-4 tw-w-full tw-bg-gray-200 tw-rounded tw-mb-4 tw-animate-pulse" />
        <div className="tw-h-10 tw-w-1/3 tw-bg-gray-200 tw-rounded tw-animate-pulse" />
      </CardBody>
    </Card>
  );

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="sale-channel-list">
              <CardHeader>
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Channel List")}</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="tw-flex tw-justify-between tw-mb-4">
                  <div className="tw-relative tw-w-64">
                    <SearchInput
                      onChangeValue={(value) => {
                        setQuery(value);
                      }}
                      searchValue={query}
                      onDeleteSearchValue={() => setQuery("")}
                      placeholder={"Search channels"}
                    />
                  </div>
                  <div className="tw-flex tw-space-x-2">
                    <ButtonTheme
                      className={`btn btn-icon btn-light flex-shrink-0 border ${
                        viewMode === "grid" ? "border-primary text-primary" : ""
                      }`}
                      onClick={() => setViewMode("grid")}
                    >
                      <i className={`ri-grid-line`}></i>
                    </ButtonTheme>
                    <ButtonTheme
                      className={`btn btn-icon btn-light flex-shrink-0 border ${
                        viewMode === "list" ? "border-primary text-primary" : ""
                      }`}
                      onClick={() => setViewMode("list")}
                    >
                      <i className={`ri-list-check`}></i>
                    </ButtonTheme>
                  </div>
                </div>
                <div className="tw-flex tw-flex-wrap tw-gap-2 tw-mb-4">
                  {types.map((type) => (
                    <ButtonTheme
                      key={type.id}
                      className={`btn btn-light border ${
                        (selectedTypes.length === 0 && type.id === "ALL") || selectedTypes.includes(type.id)
                          ? "border-primary text-primary"
                          : ""
                      }`}
                      onClick={() => {
                        if (type.id === "ALL") {
                          setSelectedTypes([]);
                        } else {
                          if (selectedTypes.includes(type.id)) {
                            setSelectedTypes(selectedTypes.filter((item) => item !== type.id));
                          } else {
                            setSelectedTypes([...selectedTypes, type.id]);
                          }
                        }
                      }}
                    >
                      {t(type.name)}
                    </ButtonTheme>
                  ))}
                </div>
                <div className={viewMode === "grid" ? "tw-grid tw-grid-cols-3 tw-gap-4" : "tw-space-y-4"}>
                  {saleChannelsLoading && saleChannels.length === 0
                    ? Array.from({ length: 3 }).map((_, index) => (
                        <Fragment key={index}>{renderSkeletonCard()}</Fragment>
                      ))
                    : filteredChannels.length === 0
                    ? "No data"
                    : filteredChannels.map((channel, index) => (
                        <Fragment key={index}>{renderChannelCard(channel)}</Fragment>
                      ))}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card id="installed-channel-list">
              <CardHeader>
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Connection List")}</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <TableContainerCustom
                  filterType={"connections"}
                  searchPlaceHolder="Search connections..."
                  listFilter={[
                    {
                      id: "status",
                      type: "selectBox",
                      title: `${t("Status")}`,
                      dataOption: ChannelStatusOptions,
                    },
                  ]}
                  headers={["image", "name", "status", "url", "created_at", "updated_at", "action"]}
                  contents={installedChannels}
                  limitPage={Number(installedChannelsLimit)}
                  currentPage={Number(installedChannelsPage) + 1}
                  totalItems={installedChannelsTotal}
                  loading={installedChannelsLoading}
                  showPagination={true}
                  scrollHeight="50vh"
                  onCallData={(dataPayload) => {
                    dispatch(listInstalledChannel(dataPayload));
                  }}
                  isSettings={true}
                  listHeader={[
                    {
                      title: t("Connection details"),
                      key: "detail-connection",
                      children: [
                        {
                          title: t("Image"),
                          key: "image",
                          dataIndex: "image",
                          render: (value, data, index) => {
                            return (
                              <ImageCustom image={value} name={data.name} avatarSize={"avatar-xs"} isPreview={false} />
                            );
                          },
                        },
                        { title: t("Name"), dataIndex: "name", key: "name", sorter: true },
                        {
                          title: t("Status"),
                          dataIndex: "status",
                          key: "status",
                          sorter: true,
                          render: (value, data, index) => {
                            return <BadgeCustom status={value} />;
                          },
                        },
                        {
                          title: t("Url"),
                          dataIndex: "url",
                          key: "url",
                          render: (value, data, index) => {
                            return value ?? "---";
                          },
                        },
                        {
                          title: t("Created At"),
                          dataIndex: "created_at",
                          key: "created_at",
                          sorter: true,
                          defaultSortOrder: "desc",
                        },
                        { title: "Updated at", dataIndex: "updated_at", key: "updated_at", sorter: true },
                        {
                          title: t("Action"),
                          dataIndex: "action",
                          key: "action",
                          render: (_, data, index) => {
                            return (
                              <ActionDropdown
                                index={index}
                                actions={[
                                  {
                                    type: "edit",
                                    title: "Configure",
                                    onClick: () => {
                                      navigate(`/channels/connections/${data.id}`);
                                    },
                                  },
                                ]}
                              />
                            );
                          },
                        },
                      ],
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(SaleChannelPage);
