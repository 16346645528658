import {
  LIST_NOTIFICATION,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
  LIST_NOTIFICATION_FAIL,
  LIST_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION,
  GET_NOTIFICATION_FAIL,
  GET_NOTIFICATION_SUCCESS,
  RESET_NOTIFICATION_STATE,
} from "./actionType";

export const listNotification = (options) => ({
  type: LIST_NOTIFICATION,
  payload: options,
});

export const listNotificationFail = (error) => ({
  type: LIST_NOTIFICATION_FAIL,
  payload: error,
});

export const listNotificationSuccess = (notifications) => ({
  type: LIST_NOTIFICATION_SUCCESS,
  payload: notifications,
});

export const getNotification = (notificationID) => ({
  type: GET_NOTIFICATION,
  payload: notificationID,
});

export const getNotificationFail = (error) => ({
  type: GET_NOTIFICATION_FAIL,
  payload: error,
});

export const getNotificationSuccess = (notification) => ({
  type: GET_NOTIFICATION_SUCCESS,
  payload: notification,
});

export const deleteNotification = (notification, handleAfterSubmit = () => {}) => ({
  type: DELETE_NOTIFICATION,
  payload: { notification, handleAfterSubmit },
});

export const deleteNotificationSuccess = (notification) => ({
  type: DELETE_NOTIFICATION_SUCCESS,
  payload: notification,
});

export const deleteNotificationFail = (error) => ({
  type: DELETE_NOTIFICATION_FAIL,
  payload: error,
});

export const resetNotificationState = () => ({
  type: RESET_NOTIFICATION_STATE,
});
