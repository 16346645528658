import React from "react";
import { useTranslation } from "react-i18next";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

const defaultIcons = {
  view: "ri-eye-fill",
  delete: "ri-delete-bin-fill",
  edit: "ri-edit-2-line",
  add: "ri-add-fill",
  settings: "ri-settings-5-line",
  default: "ri-close-circle-fill",
};

const ActionDropdown = ({ actions, type = "light" }) => {
  const { t } = useTranslation();

  const getIcon = (action) => action?.customIcon || defaultIcons[action?.type] || defaultIcons.default;

  const renderActionItem = (action, index) => {
    if (action.hide) return null;

    const icon = getIcon(action);
    const title = action.title
      ? t(action.title)
      : action.type
      ? t(action.type.charAt(0).toUpperCase() + action.type.slice(1))
      : "";
    const isDelete = action.type === "delete";

    return (
      <React.Fragment key={index}>
        <DropdownItem onClick={action.onClick} className="d-flex align-items-center">
          <i className={`${icon} align-bottom me-2 ${isDelete ? "text-danger" : ""} color-sub-text-secondary fs-18`} />
          <span className="color-sub-text fw-medium">{title}</span>
        </DropdownItem>
        {index !== actions.length - 1 && <DropdownItem divider />}
      </React.Fragment>
    );
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle className={`btn btn-ghost-${type} btn-sm`} tag="div">
        <i className="ri-more-fill text-dark" />
      </DropdownToggle>
      <DropdownMenu container="body">{actions.map(renderActionItem)}</DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ActionDropdown;
