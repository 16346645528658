import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { isEmpty } from "lodash";

import AddEditCustomerModal from "./AddEditCustomerModal";
import withRouter from "../../../../Components/Common/withRouter";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { Actions } from "../../../../Components/constants/common";

import { deleteCustomer, listCustomer } from "../../../../store/actions";
import FullNameRender from "../../../../Components/Common/FullnameRender";
import ActionDropdown from "../../../../Components/Common/ActionDropdown";
import { Link } from "react-router-dom";
import { formatVNDCurrency } from "../../../../helpers/format_helper";
import ImportCSVModal from "../../../../Components/Common/ImportCSVModal";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import LogoTheme from "../../../../assets/images/logo-theme.png";

const CustomerListPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Customer List")} | OptiWarehouse`;
  const dispatch = useDispatch();

  const {
    customers,
    customersAction,
    customersLoading,
    customersMessage,
    customersSuccess,
    customersError,
    customersLimit,
    customersPage,
    customersTotal,
  } = useMemoizedSelector((state) => ({
    customers: state.Customers.customers,
    customersAction: state.Customers.action,
    customersLoading: state.Customers.loading,
    customersSuccess: state.Customers.success,
    customersError: state.Customers.error,
    customersMessage: state.Customers.message,
    customersLimit: state.Customers.limit,
    customersTotal: state.Customers.total,
    customersPage: state.Customers.page,
  }));

  const { saleChannels } = useMemoizedSelector((state) => ({
    saleChannels: state.SaleChannels.saleChannels,
  }));
  const { version } = useMemoizedSelector((state) => ({
    version: state.Version.version,
  }));
  const [lightLogo, setLightLogo] = useState(null);
  useEffect(() => {
    setLightLogo(version?.data?.dict?.shop_info?.setting_value?.store_logo_light?.url ?? LogoTheme);
  }, [version]);


  // modal state
  const [deleteModal, setDeleteModal] = useState(false);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [isImportCSV, setIsImportCSV] = useState(false);
  const [showEditAddCustomerModal, setShowEditAddCustomerModal] = useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleDeleteCustomer = () => {
    dispatch(deleteCustomer(selectedCustomer, handleAfterDelete));
  };
  const handleAfterDelete = () => {
    setSelectedCustomer(null);
    setDeleteModal(false);
  };
  const onClickDelete = (data) => {
    setSelectedCustomer(data);
    setDeleteModal(true);
  };
  const onClose = () => {
    if (!customersLoading) {
      setSelectedCustomer(null);
      setDeleteModal(false);
    }
  };
  const onClickEdit = (data) => {
    setSelectedCustomer(data);
    setShowEditAddCustomerModal(true);
  };
  const toggleEditAddCustomerModal = () => {
    if (!customersLoading) {
      setShowEditAddCustomerModal(false);
      setSelectedCustomer(null);
    }
  };

  return (
    <Fragment>
      <div className="page-content">
        <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={customers} />
        <ImportCSVModal
          show={isImportCSV}
          onCloseClick={() => setIsImportCSV(false)}
          pathUrl="import_customer_by_xlsx_file"
          title={t("Import customer data")}
        />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteCustomer}
          loading={customersLoading && customersAction === Actions.DELETE}
          onCloseClick={onClose}
        />
        <AddEditCustomerModal
          isOpen={showEditAddCustomerModal}
          toggle={toggleEditAddCustomerModal}
          customerId={selectedCustomer?.id}
        />
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">{t("Customer List")}</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-3 flex-wrap">
                        <ButtonTheme
                          type="button"
                          className="btn btn-light"
                          icon="ri-external-link-fill"
                          onClick={() => {
                            setIsImportCSV(true);
                          }}
                        >
                          {t("Import")}
                        </ButtonTheme>
                        <ButtonTheme
                          type="button"
                          className="btn btn-light"
                          icon="ri-file-text-line"
                          onClick={() => setIsExportCSV(true)}
                        >
                          {t("Export")}
                        </ButtonTheme>
                        <ButtonTheme
                          className="btn btn-primary"
                          id="create-btn"
                          icon="ri-add-line text-white"
                          onClick={() => {
                            setShowEditAddCustomerModal(true);
                          }}
                        >
                          {t("Add Customer")}
                        </ButtonTheme>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <TableContainerCustom
                    filterType={"customers"}
                    searchPlaceHolder="Search customers..."
                    listFilter={[
                      {
                        id: "source.channel_name",
                        type: "selectBox",
                        title: `${t("Source")}`,
                        dataOption: [
                          {
                            id: "system",
                            name: version?.data?.dict?.shop_info?.setting_value?.store_name || "OptiWarehouse",
                          },
                          ...(saleChannels ?? []).map((item) => {
                            return { id: item.key, name: item.name };
                          }),
                        ],
                      },
                      {
                        id: "customer_group.id",
                        type: "selectBox",
                        title: "Customer group",
                        remote: true,
                        pathUrlLoad: "actor/customer_groups",
                      },
                    ]}
                    headers={[
                      "source",
                      "customer",
                      "phone",
                      "customer_group",
                      "total_spent",
                      "purchased_order",
                      "created_at",
                      "action",
                    ]}
                    contents={customers}
                    limitPage={Number(customersLimit)}
                    currentPage={Number(customersPage) + 1}
                    totalItems={customersTotal}
                    loading={customersLoading && customersAction === Actions.LIST}
                    showPagination={true}
                    scrollHeight="50vh"
                    onCallData={(dataPayload) => {
                      dispatch(listCustomer(dataPayload));
                    }}
                    isSettings={true}
                    listHeader={[
                      {
                        title: t("Customer details"),
                        key: "detail-variant",
                        children: [
                          {
                            title: t("Source"),
                            dataIndex: "source",
                            width: "70px",
                            key: "source",
                            render: (value, row, index) => {
                              const saleChannel = saleChannels.find((item) => item.key === row?.source?.channel_name);
                              if (saleChannel) {
                                return (
                                  <ImageCustom
                                    image={saleChannel?.logo}
                                    name={saleChannel?.name}
                                    avatarSize={"avatar-xs"}
                                    isPreview={false}
                                  />
                                );
                              } else {
                                return (
                                  <ImageCustom
                                    image={lightLogo}
                                    name={row?.source?.name}
                                    avatarSize={"avatar-xs"}
                                    isPreview={false}
                                  />
                                );
                              }
                            },
                          },
                          {
                            title: t("Customer"),
                            key: "customer",
                            dataIndex: "first_name",
                            sorter: true,
                            render: (_, data, index) => {
                              return (
                                <Link
                                  to={`/customers/${data.id}`}
                                  className="text-truncate d-block h5 fs-14 mb-0 link-primary"
                                >
                                  <FullNameRender first_name={data.first_name} last_name={data.last_name} />
                                </Link>
                              );
                            },
                          },
                          { title: t("Phone"), dataIndex: "phone", key: "phone" },
                          {
                            title: t("Customer group"),
                            dataIndex: "customer_group",
                            key: "customer_group",
                            sorter: true,
                            render: (value, data, index) => {
                              return <>{value?.name ?? "---"}</>;
                            },
                          },
                          {
                            title: t("Total spent"),
                            dataIndex: "total_spent",
                            key: "total_spent",
                            render: (value, data, index) => {
                              return <>{formatVNDCurrency(data?.sale_order?.total_spent ?? 0)}</>;
                            },
                          },
                          {
                            title: t("Purchased order"),
                            dataIndex: "purchased_order",
                            key: "purchased_order",
                            render: (value, data, index) => {
                              return <>{data?.sale_order?.purchased_order ?? 0}</>;
                            },
                          },
                          {
                            title: t("Created At"),
                            dataIndex: "created_at",
                            key: "created_at",
                            sorter: true,
                            defaultSortOrder: "desc",
                          },
                          {
                            title: t("Action"),
                            dataIndex: "action",
                            key: "action",
                            render: (_, data, index) => {
                              return (
                                <ActionDropdown
                                  index={index}
                                  actions={[
                                    {
                                      type: "edit",
                                      onClick: () => {
                                        onClickEdit(data);
                                      },
                                    },
                                    {
                                      type: "delete",
                                      onClick: () => {
                                        onClickDelete(data);
                                      },
                                    },
                                  ]}
                                />
                              );
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};
export default withRouter(CustomerListPage);
