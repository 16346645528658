import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useTranslation, withTranslation } from "react-i18next";
import TableContainerCustom from "./TableContainerCustom";
import { useDispatch } from "react-redux";
import { Actions } from "../constants/common";
import { convertValidDate, convertValidTime } from "../../helpers/format_helper";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import useMemoizedSelector from "../Hooks/useMemoizedSelector";
import { listActivity } from "../../store/actions";

const ActivitiesModal = ({ isOpen, toggle, itemId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    activities,
    activitiesLoading,
    activitiesAction,
    activitiesSuccess,
    activitiesError,
    activitiesLimit,
    activitiesPage,
    activitiesTotal,
    activitiesMessage,
  } = useMemoizedSelector((state) => ({
    activities: state.Activities.activities,
    activitiesAction: state.Activities.action,
    activitiesMessage: state.Activities.message,
    activitiesLoading: state.Activities.loading,
    activitiesSuccess: state.Activities.success,
    activitiesError: state.Activities.error,
    activitiesLimit: state.Activities.limit,
    activitiesPage: state.Activities.page,
    activitiesTotal: state.Activities.total,
  }));

  const headers = [
    {
      title: t("Id"),
      dataIndex: "id",
      key: "id",
      render: (value, row, index) => {
        return (
          <>
            {value === "None" ? (
              "---"
            ) : (
              <Tooltip title={value}>
                <Link to={`/products/${value}`} className="fw-medium link-primary text-truncate-two-lines">
                  {value || "---"}
                </Link>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: t("Sku"),
      dataIndex: "sku",
      key: "sku",
      sorter: true,
      render: (value, row, index) => {
        return <>{value || "---"}</>;
      },
    },
    {
      title: t("Available"),
      dataIndex: "available",
      key: "available",
      sorter: true,
      render: (value, row, index) => {
        return <>{value || "---"}</>;
      },
    },
    {
      title: t("Incoming"),
      dataIndex: "incoming",
      key: "incoming",
      sorter: true,
      render: (value, row, index) => {
        return <>{value || "---"}</>;
      },
    },
    {
      title: t("On hand"),
      dataIndex: "on_hand",
      key: "on_hand",
      sorter: true,
      render: (value, row, index) => {
        return <>{value || "---"}</>;
      },
    },
    {
      title: t("Packing"),
      dataIndex: "packing",
      key: "packing",
      sorter: true,
      render: (value, row, index) => {
        return <>{value || "---"}</>;
      },
    },
    {
      title: t("Updated at"),
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: true,
      render: (value, row, index) => {
        return (
          <>
            {convertValidDate(value)},<small className="text-muted"> {convertValidTime(value)}</small>
          </>
        );
      },
    },
    {
      title: t("Created At"),
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: "desc",
      sorter: true,
      render: (value, row, index) => {
        return (
          <>
            {convertValidDate(value)},<small className="text-muted"> {convertValidTime(value)}</small>
          </>
        );
      },
    },
  ];
  return (
    <Modal fade={true} isOpen={isOpen} toggle={toggle} centered={true} size="lg">
      <ModalHeader>{t("Activities")}</ModalHeader>
      <ModalBody>
        <Link
          className="link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover"
          to={"/settings/activities"}
        >
          {t("View all activities")}
        </Link>{" "}
        <TableContainerCustom
          headers={headers}
          contents={activities}
          limitPage={Number(activitiesLimit)}
          currentPage={Number(activitiesPage) + 1}
          totalItems={activitiesTotal}
          loading={activitiesLoading && activitiesAction === Actions.LIST}
          showPagination={true}
          scrollHeight="50vh"
          onCallData={(dataPayload) => {
            dispatch(listActivity({ ...dataPayload, id: itemId }));
          }}
        />
      </ModalBody>
    </Modal>
  );
};

ActivitiesModal.propTypes = {
  toggle: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default ActivitiesModal;
