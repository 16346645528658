import { Actions } from "../../../../Components/constants/common";
import {
  LIST_SYNCRECORD,
  LIST_SYNCRECORD_SUCCESS,
  LIST_SYNCRECORD_FAIL,
  GET_IMPORT_SYNCRECORD,
  GET_IMPORT_SYNCRECORD_SUCCESS,
  GET_IMPORT_SYNCRECORD_FAIL,
} from "./actionType";

const INIT_STATE = {
  syncRecords: [],
  importSyncRecord: [],
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const SyncRecords = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_SYNCRECORD:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_SYNCRECORD_SUCCESS:
      return {
        ...state,
        message: "List sync records success",
        loading: false,
        success: true,
        syncRecords: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_SYNCRECORD_FAIL:
      return {
        ...state,
        message: "List sync records failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_IMPORT_SYNCRECORD:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_IMPORT_SYNCRECORD_SUCCESS:
      return {
        ...state,
        message: "Get import sync record success",
        loading: false,
        success: true,
        importSyncRecord: action.payload?.data.channels ?? [],
        action: Actions.EMPTY,
      };

    case GET_IMPORT_SYNCRECORD_FAIL:
      return {
        ...state,
        message: "Get import sync record failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    default:
      return { ...state };
  }
};

export default SyncRecords;
