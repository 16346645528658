import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";

import { GET_IMPORT_SYNCRECORD, LIST_SYNCRECORD } from "./actionType";

import {
  listSyncRecordSuccess,
  listSyncRecordFail,
  getImportSyncRecordSuccess,
  getImportSyncRecordFail,
} from "./action";

import { getListSyncRecordsAPI, getSyncRecordAPI } from "../../../../helpers/service_helper";

function* listSyncRecord({ payload: { ...res } }) {
  try {
    const response = yield call(getListSyncRecordsAPI, { ...res });
    yield put(listSyncRecordSuccess(response));
  } catch (error) {
    yield put(listSyncRecordFail(error));
  }
}

function* getImportSyncRecord({ payload: { ...res } }) {
  try {
    const response = yield call(getSyncRecordAPI, res);
    yield put(getImportSyncRecordSuccess(response));
  } catch (error) {
    yield put(getImportSyncRecordFail(error));
  }
}

export function* watchGetImportSyncRecord() {
  yield takeEvery(GET_IMPORT_SYNCRECORD, getImportSyncRecord);
}

export function* watchGetSyncRecords() {
  yield takeEvery(LIST_SYNCRECORD, listSyncRecord);
}

function* syncRecordsSaga() {
  yield all([fork(watchGetSyncRecords), fork(watchGetImportSyncRecord)]);
}

export default syncRecordsSaga;
