import { Form, Formik } from "formik";
import React, { useRef } from "react";
import ScrollToError from "../../../../Components/Common/ScrollToError";
import { Card, CardBody, CardHeader } from "reactstrap";
import SelectTheme from "../../../../Components/Common/SelectTheme";
import languagesCountry from "../../../../common/data/languagesCountry";
import InputTheme from "../../../../Components/Common/InputTheme";
import { t } from "i18next";
import { Popover } from "antd";
import CKEditorCustom from "../../../../Components/Common/CKEditorCustom";
import { deepCopy } from "../../../../helpers/format_helper";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { addNewBlog, updateBlog } from "../../../../store/actions";
import { useNavigate, useParams } from "react-router-dom";

export default function FormBlogLanguage({ formRef, isCreate = false, dataDefault = {} }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const handleChangeMetaData = (field, value, meta_data) => {
    const metaData = deepCopy(meta_data);
    if (value) {
      metaData[field] = value;
    } else {
      delete metaData[field];
    }
    formRef.current.setFieldValue("meta_data", metaData);
  };
  const validation = {
    enableReinitialize: true,
    initialValues: {
      title: dataDefault?.title ?? "",
      content: dataDefault?.content ?? "",
      description: dataDefault?.description ?? "",
      tags: dataDefault?.tags ?? null,
      slug: dataDefault?.slug ?? null,
      meta_data: dataDefault?.meta_data ?? null,
      locale: dataDefault?.locale ?? null,
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t("Please Enter Blog Title")),
      content: Yup.string().required(t("Please Enter Blog Content")),
      ...(isCreate ? { locale: Yup.string().required(t("Please Enter Blog Locale")) } : {}),
    }),
    onSubmit: async (values) => {
      if (!values.tags) {
        values.meta_data && (values.meta_data.keywords = null);
      } else {
        values.meta_data && (values.meta_data.keywords = values.tags);
      }
      if (id) {
        values.org_blog_id = id;
      }
      if (isCreate) {
        dispatch(
          addNewBlog(values, (data) => {
            navigate(`/blogs/${data}`, { state: { idBlog: id } });
          }),
        );
      } else {
        dispatch(
          updateBlog({ ...dataDefault, ...values }, (data) => {
            navigate(`/blogs/${data}`, { state: { idBlog: id } });
          }),
        );
      }
    },
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={validation.initialValues}
        validationSchema={validation.validationSchema}
        onSubmit={validation.onSubmit}
        innerRef={formRef}
      >
        {({ values, setFieldValue, errors, touched }) => {
          return (
            <Form>
              <ScrollToError />
              <div className="d-flex gap-3">
                <div className="flex-grow-1">
                  <Card id="basic-information">
                    <CardBody>
                      <div id="blog-images" className="hstack gap-5 mb-3">
                        <div className="flex-1 col-6">
                          {isCreate && (
                            <SelectTheme
                              name="locale"
                              placeholder={"Select blog language"}
                              options={languagesCountry.map((item) => ({ label: item.name, value: item.code }))}
                              label={t("Language")}
                            />
                          )}
                          <InputTheme name={"title"} placeholder="Enter blog title" label={"Title"} />
                        </div>
                      </div>
                      <InputTheme name={"description"} placeholder="Enter blog description" label={"Description"} />

                      <div id="blog-content" className="mb-3">
                        <h5 className="fs-14 mb-1 hstack justify-content-between">
                          {t("Content")}
                          <Popover
                            content={
                              <div style={{ width: "850px", height: "400px", overflow: "auto" }}>
                                {values?.content ? (
                                  <div className="ck-content" dangerouslySetInnerHTML={{ __html: values?.content }} />
                                ) : (
                                  ""
                                )}
                              </div>
                            }
                            title={t("Preview")}
                            placement="right"
                          >
                            <div className="hstack gap-2">
                              <span>
                                <i className="ri-eye-fill align-bottom color-sub-text-secondary fs-18" />
                              </span>
                            </div>
                          </Popover>
                        </h5>
                        <div
                          style={{
                            border: `1px solid ${errors.description && touched.description ? "#f06548" : "#d1d1d1"}`,
                            backgroundColor: "#fff",
                          }}
                        >
                          <CKEditorCustom
                            config={{
                              language: "vi",
                              resize: {
                                minHeight: "300px", // Set minimum height
                                maxHeight: "600px", // Set maximum height
                              },
                            }}
                            imagePrefix="blogs"
                            value={values?.content}
                            onChangeValue={(data) => {
                              setFieldValue("content", data);
                            }}
                          />
                        </div>
                        {errors.content && touched.content && (
                          <div className="position-absolute fs-10" style={{ color: "#f06548" }}>
                            {errors.content}
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <h5 className="card-title">{t("SEO")}</h5>
                      <div className="mb-3">
                        <InputTheme name={"slug"} placeholder={"this-is-blog-tile"} label={"Slug"} />
                      </div>
                      <div className="mb-3">
                        <InputTheme
                          id="title_tag"
                          isForm={false}
                          placeholder="Enter Title Tag"
                          label={"Title Tag"}
                          value={values.meta_data?.title}
                          onChange={(value) => {
                            handleChangeMetaData("title", value, values.meta_data || {});
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <InputTheme
                          id="description_tag"
                          isForm={false}
                          placeholder="Enter Description Tag"
                          label={"Description Tag"}
                          value={values.meta_data?.description}
                          onChange={(value) => {
                            handleChangeMetaData("description", value, values.meta_data || {});
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <InputTheme
                          name={"tags"}
                          label={"Keywords Tag"}
                          type="tags"
                          placeholder={"Enter keywords and press enter"}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
