import {
  GET_IMPORT,
  GET_IMPORT_FAIL,
  GET_IMPORT_SUCCESS,
  LIST_IMPORT,
  LIST_IMPORT_FAIL,
  LIST_IMPORT_SUCCESS,
} from "./actionType";

export const listImport = (options) => ({
  type: LIST_IMPORT,
  payload: options,
});

export const listImportFail = (error) => ({
  type: LIST_IMPORT_FAIL,
  payload: error,
});

export const listImportSuccess = (imports) => ({
  type: LIST_IMPORT_SUCCESS,
  payload: imports,
});

export const getImport = (import_id) => ({
  type: GET_IMPORT,
  payload: import_id,
});

export const getImportFail = (error) => ({
  type: GET_IMPORT_FAIL,
  payload: error,
});

export const getImportSuccess = (importData) => ({
  type: GET_IMPORT_SUCCESS,
  payload: importData,
});
