const languagesCountry = [
  {
    "name": "Abkhaz",
    "code": "ab",
    "flag": "https://flagcdn.com/ge.svg" // Georgia 
  },
  {
    "name": "Afar",
    "code": "aa", 
    "flag": "https://flagcdn.com/et.svg" // Ethiopia
  },
  {
    "name": "Afrikaans",
    "code": "af",
    "flag": "https://flagcdn.com/za.svg" // South Africa
  },
  {
    "name": "Akan",
    "code": "ak",
    "flag": "https://flagcdn.com/gh.svg" // Ghana
  },
  {
    "name": "Albanian",
    "code": "sq",
    "flag": "https://flagcdn.com/al.svg" // Albania
  },
  {
    "name": "Amharic",
    "code": "am",
    "flag": "https://flagcdn.com/et.svg" // Ethiopia
  },
  {
    "name": "Arabic",
    "code": "ar",
    "flag": "https://flagcdn.com/sa.svg" // Saudi Arabia
  },
  {
    "name": "Aragonese",
    "code": "an",
    "flag": "https://flagcdn.com/es.svg" // Spain
  },
  {
    "name": "Armenian",
    "code": "hy",
    "flag": "https://flagcdn.com/am.svg" // Armenia
  },
  {
    "name": "Assamese",
    "code": "as",
    "flag": "https://flagcdn.com/in.svg" // India
  },
  {
    "name": "Azerbaijani",
    "code": "az",
    "flag": "https://flagcdn.com/az.svg" // Azerbaijan
  },
  {
    "name": "Bambara",
    "code": "bm",
    "flag": "https://flagcdn.com/ml.svg" // Mali
  },
  {
    "name": "Basque",
    "code": "eu",
    "flag": "https://flagcdn.com/es.svg" // Spain
  },
  {
    "name": "Belarusian",
    "code": "be",
    "flag": "https://flagcdn.com/by.svg" // Belarus
  },
  {
    "name": "Bengali",
    "code": "bn",
    "flag": "https://flagcdn.com/bd.svg" // Bangladesh
  },
  {
    "name": "Bosnian",
    "code": "bs",
    "flag": "https://flagcdn.com/ba.svg" // Bosnia and Herzegovina
  },
  {
    "name": "Breton",
    "code": "br",
    "flag": "https://flagcdn.com/fr.svg" // France
  },
  {
    "name": "Bulgarian",
    "code": "bg",
    "flag": "https://flagcdn.com/bg.svg" // Bulgaria
  },
  {
    "name": "Burmese",
    "code": "my",
    "flag": "https://flagcdn.com/mm.svg" // Myanmar
  },
  {
    "name": "Catalan",
    "code": "ca",
    "flag": "https://flagcdn.com/es.svg" // Spain
  },
  {
    "name": "Chechen",
    "code": "ce",
    "flag": "https://flagcdn.com/ru.svg" // Russia
  },
  {
    "name": "Chinese",
    "code": "zh",
    "flag": "https://flagcdn.com/cn.svg" // China
  },
  {
    "name": "Croatian",
    "code": "hr",
    "flag": "https://flagcdn.com/hr.svg" // Croatia
  },
  {
    "name": "Czech",
    "code": "cs",
    "flag": "https://flagcdn.com/cz.svg" // Czech Republic
  },
  {
    "name": "Danish",
    "code": "da",
    "flag": "https://flagcdn.com/dk.svg" // Denmark
  },
  {
    "name": "Dutch",
    "code": "nl",
    "flag": "https://flagcdn.com/nl.svg" // Netherlands
  },
  {
    "name": "English",
    "code": "en",
    "flag": "https://flagcdn.com/gb.svg" // United Kingdom
  },
  {
    "name": "Estonian",
    "code": "et",
    "flag": "https://flagcdn.com/ee.svg" // Estonia
  },
  {
    "name": "Finnish",
    "code": "fi",
    "flag": "https://flagcdn.com/fi.svg" // Finland
  },
  {
    "name": "French",
    "code": "fr",
    "flag": "https://flagcdn.com/fr.svg" // France
  },
  {
    "name": "Galician",
    "code": "gl",
    "flag": "https://flagcdn.com/es.svg" // Spain
  },
  {
    "name": "Georgian",
    "code": "ka",
    "flag": "https://flagcdn.com/ge.svg" // Georgia
  },
  {
    "name": "German",
    "code": "de",
    "flag": "https://flagcdn.com/de.svg" // Germany
  },
  {
    "name": "Greek",
    "code": "el",
    "flag": "https://flagcdn.com/gr.svg" // Greece
  },
  {
    "name": "Gujarati",
    "code": "gu",
    "flag": "https://flagcdn.com/in.svg" // India
  },
  {
    "name": "Haitian",
    "code": "ht",
    "flag": "https://flagcdn.com/ht.svg" // Haiti
  },
  {
    "name": "Hausa",
    "code": "ha",
    "flag": "https://flagcdn.com/ng.svg" // Nigeria
  },
  {
    "name": "Hebrew",
    "code": "he",
    "flag": "https://flagcdn.com/il.svg" // Israel
  },
  {
    "name": "Hindi",
    "code": "hi",
    "flag": "https://flagcdn.com/in.svg" // India
  },
  {
    "name": "Hungarian",
    "code": "hu",
    "flag": "https://flagcdn.com/hu.svg" // Hungary
  },
  {
    "name": "Icelandic",
    "code": "is",
    "flag": "https://flagcdn.com/is.svg" // Iceland
  },
  {
    "name": "Igbo",
    "code": "ig",
    "flag": "https://flagcdn.com/ng.svg" // Nigeria
  },
  {
    "name": "Indonesian",
    "code": "id",
    "flag": "https://flagcdn.com/id.svg" // Indonesia
  },
  {
    "name": "Irish",
    "code": "ga",
    "flag": "https://flagcdn.com/ie.svg" // Ireland
  },
  {
    "name": "Italian",
    "code": "it",
    "flag": "https://flagcdn.com/it.svg" // Italy
  },
  {
    "name": "Japanese",
    "code": "ja",
    "flag": "https://flagcdn.com/jp.svg" // Japan
  },
  {
    "name": "Javanese",
    "code": "jv",
    "flag": "https://flagcdn.com/id.svg" // Indonesia
  },
  {
    "name": "Kannada",
    "code": "kn",
    "flag": "https://flagcdn.com/in.svg" // India
  },
  {
    "name": "Kazakh",
    "code": "kk",
    "flag": "https://flagcdn.com/kz.svg" // Kazakhstan
  },
  {
    "name": "Khmer",
    "code": "km",
    "flag": "https://flagcdn.com/kh.svg" // Cambodia
  },
  {
    "name": "Korean",
    "code": "ko",
    "flag": "https://flagcdn.com/kr.svg" // South Korea
  },
  {
    "name": "Kurdish",
    "code": "ku",
    "flag": "https://flagcdn.com/iq.svg" // Iraq
  },
  {
    "name": "Kyrgyz",
    "code": "ky",
    "flag": "https://flagcdn.com/kg.svg" // Kyrgyzstan
  },
  {
    "name": "Lao",
    "code": "lo",
    "flag": "https://flagcdn.com/la.svg" // Laos
  },
  {
    "name": "Latin",
    "code": "la",
    "flag": "https://flagcdn.com/va.svg" // Vatican City
  },
  {
    "name": "Latvian",
    "code": "lv",
    "flag": "https://flagcdn.com/lv.svg" // Latvia
  },
  {
    "name": "Lithuanian",
    "code": "lt",
    "flag": "https://flagcdn.com/lt.svg" // Lithuania
  },
  {
    "name": "Luxembourgish",
    "code": "lb",
    "flag": "https://flagcdn.com/lu.svg" // Luxembourg
  },
  {
    "name": "Macedonian",
    "code": "mk",
    "flag": "https://flagcdn.com/mk.svg" // North Macedonia
  },
  {
    "name": "Malagasy",
    "code": "mg",
    "flag": "https://flagcdn.com/mg.svg" // Madagascar
  },
  {
    "name": "Malay",
    "code": "ms",
    "flag": "https://flagcdn.com/my.svg" // Malaysia
  },
  {
    "name": "Malayalam",
    "code": "ml",
    "flag": "https://flagcdn.com/in.svg" // India
  },
  {
    "name": "Maltese",
    "code": "mt",
    "flag": "https://flagcdn.com/mt.svg" // Malta
  },
  {
    "name": "Maori",
    "code": "mi",
    "flag": "https://flagcdn.com/nz.svg" // New Zealand
  },
  {
    "name": "Marathi",
    "code": "mr",
    "flag": "https://flagcdn.com/in.svg" // India
  },
  {
    "name": "Mongolian",
    "code": "mn",
    "flag": "https://flagcdn.com/mn.svg" // Mongolia
  },
  {
    "name": "Nepali",
    "code": "ne",
    "flag": "https://flagcdn.com/np.svg" // Nepal
  },
  {
    "name": "Norwegian",
    "code": "no",
    "flag": "https://flagcdn.com/no.svg" // Norway
  },
  {
    "name": "Oriya",
    "code": "or",
    "flag": "https://flagcdn.com/in.svg" // India
  },
  {
    "name": "Pashto",
    "code": "ps",
    "flag": "https://flagcdn.com/af.svg" // Afghanistan
  },
  {
    "name": "Persian",
    "code": "fa",
    "flag": "https://flagcdn.com/ir.svg" // Iran
  },
  {
    "name": "Polish",
    "code": "pl",
    "flag": "https://flagcdn.com/pl.svg" // Poland
  },
  {
    "name": "Portuguese",
    "code": "pt",
    "flag": "https://flagcdn.com/pt.svg" // Portugal
  },
  {
    "name": "Punjabi",
    "code": "pa",
    "flag": "https://flagcdn.com/in.svg" // India
  },
  {
    "name": "Romanian",
    "code": "ro",
    "flag": "https://flagcdn.com/ro.svg" // Romania
  },
  {
    "name": "Russian",
    "code": "ru",
    "flag": "https://flagcdn.com/ru.svg" // Russia
  },
  {
    "name": "Sanskrit",
    "code": "sa",
    "flag": "https://flagcdn.com/in.svg" // India
  },
  {
    "name": "Serbian",
    "code": "sr",
    "flag": "https://flagcdn.com/rs.svg" // Serbia
  },
  {
    "name": "Sindhi",
    "code": "sd",
    "flag": "https://flagcdn.com/pk.svg" // Pakistan
  },
  {
    "name": "Sinhala",
    "code": "si",
    "flag": "https://flagcdn.com/lk.svg" // Sri Lanka
  },
  {
    "name": "Slovak",
    "code": "sk",
    "flag": "https://flagcdn.com/sk.svg" // Slovakia
  },
  {
    "name": "Slovenian",
    "code": "sl",
    "flag": "https://flagcdn.com/si.svg" // Slovenia
  },
  {
    "name": "Somali",
    "code": "so",
    "flag": "https://flagcdn.com/so.svg" // Somalia
  },
  {
    "name": "Spanish",
    "code": "es",
    "flag": "https://flagcdn.com/es.svg" // Spain
  },
  {
    "name": "Sundanese",
    "code": "su",
    "flag": "https://flagcdn.com/id.svg" // Indonesia
  },
  {
    "name": "Swahili",
    "code": "sw",
    "flag": "https://flagcdn.com/tz.svg" // Tanzania
  },
  {
    "name": "Swedish",
    "code": "sv",
    "flag": "https://flagcdn.com/se.svg" // Sweden
  },
  {
    "name": "Tagalog",
    "code": "tl",
    "flag": "https://flagcdn.com/ph.svg" // Philippines
  },
  {
    "name": "Tajik",
    "code": "tg",
    "flag": "https://flagcdn.com/tj.svg" // Tajikistan
  },
  {
    "name": "Tamil",
    "code": "ta",
    "flag": "https://flagcdn.com/in.svg" // India
  },
  {
    "name": "Telugu",
    "code": "te",
    "flag": "https://flagcdn.com/in.svg" // India
  },
  {
    "name": "Thai",
    "code": "th",
    "flag": "https://flagcdn.com/th.svg" // Thailand
  },
  {
    "name": "Turkish",
    "code": "tr",
    "flag": "https://flagcdn.com/tr.svg" // Turkey
  },
  {
    "name": "Turkish",
    "code": "tr",
    "flag": "https://flagcdn.com/tr.svg" // Turkey
  },
  {
    "name": "Ukrainian",
    "code": "uk",
    "flag": "https://flagcdn.com/ua.svg" // Ukraine
  },
  {
    "name": "Urdu",
    "code": "ur",
    "flag": "https://flagcdn.com/pk.svg" // Pakistan
  },
  {
    "name": "Uzbek",
    "code": "uz",
    "flag": "https://flagcdn.com/uz.svg" // Uzbekistan
  },
  {
    "name": "Vietnamese",
    "code": "vn",
    "flag": "https://flagcdn.com/vn.svg" // Vietnam
  },
  {
    "name": "Welsh",
    "code": "cy",
    "flag": "https://flagcdn.com/gb-wls.svg" // Wales
  },
  {
    "name": "Yiddish",
    "code": "yi",
    "flag": "https://flagcdn.com/il.svg" // Israel
  },
  {
    "name": "Yoruba",
    "code": "yo",
    "flag": "https://flagcdn.com/ng.svg" // Nigeria
  },
  {
    "name": "Zulu",
    "code": "zu",
    "flag": "https://flagcdn.com/za.svg" // South Africa
  }
];

export default languagesCountry;