import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PERMISSION, PlanDetails, SubPlanDetails } from "../common/data/Permission";
import useMemoizedSelector from "../Components/Hooks/useMemoizedSelector";
import { useProfile } from "../Components/Hooks/useProfile";
import { UserRoles } from "../Components/constants/common";

const Navdata = () => {
  const history = useNavigate();
  const { permission } = useMemoizedSelector((state) => ({
    permission: state.Permission.permission,
  }));
  const { userProfile } = useProfile();

  const isAdmin = userProfile?.UserAttributes?.["custom:role"] === UserRoles.Admin;
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isProduct, setIsProduct] = useState(false);
  const [isInventory, setIsInventory] = useState(false);
  const [isOrder, setIsOrder] = useState(false);
  const [isSaleChannel, setIsSaleChannel] = useState(false);
  const [isLogistics, setIsLogistics] = useState(false);
  const [isPurchaseOrders, setIsPurchaseOrders] = useState(false);
  const [isFinance, setIsFinance] = useState(false);
  const [isCustomers, setIsCustomers] = useState(false);
  const [isSetting, setIsSetting] = useState(false);
  const [isPromotion, setIsPromotion] = useState(false);
  const [isImport, setIsImport] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [isWebsite, setIsWebsite] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [isPOS, setIsPOS] = useState(false);
  const [isCurrentState, setIsCurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id)) document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (isCurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (isCurrentState !== "Product") {
      setIsProduct(false);
    }
    if (isCurrentState !== "Inventory") {
      setIsInventory(false);
    }
    if (isCurrentState !== "Order") {
      setIsOrder(false);
    }
    if (isCurrentState !== "SaleChannel") {
      setIsSaleChannel(false);
    }
    if (isCurrentState !== "Logistics") {
      setIsLogistics(false);
    }
    if (isCurrentState !== "PurchaseOrders") {
      setIsPurchaseOrders(false);
    }
    if (isCurrentState !== "Finance") {
      setIsFinance(false);
    }
    if (isCurrentState !== "Customers") {
      setIsCustomers(false);
    }
    if (isCurrentState !== "Setting") {
      setIsSetting(false);
    }
    if (isCurrentState !== "Promotion") {
      setIsPromotion(false);
    }
    if (isCurrentState !== "Import") {
      setIsImport(false);
    }
    if (isCurrentState !== "Report") {
      setIsReport(false);
    }
    if (isCurrentState !== "Website") {
      setIsWebsite(false);
    }
    if (isCurrentState !== "Notification") {
      setIsNotification(false);
    }
    if (isCurrentState !== "POS") {
      setIsPOS(false);
    }
  }, [
    history,
    isCurrentState,
    isDashboard,
    isProduct,
    isInventory,
    isCustomers,
    isOrder,
    isImport,
    isReport,
    isWebsite,
    isPOS,
  ]);
  const menuItems = [
    {
      id: "dashboards",
      label: "Dashboards",
      icon: "ri-dashboard-2-line",
      link: "/dashboard",
      isShow: true,
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIsCurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "product",
      label: "Product",
      icon: "ri-store-2-line",
      link: "/#",
      stateVariables: isProduct,
      click: function (e) {
        e.preventDefault();
        setIsProduct(!isProduct);
        setIsCurrentState("Product");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "product._list",
          label: "Product List",
          link: "/products",
          parentId: "product",
        },
        {
          id: "variant._list",
          label: "Variant List",
          link: "/variants",
          parentId: "product",
        },
        {
          id: "brand._list",
          label: "Brand List",
          link: "/brands",
          parentId: "product",
        },
        {
          id: "category._list",
          label: "Category List",
          link: "/categories",
          parentId: "product",
        },
      ],
    },
    {
      id: "order",
      label: "Order",
      icon: "ri-file-list-3-line",
      link: "/#",
      stateVariables: isOrder,
      click: function (e) {
        e.preventDefault();
        setIsOrder(!isOrder);
        setIsCurrentState("Order");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "_list",
          label: "Order List",
          link: "/orders",
          parentId: "order",
        },
        {
          id: "order_process",
          label: "Order Process",
          link: "/order-process",
          parentId: "order",
        },
        {
          id: "return_order._list",
          label: "Return Order List",
          link: "/return-orders",
          parentId: "order",
        },
        {
          id: "package._list",
          label: "Package List",
          link: "/packages",
          parentId: "order",
        },
      ],
    },
    {
      id: "intgration",
      label: "Integration",
      icon: "ri-stackshare-line",
      link: "/#",
      stateVariables: isSaleChannel,
      click: function (e) {
        e.preventDefault();
        setIsSaleChannel(!isSaleChannel);
        setIsCurrentState("SaleChannel");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "sync_records._list",
          label: "Sync records",
          link: "/sync-records",
          parentId: "intgration",
        },
        {
          id: "channels._list",
          label: "Channel",
          link: "/channels",
          parentId: "intgration",
        },
      ],
    },
    {
      id: "logistics",
      label: "Logistics",
      icon: "mdi mdi-truck-fast-outline",
      link: "/#",
      stateVariables: isLogistics,
      click: function (e) {
        e.preventDefault();
        setIsLogistics(!isLogistics);
        setIsCurrentState("Logistics");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "shipping_provider._list",
          label: "Shipping Provider List",
          link: "/shipping-providers",
          parentId: "logistics",
        },
      ],
    },
    {
      id: "purchase_orders",
      label: "Purchase Order",
      icon: "ri-article-line",
      link: "/#",
      stateVariables: isPurchaseOrders,
      click: function (e) {
        e.preventDefault();
        setIsPurchaseOrders(!isPurchaseOrders);
        setIsCurrentState("PurchaseOrders");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "purchase_orders._list",
          label: "Purchase Order List",
          link: "/purchase-orders",
          parentId: "purchase-orders",
        },
        {
          id: "supplier._list",
          label: "Supplier List",
          link: "/suppliers",
          parentId: "purchase-orders",
        },
      ],
    },
    {
      id: "customer",
      label: "Customers",
      icon: "ri-user-3-line",
      link: "/#",
      stateVariables: isCustomers,
      click: function (e) {
        e.preventDefault();
        setIsCustomers(!isCustomers);
        setIsCurrentState("Customers");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "dashboard",
          label: "Dashboard",
          link: "/customer-dashboard",
          parentId: "customer",
        },
        {
          id: "customer._list",
          label: "Customer List",
          link: "/customers",
          parentId: "customer",
        },
        {
          id: "customer_group._list",
          label: "Customer Group List",
          link: "/customer-groups",
          parentId: "customer",
        },
        {
          id: "loyalty_program._list",
          label: "Loyalty Program",
          link: "/loyalty-programs",
          parentId: "customer",
        },
        {
          id: "reward_program._list",
          label: "Reward Program",
          link: "/reward-programs",
          parentId: "customer",
        },
      ],
    },
    {
      id: "finance",
      label: "Finance",
      icon: "ri-coins-line",
      link: "/#",
      stateVariables: isFinance,
      click: function (e) {
        e.preventDefault();
        setIsFinance(!isFinance);
        setIsCurrentState("Finance");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "account._list",
          label: "Account",
          link: "/accounts",
          parentId: "finance",
        },
        {
          id: "payment_methods._list",
          label: "Payment Method",
          link: "/payment-methods",
          parentId: "finance",
        },
        {
          id: "transaction._list",
          label: "Transaction",
          link: "/transactions",
          parentId: "finance",
        },
      ],
    },
    {
      id: "inventory",
      label: "Inventory",
      icon: "mdi mdi-map-marker-radius-outline",
      link: "/#",
      stateVariables: isInventory,
      click: function (e) {
        e.preventDefault();
        setIsInventory(!isInventory);
        setIsCurrentState("Inventory");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "location._list",
          label: "Location List",
          link: "/locations",
          parentId: "inventory",
        },
        {
          id: "inventory_item._list",
          label: "Inventory",
          link: "/inventory-items",
          parentId: "inventory",
        },
        {
          id: "stock_adjustment._list",
          label: "Stock Adjustment List",
          link: "/stock-adjustments",
          parentId: "inventory",
        },
        {
          id: "stock_relocate._list",
          label: "Stock Relocate List",
          link: "/stock-relocates",
          parentId: "inventory",
        },
      ],
    },
    {
      id: "promotion",
      label: "Promotion",
      icon: "ri-coupon-2-line",
      link: "/#",
      stateVariables: isPromotion,
      click: function (e) {
        e.preventDefault();
        setIsPromotion(!isPromotion);
        setIsCurrentState("Promotion");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "discount._list",
          label: "Discount List",
          link: "/discounts",
          parentId: "promotion",
        },
        {
          id: "voucher._list",
          label: "Voucher List",
          link: "/vouchers",
          parentId: "promotion",
        },
      ],
    },
    {
      id: "import",
      label: "Import",
      icon: "bx bx-import",
      link: "/#",
      stateVariables: isImport,
      click: function (e) {
        e.preventDefault();
        setIsImport(!isImport);
        setIsCurrentState("Import");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "import.import_list",
          label: "Import List",
          link: "/imports",
          parentId: "import",
        },
        {
          id: "record.record_details",
          label: "Record List",
          link: "/records",
          parentId: "import",
        },
      ],
    },
    {
      id: "report",
      label: "Report",
      icon: "ri-numbers-line",
      link: "/#",
      stateVariables: isReport,
      click: function (e) {
        e.preventDefault();
        setIsReport(!isReport);
        setIsCurrentState("Report");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "report.product_report_list",
          label: "Product Report",
          link: "/report/products",
          parentId: "report",
        },
      ],
    },
    {
      id: "website",
      label: "Website",
      icon: "ri-earth-fill",
      link: "/#",
      stateVariables: isWebsite,
      click: function (e) {
        e.preventDefault();
        setIsWebsite(!isWebsite);
        setIsCurrentState("Website");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "blog_category._list",
          label: "Blog category",
          link: "/blog-categories",
          parentId: "website",
        },
        {
          id: "blog._list",
          label: "Blog List",
          link: "/blogs",
          parentId: "website",
        },
      ],
    },
    {
      id: "notification",
      label: "Notification",
      icon: "ri-chat-history-line",
      link: "/#",
      stateVariables: isNotification,
      click: function (e) {
        e.preventDefault();
        setIsNotification(!isNotification);
        setIsCurrentState("Notification");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "notifications._list",
          label: "Notification List",
          link: "/notifications",
          parentId: "notification",
        },
      ],
    },
    {
      id: "loyalty_app",
      label: "Loyalty app",
      icon: "ri-star-smile-line",
      link: "/loyalty-app",
      isShow: true,
      stateVariables: isSetting,
      click: function (e) {
        e.preventDefault();
        setIsCurrentState("LoyaltyApp");
        updateIconSidebar(e);
      },
    },
    {
      id: "pos",
      label: "POS",
      icon: "mdi mdi-desktop-classic",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIsCurrentState("Dashboard");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "terminal._list",
          label: "Terminal List",
          link: "/terminals",
          parentId: "pos",
        },
        {
          id: "shift._list",
          label: "Shift List",
          link: "/shifts",
          parentId: "pos",
        },
        {
          id: "pos.pos",
          label: "POS",
          link: "/pos",
          parentId: "pos", 
        },
        {
          id: "pos.pos",
          label: "POSFnB",
          link: "/pos",
          parentId: "pos", 
        },
      ],
    },
    {
      id: "setting",
      label: "Setting",
      icon: " ri-settings-5-line",
      link: "/settings",
      isShow: true,
      stateVariables: isSetting,
      click: function (e) {
        e.preventDefault();
        setIsSetting(!isSetting);
        setIsCurrentState("Setting");
        updateIconSidebar(e);
      },
    },
  ];

  function checkPropertyValue(obj, propertyString) {
    if (isAdmin) return true;
    // Tách chuỗi thành các phần tử con
    // const objPermission = { ...obj };
    const properties = propertyString.split(".");
    // const isInSubPlanDetails = subPlanDetails.includes(properties[0]);
    // if (!isInSubPlanDetails) {
    //   return false;
    // }
    // Sử dụng reduce để truy cập giá trị tương ứng trong object hoặc map
    // const result = properties.reduce((accumulator, currentProperty) => {
    //   if (accumulator && typeof accumulator === "object" && currentProperty in accumulator) {
    //     return accumulator[currentProperty];
    //   } else {
    //     return undefined;
    //   }
    // }, objPermission);

    // Kiểm tra giá trị cuối cùng và trả về kết quả
    return obj?.[properties[0]]?.[properties[1]];
  }

  const mapPermissionMenuItem = menuItems
    .map((menu) => {
      const menuSubItem = menu?.subItems;
      if (menuSubItem) {
        const newMenuSub = menuSubItem.filter((item) => {
          if (item?.isShow) return true;
          let check = permission[menu.id]?.[item?.id];
          if (typeof check === "undefined") {
            return checkPropertyValue(permission, item.id);
          }
          return check;
        });
        menu.subItems = newMenuSub;
      }
      return menu;
    })
    .filter((item) => {
      if (item?.isShow) {
        return true;
      }
      const menuSubItem = item?.subItems || [];
      return menuSubItem.length > 0;
    });
  return <React.Fragment>{mapPermissionMenuItem}</React.Fragment>;
};
export default Navdata;
