import React, { useEffect, useState } from "react";
import { Navigate, Route, useNavigate } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch } from "react-redux";

import { useProfile } from "../Components/Hooks/useProfile";

import {
  getColorInfoShop,
  getDefaultPrintShop,
  getInfoShop,
  getTemplatePrint,
  getVersion,
  listSaleChannel,
  logoutUser,
  updateLocationUser,
} from "../store/actions";
import { getPermission } from "../store/auth/permission/actions";
import useMemoizedSelector from "../Components/Hooks/useMemoizedSelector";
import Loader from "../Components/Common/Loader";
import TourComponent from "../Components/Common/Tour";
import NoPermission from "../pages/AuthenticationInner/Errors/NoPermission";
import {
  getLoggedInUserSettings,
  handleSetColorCss,
  setLoggedInUserSettings,
  updateFavicon,
} from "../helpers/format_helper";
import { UserRoles } from "../Components/constants/common";
import { PERMISSION } from "../common/data/Permission";

const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userProfile, loading, token } = useProfile();
  // const { locationUser, loadingColor, dataColor, dataShop, loadingDataShop } = useMemoizedSelector((state) => ({
  //   locationUser: state.ShopInfo.locationUser,
  // loadingColor: state.ShopInfo.loadingColor,
  // dataColor: state.ShopInfo.dataColor,
  // dataShop: state.ShopInfo.dataShop,
  // loadingDataShop: state.ShopInfo.loading,
  // }));
  const { permission, loadingPermission } = useMemoizedSelector((state) => ({
    permission: state.Permission.permission,
    loadingPermission: state.Permission.loadingPermission,
  }));
  const { loadingVersion, version } = useMemoizedSelector((state) => ({
    loadingVersion: state.Version.loading,
    version: state.Version.version,
  }));

  const handleDefaultLocation = (data) => {
    dispatch(updateLocationUser(data));
    localStorage.setItem("location", JSON.stringify(data));
  };

  useEffect(() => {
    if (userProfile && token) {
      setAuthorization(token);
    } else if (!userProfile && !token) {
      dispatch(logoutUser());
    }
  }, [userProfile, dispatch, token]);

  useEffect(() => {
    if (userProfile && token) {
      const location = JSON.parse(localStorage.getItem("location"));
      const defaultLocation = location ? location : { id: "ALL", name: "All" };
      handleDefaultLocation(defaultLocation);
      // const role = userProfile?.UserAttributes?.["custom:role"];
      // role !== UserRoles.Admin &&
      dispatch(getPermission({ id: userProfile?.UserAttributes?.sub, location: defaultLocation }));
      // dispatch(getInfoShop());
      // dispatch(getDefaultPrintShop());
      // dispatch(getTemplatePrint());
      // dispatch(getColorInfoShop());
      dispatch(getVersion());
      dispatch(listSaleChannel({}));
    }
  }, []);

  useEffect(() => {
    updateFavicon(version?.data?.dict?.shop_info?.setting_value?.store_icon?.url);
  }, [version]);

  useEffect(() => {
    if (!loadingVersion) {
      const dataDark = version?.data?.dict?.color?.setting_value?.["dark"];
      const dataLight = version?.data?.dict?.color?.setting_value?.["light"];
      if (dataLight && dataDark) {
        const listKeyDark = Object.keys(dataDark);
        const listKeyLight = Object.keys(dataLight);
        handleSetColorCss("dark", listKeyDark, dataDark);
        handleSetColorCss("light", listKeyLight, dataLight);
      }

      const data = getLoggedInUserSettings();
      if (data) {
        data.dataColor = version?.data?.dict?.color || {};
        data.dataShop = version?.data?.dict?.shop_info || {};
      }
      setLoggedInUserSettings(
        data ? data : { dataColor: version?.data?.dict?.color, dataShop: version?.data?.dict?.shop_info },
      );
    }
  }, [loadingVersion, version]);

  function getPathKeys(obj, path) {
    for (let key in obj) {
      if (typeof obj[key] === "object") {
        const subPathKeys = getPathKeys(obj[key], path);
        if (subPathKeys.length > 0) {
          return [key, ...subPathKeys];
        }
      } else if (typeof obj[key] === "string") {
        const pathSegments = path.split("/");
        const templateSegments = obj[key].split("/");

        if (pathSegments.length === templateSegments.length) {
          const match = templateSegments.every((segment, index) => {
            return (
              segment === pathSegments[index] ||
              (segment === "id" && pathSegments[index].match(/[a-f\d-]+/) && pathSegments[index]).length === 36
            );
          });

          if (match) {
            return [key];
          }
        }
      }
    }
    return [];
  }

  useEffect(() => {
    if (!userProfile && loading && !token) {
      const currentPath = window.location.pathname;
      navigate("/login", { state: { from: currentPath } });
    }
  }, [userProfile, loading, token]);

  if (
    loadingPermission ||
    (loadingVersion && version?.data?.dict?.color && Object.keys(version?.data?.dict?.color).length === 0)
  ) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <Loader />
      </div>
    );
  }

  const currentPath = window.location.pathname;
  // check permission
  const listKey = getPathKeys(PERMISSION, currentPath);
  if (
    userProfile &&
    userProfile.UserAttributes["custom:role"] !== UserRoles.Admin &&
    listKey.length === 2 &&
    !permission?.[listKey[0]]?.[listKey[1]]
  ) {
    return <NoPermission />;
  }
  return (
    <>
      <TourComponent />
      {props.children}
    </>
  );
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
