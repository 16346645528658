import React from "react";
import { ToastContainer } from "react-toastify";

//import Scss
import "./assets/scss/themes.scss";
import "react-toastify/dist/ReactToastify.css";
import "react18-json-view/src/style.css";
 
//import Route
import Route from "./Routes";

 
function App() {
  return (
    <React.Fragment>
      <Route />
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
