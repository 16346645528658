import { useEffect, useMemo, useState } from "react";
import { getSyncRecordsAPI, syncRecordAPI } from "../../helpers/service_helper";
import { toast } from "react-toastify";
import { Card, CardBody } from "reactstrap";
import APIProgressBar from "./APIProgressBar";
import ButtonTheme from "./ButtonTheme";
import { Tooltip } from "antd";
import { snakeToCapitalizedWords } from "../../helpers/format_helper";
import useMemoizedSelector from "../Hooks/useMemoizedSelector";
import LogoTheme from "../../assets/images/logo-theme.png";
import ImageCustom from "./ImageCustom";

const SyncComponent = ({ sync_record_id }) => {
  const { saleChannels } = useMemoizedSelector((state) => ({
    saleChannels: state.SaleChannels.saleChannels,
  }));
  const { version } = useMemoizedSelector((state) => ({
    version: state.Version.version,
  }));
  const [lightLogo, setLightLogo] = useState(null);

  useEffect(() => {
    setLightLogo(version?.data?.dict?.shop_info?.setting_value?.store_logo_light?.url ?? LogoTheme);
  }, [version]);

  const [getSyncRecordsLoading, setGetSyncRecordsLoading] = useState(false);
  const [syncRecordsLoading, setSyncRecordsLoading] = useState(false);
  const [syncRecordsComplete, setSyncRecordsComplete] = useState(false);
  const [originalRecord, setOriginalRecord] = useState(null);
  const [transformRecords, setTransformRecords] = useState([]);
  const [lastSyncedTime, setLastSyncedTime] = useState(null);

  const getSyncRecords = async () => {
    if (!sync_record_id) {
      return;
    }
    try {
      setGetSyncRecordsLoading(true);
      const res = await getSyncRecordsAPI({ sync_record_id });
      const newOriginalRecord = (res?.data?.channels ?? []).find((item) => item.id.startsWith(item.connection_id));
      const newTransformRecords = (res?.data?.channels ?? []).filter((item) => !item.id.startsWith(item.connection_id));
      setOriginalRecord(newOriginalRecord ?? null);
      setTransformRecords(newTransformRecords);

      // Calculate the last synced time
      const lastUpdated = new Date(newOriginalRecord?.updated_at);
      const now = new Date();
      const timeDifference = Math.abs(now - lastUpdated);

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

      if (days > 0) {
        setLastSyncedTime(`${days} day${days > 1 ? 's' : ''} ago`);
      } else if (hours > 0) {
        setLastSyncedTime(`${hours} hour${hours > 1 ? 's' : ''} ago`);
      } else {
        setLastSyncedTime(`${minutes} minute${minutes > 1 ? 's' : ''} ago`);
      }

    } catch (e) {
      toast(String(e));
    } finally {
      setGetSyncRecordsLoading(false);
    }
  };

  const startSync = async () => {
    if (!sync_record_id) {
      return;
    }
    try {
      setSyncRecordsLoading(true);
      const res = await syncRecordAPI({ sync_record_id });
    } catch (e) {
      toast(String(e));
    } finally {
      setSyncRecordsLoading(false);
    }
  };

  useEffect(() => {
    getSyncRecords();
  }, []);

  useEffect(() => {
    if (syncRecordsLoading) {
      setSyncRecordsComplete(true);
    } else {
      setTimeout(() => setSyncRecordsComplete(false), 500);
    }
  }, [syncRecordsLoading]);

  const originalSaleChanelLogo = useMemo(
    () => saleChannels.find((item) => item.key === originalRecord?.channel)?.logo,
    [saleChannels, originalRecord],
  );

  return (
    <Card>
      <CardBody className="tw-p-6">
        <h5 className="tw-mb-4">Sync Status</h5>
        <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
          <div className="tw-flex tw-items-center tw-space-x-2">
            {originalSaleChanelLogo ? (
              <ImageCustom image={originalSaleChanelLogo} name={""} avatarSize={"avatar-xxs"} isPreview={false} />
            ) : (
              <i className="ri-database-2-line tw-text-[24px] tw-text-blue-500" />
            )}
            <span className="tw-font-semibold">{snakeToCapitalizedWords(originalRecord?.channel ?? "")}</span>
          </div>
          <i className="ri-arrow-right-line tw-text-[24px] tw-text-gray-400" />
          <div className="tw-flex tw-items-center tw-space-x-4 tw-overflow-x-auto tw-pb-2">
            {(transformRecords ?? []).map((record, index) => {
              const saleChannel = saleChannels.find((item) => item.key === record?.channel);
              return (
                <Tooltip
                  key={index}
                  title={
                    originalRecord && record.raw_record_version === originalRecord?.raw_record_version
                      ? "Active destination"
                      : "Inactive destination"
                  }
                >
                  <div
                    className={`tw-flex tw-flex-col tw-items-center ${
                      originalRecord && record.raw_record_version === originalRecord?.raw_record_version
                        ? ""
                        : "tw-opacity-50"
                    }`}
                  >
                    {saleChannel ? (
                      <ImageCustom
                        image={saleChannel?.logo}
                        name={saleChannel?.name}
                        avatarSize={"avatar-xxs"}
                        isPreview={false}
                      />
                    ) : (
                      <i
                        className={`tw-text-[24px] ri-database-2-line ${
                          record.raw_record_version === originalRecord?.raw_record_version && originalRecord
                            ? "tw-text-green-500"
                            : "tw-text-gray-400"
                        }`}
                      />
                    )}
                    <span className="tw-text-sm tw-font-semibold tw-mt-1">{record?.channel}</span>
                    {originalRecord ? (
                      record.raw_record_version === originalRecord?.raw_record_version ? (
                        <i className="ri-checkbox-circle-line tw-text-[16px] tw-text-green-500 tw-mt-1" />
                      ) : (
                        <i className="ri-close-circle-line tw-text-[16px] tw-text-red-600 tw-mt-1" />
                      )
                    ) : (
                      <i className="ri-error-warning-line tw-text-[16px] tw-text-gray-400 tw-mt-1" />
                    )}
                  </div>
                </Tooltip>
              );
            })}
          </div>
        </div>
        <div className="mb-2">{syncRecordsComplete && <APIProgressBar loading={syncRecordsLoading} />}</div>
        <div className="tw-flex tw-justify-between tw-items-center">
          <span className="tw-text-sm tw-text-gray-500">
            {syncRecordsLoading ? "Last synced: Syncing" : `Last synced: ${lastSyncedTime}`}
          </span>
          <ButtonTheme
            onClick={startSync}
            className="btn btn-outline-primary"
            loading={syncRecordsLoading}
            disabled={syncRecordsLoading}
            loadShowText={true}
          >
            Sync Now
          </ButtonTheme>
        </div>
      </CardBody>
    </Card>
  );
};

export default SyncComponent;
