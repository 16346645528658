import { useEffect, useState } from "react";
import withRouter from "../../Components/Common/withRouter";
import OpenShift from "./OpenShift";
import POS from "./POS";
import useMemoizedSelector from "../../Components/Hooks/useMemoizedSelector";
import { checkOpenedShift, listLocation, listTerminal } from "../../store/actions";
import { useDispatch } from "react-redux";
import Loader from "../../Components/Common/Loader";
import { useSearchParams } from "react-router-dom";
import { ordersAPI } from "../../helpers/resource_helper";
import { toast } from "react-toastify";
import POSFnB from "./POSFnB";

const POSPage = (props) => {
  const {
    router: { location, navigate, params, t },
  } = props;
  document.title = `${t("Point Of Sales")} | OptiWarehouse`;
  const dispatch = useDispatch();
  const { shift, shiftsLoading, checkShiftsLoading, shiftsSuccess, shiftsError } = useMemoizedSelector((state) => ({
    shift: state.Shifts.shift,
    location: state.Shifts.location,
    shiftsLoading: state.Shifts.loading,
    checkShiftsLoading: state.Shifts.checkLoading,
    shiftsSuccess: state.Shifts.success,
    shiftsError: state.Shifts.error,
  }));

  const [isShiftClosed, setIsShiftClosed] = useState(false);
  const [isOpenPOS, setIsOpenPOS] = useState(false);
  const [selectedTerminal, setSelectedTerminal] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [searchParams] = useSearchParams();
  const cloneOrderId = searchParams.get("clone_order_id");
  const [loadingCloneOrder, setLoadingCloneOrder] = useState(false);
  const [cloneOrder, setCloneOrder] = useState(null);

  const getBranchFromActiveShift = (data) => {
    onSelectLocation(data);
    onOpenPOS();
  };
  const handleChangeLocation = (data) => {
    setSelectedLocation(data);
  };
  const onOpenPOS = () => {
    setIsOpenPOS(true);
  };
  const onSelectTerminal = (data) => {
    setSelectedTerminal(data);
  };

  const onSelectLocation = (data) => {
    setSelectedLocation(data);
  };

  useEffect(() => {
    setIsShiftClosed(!shift?.id);
  }, [shift]);

  useEffect(() => {
    dispatch(listLocation({ limit: 20, sort_created_at: "desc" }));
  }, [dispatch]);

  useEffect(() => {
    if (selectedLocation?.value) {
      dispatch(listTerminal({ location_id: selectedLocation?.value, sort_created_at: "desc" }));
    }
  }, [selectedLocation]);

  useEffect(() => {
    dispatch(checkOpenedShift({}, getBranchFromActiveShift));
  }, []);

  useEffect(() => {
    if (cloneOrderId) {
      const getCloneOrder = async () => {
        try{
          setLoadingCloneOrder(true)
          const res = await ordersAPI.get({ id: cloneOrderId });
          setCloneOrder(res)
        } catch(e){
          toast.error(e)
        } finally {
          setLoadingCloneOrder(false)
        }
      };
      getCloneOrder();
    }
  }, [cloneOrderId]);

  if (shiftsLoading || checkShiftsLoading || loadingCloneOrder) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <Loader />
      </div>
    );
  }

  if (!isOpenPOS) {
    return (
      <OpenShift
        selectedLocation={selectedLocation}
        onSelectTerminal={onSelectTerminal}
        selectedTerminal={selectedTerminal}
        onSelectLocation={onSelectLocation}
        isShiftClosed={isShiftClosed}
        onOpenPOS={onOpenPOS}
      />
    );
  }

  return <POSFnB handleChangeLocation={handleChangeLocation} selectedLocation={selectedLocation} cloneOrder={cloneOrder}/>;
};

export default withRouter(POSPage);
