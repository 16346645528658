import { useEffect, Fragment, useState, useCallback, useMemo } from "react";
import { Card, CardBody, CardHeader, Col, FormFeedback, Input, Label, Row } from "reactstrap";

import { convertValidDate, convertValidTime } from "../../../../../helpers/format_helper";

import TagsInputCustom from "../../../../../Components/Common/TagsInputCustom";
import useMemoizedSelector from "../../../../../Components/Hooks/useMemoizedSelector";
import { useTranslation } from "react-i18next";
import SelectTheme from "../../../../../Components/Common/SelectTheme";
import Dropzone from "react-dropzone";
import { formatBytes } from "../../../../../helpers/format_helper";
import InputTheme from "../../../../../Components/Common/InputTheme";
import ImageCustom from "../../../../../Components/Common/ImageCustom";
import LogoTheme from "../../../../../assets/images/logo-theme.png";

const ProductDetails = ({
  productState,
  values,
  setFieldValue,
  handleChangeImages,
  handleChangeName,
  toggleOnBrandsModal,
  toggleOnCategoriesModal,
  isEdit = false,
}) => {
  const { t } = useTranslation();

  const { saleChannels } = useMemoizedSelector((state) => ({
    saleChannels: state.SaleChannels.saleChannels,
  }));
  const { version } = useMemoizedSelector((state) => ({
    version: state.Version.version,
  }));
  const [lightLogo, setLightLogo] = useState(null);

  useEffect(() => {
    setLightLogo(version?.data?.dict?.shop_info?.setting_value?.store_logo_light?.url ?? LogoTheme);
  }, [version]);

  const handleDeleteImage = (index) => {
    const newImages = (values?.images ?? []).filter((item, i) => i !== index);
    handleChangeImages(newImages);
  };

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const content = event.target.result;
        file.content = content;
        file.preview = URL.createObjectURL(file);
        file.formattedSize = formatBytes(file.size);
        resolve(file);
      };

      reader.onerror = (event) => {
        reject(event.target.error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleAcceptedFiles = useCallback(
    (files) => {
      if (files.length > 0) {
        const promises = Array.from(files).map((file) => readFileContent(file));
        Promise.all(promises)
          .then((contents) => {
            handleChangeImages([...(values?.images ?? []), ...contents]);
          })
          .catch((error) => {
            console.error("Error reading files:", error);
          });
      }
    },
    [values],
  );
  const saleChannel = useMemo(
    () => saleChannels.find((item) => item.key === productState?.source?.channel_name),
    [saleChannels, productState],
  );

  return (
    <Card>
      <CardHeader>
        <Row className="g-4 align-items-center">
          <div className="col-sm">
            <div className="hstack">
              <h5 className="card-title mb-0 flex-grow-1">{t("Product Details")}</h5>
              {saleChannel ? (
                <ImageCustom
                  image={saleChannel?.logo}
                  name={saleChannel?.name}
                  avatarSize={"avatar-xs"}
                  isPreview={false}
                />
              ) : (
                <ImageCustom
                  image={lightLogo}
                  name={productState?.source?.name}
                  avatarSize={"avatar-xs"}
                  isPreview={false}
                />
              )}
            </div>
          </div>
        </Row>
      </CardHeader>
      {isEdit ? (
        <CardBody>
          <div className="mb-3">
            <h5 className="fs-14 mb-1">{t("Product Images")}</h5>
            <p className="text-muted">{t("Add Product Images")}</p>
            <div className="dropzone">
              <div className="row justify-content-center dz-message">
                {(values?.images ?? []).map((item, i) => (
                  <div key={i + "-image"} className="col-auto p-0 m-2">
                    <img
                      data-dz-thumbnail=""
                      height="80"
                      className="avatar-md rounded bg-light"
                      style={{ objectFit: "contain", height: "80px", width: "80px" }}
                      alt={""}
                      src={item.url ?? item.content}
                    />
                    <i
                      className="position-absolute top-0 start-100 translate-middle ri-close-circle-fill text-danger fs-19 cursor-pointer"
                      onClick={() => handleDeleteImage(i)}
                    ></i>
                  </div>
                ))}
                <Dropzone
                  accept={{ "image/*": [".jpeg", ".jpg", ".png"] }}
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles(acceptedFiles);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      className="avatar-md col-auto p-0 m-2 border border-dashed border-2 cursor-pointer rounded-3"
                      {...getRootProps()}
                    >
                      <i className="display-4 text-muted ri-upload-cloud-2-fill rounded" />
                    </div>
                  )}
                </Dropzone>
              </div>
              <div className="row justify-content-center">
                <Label className="m-3 w-auto cursor-pointer text-decoration-underline" for="dropzone-images">
                  {t("Upload")}
                </Label>
                <Input
                  className="d-none"
                  type="file"
                  accept="image/*"
                  multiple
                  id="dropzone-images"
                  onChange={(e) => {
                    let files = [];
                    Object.keys(e.target.files).map((item) => files.push(e.target.files[item]));
                    let newFiles = files.filter((item) => item.type.startsWith("image/"));
                    handleAcceptedFiles(newFiles);
                  }}
                />
              </div>
            </div>{" "}
          </div>
          <div id="product-name" className="mb-3">
            <InputTheme
              name={"name"}
              placeholder="Enter product name"
              label={"Product Name"}
              onTrigger={(data) => {
                handleChangeName(data);
              }}
            />
          </div>
          <div className="mb-3">
            <SelectTheme
              name="brand"
              remote={true}
              selectDataOrigin={true}
              isObjectValueSelect={true}
              path="product/brands"
              label={
                <div className="d-flex align-items-end justify-content-between w-100">
                  <span>{t("Brand")}</span>
                  <div
                    className="float-end text-primary cursor-pointer text-decoration-underline"
                    onClick={toggleOnBrandsModal}
                  >
                    {t("Add New")}
                  </div>
                </div>
              }
            />
          </div>
          <div className="mb-3">
            <SelectTheme
              name="category"
              remote={true}
              selectDataOrigin={true}
              isObjectValueSelect={true}
              path="product/categories"
              label={
                <div className="d-flex align-items-end justify-content-between w-100">
                  <span>{t("Category")}</span>
                  <span
                    className="float-end text-primary cursor-pointer text-decoration-underline"
                    onClick={toggleOnCategoriesModal}
                  >
                    {t("Add New")}
                  </span>
                </div>
              }
            />
          </div>
          {(productState?.variants ?? []).length === 1 && (
            <Fragment>
              <div className="mb-3">
                <InputTheme name={"sku"} placeholder="Enter sku" label={"Sku"} />
              </div>
              <div className="mb-3">
                <InputTheme name={"barcode"} placeholder="Enter barcode" label={"Barcode"} />
              </div>
              <div className="mb-3">
                <Label className="form-label">{t("Measurements")}</Label>
                <Row>
                  <Col sm={6}>
                    <div className="mb-3">
                      <InputTheme
                        label="Height"
                        type="number"
                        name="measurements.height_value"
                        customRenderEnd={
                          <>
                            <Input
                              type="select"
                              value={values.measurements?.height_unit}
                              className="input-group-text"
                              onChange={(e) => {
                                setFieldValue("measurements.height_unit", e.target.value);
                              }}
                            >
                              <Fragment>
                                <option value="mm">mm</option>
                                <option value="cm">cm</option>
                                <option value="m">m</option>
                              </Fragment>
                            </Input>
                          </>
                        }
                      />
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="mb-3">
                      <InputTheme
                        label="Length"
                        type="number"
                        name="measurements.length_value"
                        customRenderEnd={
                          <>
                            <Input
                              type="select"
                              value={values.measurements?.length_unit}
                              className="input-group-text"
                              onChange={(e) => {
                                setFieldValue("measurements.length_unit", e.target.value);
                              }}
                            >
                              <Fragment>
                                <option value="mm">mm</option>
                                <option value="cm">cm</option>
                                <option value="m">m</option>
                              </Fragment>
                            </Input>
                          </>
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <InputTheme
                      label="Width"
                      type="number"
                      name="measurements.width_value"
                      customRenderEnd={
                        <>
                          <Input
                            type="select"
                            value={values.measurements?.width_unit}
                            className="input-group-text"
                            onChange={(e) => {
                              setFieldValue("measurements.width_unit", e.target.value);
                            }}
                          >
                            <Fragment>
                              <option value="mm">mm</option>
                              <option value="cm">cm</option>
                              <option value="m">m</option>
                            </Fragment>
                          </Input>
                        </>
                      }
                    />
                  </Col>
                  <Col sm={6}>
                    <InputTheme
                      label="Weight"
                      type="number"
                      name="measurements.weight_value"
                      customRenderEnd={
                        <>
                          <Input
                            type="select"
                            value={values.measurements?.weight_unit}
                            className="input-group-text"
                            onChange={(e) => {
                              setFieldValue("measurements.weight_unit", e.target.value);
                            }}
                          >
                            <Fragment>
                              <option value="gr">gr</option>
                              <option value="g">g</option>
                              <option value="kg">kg</option>
                            </Fragment>
                          </Input>
                        </>
                      }
                    />
                  </Col>
                </Row>
              </div>
            </Fragment>
          )}
          <div className="mb-3">
            <InputTheme name={"tags"} label={"Tags"} type="tags" placeholder={"Enter tags and press enter"} />
          </div>
        </CardBody>
      ) : (
        <CardBody>
          <div className="hstack">
            <div className="flex-grow-1">
              <h5>{productState?.name}</h5>
              <Row>
                <Col xs={6}>
                  <span className="text-muted">{t("Category")}</span>
                </Col>
                <Col xs={6}>: {t(productState?.category?.name || "---")}</Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <span className="text-muted">{t("Brand")}</span>
                </Col>
                <Col xs={6}>: {t(productState?.brand?.name || "---")}</Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <span className="text-muted">{t("Tags")}</span>
                </Col>

                <Col xs={6}>: {t(productState?.tags || "---")}</Col>
              </Row>
              {productState?.variants && productState.variants.length === 0 && (
                <Fragment>
                  <Row>
                    <Col xs={6}>
                      <span className="text-muted">{t("Sku")}</span>
                    </Col>
                    <Col xs={6}>: {t(productState?.sku || "---")}</Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <span className="text-muted">{t("Barcode")}</span>
                    </Col>
                    <Col xs={6}>: {productState?.barcode || "---"}</Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <span className="text-muted">{t("Height")}</span>
                    </Col>
                    <Col xs={6}>
                      : {productState?.measurements?.height_value} {productState?.measurements?.height_unit}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <span className="text-muted">{t("Length")}</span>
                    </Col>
                    <Col xs={6}>
                      : {productState?.measurements?.length_value} {productState?.measurements?.length_unit}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <span className="text-muted">{t("Weight")}</span>
                    </Col>
                    <Col xs={6}>
                      : {productState?.measurements?.weight_value} {productState?.measurements?.weight_unit}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <span className="text-muted">{t("Width")}</span>
                    </Col>
                    <Col xs={6}>
                      : {productState?.measurements?.width_value} {productState?.measurements?.width_unit}
                    </Col>
                  </Row>
                </Fragment>
              )}
              <Row>
                <Col xs={6}>
                  <span className="text-muted">{t("Created at")}</span>
                </Col>
                <Col xs={6}>
                  : {convertValidDate(productState?.created_at)}, {convertValidTime(productState?.created_at)}
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <span className="text-muted">{t("Updated at")}</span>
                </Col>
                <Col xs={6}>
                  : {convertValidDate(productState?.updated_at)}, {convertValidTime(productState?.updated_at)}
                </Col>{" "}
              </Row>
            </div>
            <div className="flex-shrink-0 px-3">
              <img
                style={{ objectFit: "contain", height: "10rem", width: "10rem" }}
                className="rounded border bg-light"
                alt=""
                src={productState?.images?.[0]?.url}
              />
            </div>
          </div>
        </CardBody>
      )}
    </Card>
  );
};
export default ProductDetails;
