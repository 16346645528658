import React, { useRef } from "react";
import FormBlogLanguage from "./FormBlogLanguage";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import BreadCrumbCustom from "../../../../Components/Common/BreadCrumbCustom";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { t } from "i18next";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";

export default function AddBlogLanguage() {
  const formRef = useRef();
  const { blogsLoading } = useMemoizedSelector((state) => ({
    blogsLoading: state.Blogs.loading,
  }));
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumbCustom text="Back to blog list" navigateTo="/blogs" t={t}>
          <div className="hstack gap-2">
            <ButtonTheme
              type="button"
              className="btn btn-outline-primary w-sm"
              loading={blogsLoading}
              disabled={blogsLoading}
              onClick={() => formRef.current.handleSubmit()}
            >
              {t("Create")}
            </ButtonTheme>
          </div>
        </BreadCrumbCustom>
        <Card id="basic-information">
          <CardHeader>
            <h5 className="card-title">{t("Create locale blog")}</h5>
          </CardHeader>
          <CardBody>
            <FormBlogLanguage formRef={formRef} isCreate={true} />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
