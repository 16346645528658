import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Row, Table } from "reactstrap";

import { convertValidDate, convertValidTime } from "../../../../helpers/format_helper";

import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import Loader from "../../../../Components/Common/Loader";

import { getImport } from "../../../../store/actions";
import withRouter from "../../../../Components/Common/withRouter";
import BreadCrumbCustom from "../../../../Components/Common/BreadCrumbCustom";
import JsonView from "react18-json-view";
import LottieIcon from "../../../../Components/Common/LottieIcon";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";

const ImportDetails = (props) => {
  document.title = "Import Details | OptiWarehouse";
  const {
    router: { navigate, location, params, t },
  } = props;
  const dispatch = useDispatch();
  const { id } = params;

  const { importData, importsLoading, importsSuccess, importsError, importsMessage } = useMemoizedSelector((state) => ({
    importData: state.Imports.importData,
    importsMessage: state.Imports.message,
    importsLoading: state.Imports.loading,
    importsSuccess: state.Imports.success,
    importsError: state.Imports.error,
  }));

  useEffect(() => {
    id && dispatch(getImport(id));
  }, [id]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumbCustom text={"Back to import list"} navigateTo={"/imports"} />
        <Row>
          <Col lg={12}>
            {importsLoading ? (
              <Loader />
            ) : importsError ? (
              <div className="text-center">
                <LottieIcon src="https://cdn.lordicon.com/msoeawqm.json" style={{ width: "130px", height: "130px" }} />
                <div>
                  <h4>{t(importsError)}</h4>
                </div>
              </div>
            ) : (
              <Fragment>
                <Card>
                  <CardHeader>
                    <h5 className="card-title">{t("Import details")}</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col lg={12}>
                        <div className="table-responsive">
                          <Table className="table mb-0 table-borderless">
                            <tbody>
                              <tr>
                                <th>
                                  <span className="fw-medium">Id:</span>
                                </th>
                                <td>{importData?.id || "---"}</td>
                              </tr>
                              <tr>
                                <th>
                                  <span className="fw-medium">Import type:</span>
                                </th>
                                <td>{importData?.import_type || "---"}</td>
                              </tr>
                              <tr>
                                <th>
                                  <span className="fw-medium">Status:</span>
                                </th>
                                <td>
                                  <BadgeCustom status={importData?.status} />
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <span className="fw-medium">Message:</span>
                                </th>
                                <td>{importData?.message || "---"}</td>
                              </tr>
                              <tr>
                                <th>
                                  <span className="fw-medium">Source:</span>
                                </th>
                                <td>{importData?.source?.channel_name || "---"}</td>
                              </tr>
                              <tr>
                                <th>
                                  <span className="fw-medium">Imported at:</span>
                                </th>
                                <td>
                                  {convertValidDate(importData?.imported_at)},{" "}
                                  <small>{convertValidTime(importData?.imported_at)}</small>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <span className="fw-medium">Finished at:</span>
                                </th>
                                {importData?.finished_at ? (
                                  <td>
                                    {convertValidDate(importData?.finished_at)},{" "}
                                    <small>{convertValidTime(importData?.finished_at)}</small>
                                  </td>
                                ) : (
                                  <td>---</td>
                                )}
                              </tr>
                              <tr>
                                <th>
                                  <span className="fw-medium">Import data:</span>
                                </th>
                                <td>
                                  {importData?.import_data ? (
                                    <JsonView src={JSON.parse(importData.import_data)} collapsed={true} />
                                  ) : (
                                    "---"
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Fragment>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(ImportDetails);
