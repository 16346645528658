import React, { Fragment, useEffect, useMemo, useState, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { updateDynamicSettings } from "../../../../../helpers/service_helper";
import InputTheme from "../../../../../Components/Common/InputTheme";
import ButtonTheme from "../../../../../Components/Common/ButtonTheme";
import SelectTheme from "../../../../../Components/Common/SelectTheme";
import { deepCopy, snakeToCapitalizedWords } from "../../../../../helpers/format_helper";
import { Alert, Card, CardBody, Col, Row, Collapse, Button, NavLink, Nav } from "reactstrap";
import SearchInput from "../../../../../Components/Common/SearchInput";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import ScrollToError from "../../../../../Components/Common/ScrollToError";
import classnames from "classnames";

const DynamicSettingsForm = ({ id, initialDynamicSettings }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [collapsedSections, setCollapsedSections] = useState({});
  const [isEditing, setIsEditing] = useState({}); // State to control editability per section
  const sectionRefs = useRef({});
  const [newEntryKey, setNewEntryKey] = useState("");
  const [newEntryValue, setNewEntryValue] = useState("");
  const [isBasicCollapse, setIsBasicCollapse] = useState(true);

  const toggleEditMode = (key) => {
    setIsEditing((prev) => ({
      ...prev,
      [key]: !prev[key], // Toggle editing mode for specific section
    }));
  };

  const handleAddNewEntry = () => {
    if (newEntryKey && newEntryValue) {
      const parsedValue = (() => {
        try {
          return JSON.parse(newEntryValue);
        } catch {
          return newEntryValue;
        }
      })();

      const updatedDynamicSettings = {
        ...initialDynamicSettings,
        [newEntryKey]: parsedValue,
      };
      setNewEntryKey("");
      setNewEntryValue("");
    } else {
      toast.error("Both key and value are required.");
    }
  };

  useEffect(() => {
    console.log("Basic collapse:", isBasicCollapse);

    const initialCollapsedState = Object.keys(initialDynamicSettings).reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {});
    setCollapsedSections(initialCollapsedState);
  }, [initialDynamicSettings]);

  const toggleSection = (key) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
    setIsBasicCollapse((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const processedValues = Object.entries(values).reduce((acc, [key, value]) => {
        if (key === "location_mapping") {
          acc[key] = { mapping: value.mapping };
        } else if (typeof initialDynamicSettings[key] === "object" && initialDynamicSettings[key] !== null) {
          acc[key] = JSON.parse(value);
        } else {
          acc[key] = value;
        }
        return acc;
      }, {});
      await updateDynamicSettings(id, { dynamic_settings: processedValues });
      toast.success("Dynamic settings updated successfully!");
    } catch (e) {
      toast.error(String(e));
    } finally {
      setLoading(false);
    }
  };

  const defaultSettings = {
    sampleField: "", // Example default field; add more as needed
  };

  const initialFormValues = useMemo(
    () =>
      Object.entries(initialDynamicSettings || defaultSettings).reduce((acc, [key, value]) => {
        if (key === "location_mapping") {
          acc[key] = { mapping: value?.mapping || {} };
        } else if (typeof value === "object" && value !== null) {
          acc[key] = JSON.stringify(value, null, 2) || "";
        } else {
          acc[key] = value || ""; // Provide an empty string as a fallback
        }
        return acc;
      }, {}),
    [initialDynamicSettings],
  );

  const validationSchema = Yup.object().shape(
    Object.entries(initialDynamicSettings).reduce((acc, [key, value]) => {
      if (key === "location_mapping") {
        acc[key] = Yup.object().shape({
          mapping: Yup.object().test("is-valid-mapping", t("Invalid mapping"), function (value) {
            return Object.values(value).every((v) => typeof v === "string" && v.trim() !== "");
          }),
        });
      } else if (typeof value === "object" && value !== null) {
        acc[key] = Yup.string().test(
          "is-valid-json",
          `${t("Invalid JSON for")} ${t(snakeToCapitalizedWords(key))}`,
          function (value) {
            if (!value) return true;
            try {
              JSON.parse(value);
              return true;
            } catch (e) {
              return false;
            }
          },
        );
      } else {
        acc[key] = Yup.string().required(`${t(snakeToCapitalizedWords(key))} ${t("is required")}`);
      }
      return acc;
    }, {}),
  );

  const scrollToSection = (key) => {
    if (sectionRefs.current[key]) {
      sectionRefs.current[key].scrollIntoView({ behavior: "smooth", block: "start" });
      if (collapsedSections[key]) toggleSection(key);
    }
  };

  return (
    <Row>
      <Col lg={9}>
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <ScrollToError />
              {Object.keys(initialDynamicSettings).length === 0 ? (
                <div>
                  <p>No dynamic settings found. You can start by adding new entries.</p>
                  <InputTheme
                    type="text"
                    name="newEntryKey"
                    label="Key"
                    placeholder="Enter key"
                    value={newEntryKey}
                    onChange={(e) => setNewEntryKey(e.target.value)}
                  />
                  <InputTheme
                    type="text"
                    name="newEntryValue"
                    label="Value"
                    placeholder="Enter value (JSON or string)"
                    value={newEntryValue}
                    onChange={(e) => setNewEntryValue(e.target.value)}
                  />
                  <div className="hstack gap-2 justify-content-end mt-3">
                    <ButtonTheme type="submit" className="btn btn-outline-primary" onClick={handleAddNewEntry}>
                      Add Entry
                    </ButtonTheme>
                  </div>
                </div>
              ) : (
                Object.entries(initialDynamicSettings).map(([key, value]) => (
                  <div key={key} ref={(el) => (sectionRefs.current[key] = el)} className="mb-3">
                    <div className="accordion mb-3">
                      <div className="accordion-item">
                        <h5 className="accordion-header">
                          <ButtonTheme
                            type="button"
                            onClick={() => toggleSection(key)}
                            className={classnames("accordion-button", { collapsed: !isBasicCollapse[key] })}
                            style={{ cursor: "pointer" }}
                          >
                            {snakeToCapitalizedWords(key)} {collapsedSections[key]}
                          </ButtonTheme>
                        </h5>
                      </div>
                    </div>
                    <Collapse isOpen={!collapsedSections[key]}>
                      {key === "location_mapping" ? (
                        <LocationMappingForm
                          location_mapping={initialDynamicSettings?.location_mapping}
                          onChangeValue={(data) => setFieldValue(`${key}.mapping`, data)}
                        />
                      ) : typeof value === "object" && value !== null ? (
                        <>
                          <InputTheme
                            type="editJson"
                            name={key}
                            placeholder={`Enter JSON for ${key}`}
                            rows={5}
                            disabled={!isEditing[key]} // Toggle disabled based on individual section edit mode
                          />
                          <ButtonTheme
                            type="button"
                            className="btn btn-outline-primary mt-2 mb-2"
                            onClick={() => toggleEditMode(key)}
                          >
                            {isEditing[key] ? "Disable Editing" : "Enable Editing"}
                          </ButtonTheme>
                        </>
                      ) : (
                        <>
                          <InputTheme
                            type="text"
                            name={key}
                            label={snakeToCapitalizedWords(key)}
                            placeholder={`Enter value for ${key}`}
                          />
                          <ButtonTheme
                            type="button"
                            className="btn btn-outline-primary mt-2 mb-2"
                            onClick={() => toggleEditMode(key)}
                          >
                            {isEditing[key] ? "Disable Editing" : "Enable Editing"}
                          </ButtonTheme>
                        </>
                      )}
                    </Collapse>
                  </div>
                ))
              )}

              {Object.keys(initialDynamicSettings).length > 0 && (
                <div className="hstack gap-2 justify-content-end mt-3">
                  <ButtonTheme type="button" className="btn btn-outline-primary" onClick={handleSubmit}>
                    Save Dynamic Settings
                  </ButtonTheme>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Col>
      <Col lg={3} className="flex-shrink-0">
        <div className="position-sticky" style={{ top: "calc(70px + 1.5rem + 60px)" }}>
          <Card>
            <Nav id="navbar-scroll-spy" className="flex-column">
              <Nav className="nav-pills flex-column p-3">
                {Object.keys(initialDynamicSettings).map((key) => (
                  <NavLink
                    key={key}
                    href={`#${key}`}
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection(key);
                    }}
                    className="cursor-pointer "
                    style={{ cursor: "pointer" }}
                  >
                    <i className="ri-information-line align-middle me-2 fs-16"></i>
                    <span>{snakeToCapitalizedWords(key)}</span>
                  </NavLink>
                ))}
              </Nav>
            </Nav>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

const LocationMappingForm = ({ location_mapping, onChangeValue }) => {
  const { t } = useTranslation();
  const [mappings, setMappings] = useState({});
  const [externalLocations, setExternalLocations] = useState([]);
  const [masterLocations, setMasterLocations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [notification, setNotification] = useState(null);

  const handleQuickMap = () => {
    const newMappings = deepCopy(mappings);
    externalLocations.forEach((external) => {
      if (!newMappings[external.id]) {
        const match = masterLocations.find((master) => master.name.toLowerCase().includes(external.name.toLowerCase()));
        if (match) {
          newMappings[external.id] = match.id;
        }
      }
    });
    onChangeValue(newMappings);
    setNotification({ type: "success", message: "Quick mapping applied!" });
    setTimeout(() => setNotification(null), 3000);
  };

  const handleMapping = (externalId, masterId) => {
    const newMappings = deepCopy(mappings);
    if (masterId) {
      newMappings[externalId] = masterId;
    } else {
      delete newMappings[externalId];
    }
    onChangeValue(newMappings);
  };

  const filteredExternalLocations = useMemo(() => {
    return externalLocations.filter(
      (location) =>
        location.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        location.address.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [externalLocations, searchTerm]);

  useEffect(() => {
    if (location_mapping) {
      setExternalLocations(location_mapping.external || []);
      setMasterLocations(location_mapping.master || []);
      setMappings(location_mapping.mapping || {});
    }
  }, [location_mapping]);

  return (
    <Fragment>
      <Row className="mb-4">
        <Col lg={12} className="d-flex gap-2">
          <div className="flex-grow-1">
            <SearchInput
              onChangeValue={(value) => {
                setSearchTerm(value);
              }}
              searchValue={searchTerm}
              onDeleteSearchValue={() => setSearchTerm("")}
              placeholder={"Search branches..."}
            />
          </div>
          <ButtonTheme onClick={handleQuickMap} className="btn btn-outline-primary">
            <i className="ri-flashlight-line me-2" />
            Quick Map
          </ButtonTheme>
        </Col>
      </Row>
      {notification && (
        <Alert color={notification.type === "success" ? "success" : "danger"} className="mb-4">
          {notification.message}
        </Alert>
      )}
      <div className="px-4">
        {" "}
        {/* Removed SimpleBar here */}
        {filteredExternalLocations.map((location) => (
          <Card className="mb-4" key={location.id}>
            <CardBody className="shadow-lg">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h5 className="mb-0">{location.name}</h5>
                <i className="ri-map-2-line text-gray-500 fs-20" />
              </div>
              <p className="small text-muted mb-2">{location.address}</p>
              <p className="small text-muted mb-3">
                {location.cityName}, {location.districtName}, {location.wardName}
              </p>
              <SelectTheme
                placeholder="Select branch"
                isForm={false}
                options={masterLocations}
                value={mappings[location.id] || ""}
                onChange={(value) => {
                  handleMapping(location.id, value?.value);
                }}
              />
            </CardBody>
          </Card>
        ))}
      </div>
    </Fragment>
  );
};

export default DynamicSettingsForm;
