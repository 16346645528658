import { useState, useEffect, Fragment, useMemo, useRef, useImperativeHandle } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import { customersAPI, discountsAPI, ordersAPI, packagesAPI } from "../../../../helpers/resource_helper";
import { calculateDiscountByCustomerGroup, deepCopy, formatVNDCurrency } from "../../../../helpers/format_helper";

import DiscountModal from "../Modal/DiscountModal";
import CustomersSearchDropdown from "./CustomersSearchDropDown";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import PaymentsModal from "../Modal/PaymentsModal";
import Loader from "../../../../Components/Common/Loader";
import AddEditCustomerModal from "../../../Ecommerce/Customer/Customers/AddEditCustomerModal";

import { addNewOrder, getDiscount, listApp, resetCustomer } from "../../../../store/actions";
import { DiscountAmountType, DiscountTypes, OrderTypes } from "../../../../Components/constants/common";
import { Tooltip } from "antd";
import ReactSelect from "react-select";
import SelectTheme from "../../../../Components/Common/SelectTheme";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import ViewTemplatePDF from "../../../../Components/Common/ViewTemplatePDF";
import { updateOrderAPI } from "../../../../helpers/service_helper";
import QRModal from "../Modal/QRModal";
import SubmitModal from "../../../../Components/Common/SubmitModal";
import InputTheme from "../../../../Components/Common/InputTheme";
import SimpleBar from "simplebar-react";
import { useDebounce } from "../../../../Components/Hooks/UseDebounce";
import FullNameRender from "../../../../Components/Common/FullnameRender";
import { useNavigate } from "react-router-dom";
import PriceSuggestion from "../../../../Components/Common/PriceSuggestion";

const RightSideBar = ({
  currentOrders,
  currentOrder,
  selectedOrder,
  onChangeCurrentOrder,
  discount,
  togglePromotionModal,
  deleteCurrentOrder,
  refShortcutButtonRightMenu,
  sendPayNotification,
  selectedLocation,
  cloneOrder,
  rewardProgram,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { order, ordersLoading, ordersSuccess, ordersError } = useMemoizedSelector((state) => ({
    order: state.Orders.order,
    ordersLoading: state.Orders.loading,
    ordersSuccess: state.Orders.success,
    ordersError: state.Orders.error,
  }));
  const [isQuickActionTab, setIsQuickActionTab] = useState(true);

  const { paymentMethods, paymentMethodsLoading, paymentMethodsSuccess, paymentMethodsError } = useMemoizedSelector(
    (state) => ({
      paymentMethods: state.PaymentMethods.paymentMethods,
      paymentMethodsLoading: state.PaymentMethods.loading,
      paymentMethodsSuccess: state.PaymentMethods.success,
      paymentMethodsError: state.PaymentMethods.error,
    }),
  );

  const { apps, appsLoading, appsLimit, appsPage, appsTotal, appsLoadingDelete } = useMemoizedSelector((state) => ({
    apps: state.Stores.apps,
    appsLoading: state.Stores.loadingApp,
    appsLimit: state.Stores.limitApp,
    appsPage: state.Stores.pageApp,
    appsTotal: state.Stores.totalApp,
    appsLoadingDelete: state.Stores.loadingDelete,
  }));

  const { locationUser } = useMemoizedSelector((state) => ({
    locationUser: state.ShopInfo.locationUser,
  }));

  const { loadingVersion, version } = useMemoizedSelector((state) => ({
    loadingVersion: state.Version.loading,
    version: state.Version.version,
  }));

  const { shift, shiftsLoading, shiftsSuccess, shiftsError } = useMemoizedSelector((state) => ({
    shift: state.Shifts.shift,
    location: state.Shifts.location,
    shiftsLoading: state.Shifts.loading,
    shiftsSuccess: state.Shifts.success,
    shiftsError: state.Shifts.error,
  }));
  const [payLoading, setPayLoading] = useState(false);

  const customerRef = useRef();

  const [tempGivenCash, setTempGivenCash] = useState(0);
  const debouncedGivenCash = useDebounce(tempGivenCash, 1000);

  const [tempRedeemPoint, setTempRedeemPoint] = useState(0);
  const debouncedRedeemPoint = useDebounce(tempRedeemPoint, 1000);

  useEffect(() => {
    calculateExcessCash(debouncedGivenCash);
  }, [debouncedGivenCash]);

  useEffect(() => {
    if (currentOrder?.customer?.loyal_customer) {
      onChangePoint(debouncedRedeemPoint, currentOrder?.customer?.loyal_customer?.point ?? 0);
    }
  }, [debouncedRedeemPoint]);

  const calculateExcessCash = (cash) => {
    const paymentTotal = (currentOrder?.payments ?? [])
      .filter((item) => item?.payment?.account?.type === "CASH")
      .reduce((total, item) => total + +item.amount, 0);
    const data = { excess_cash: cash - paymentTotal, given_cash: cash };
    onChangeValue(data);
  };

  const onChangePoint = (point, max_point) => {
    if (!rewardProgram) {
      return;
    }
    const data = {};
    if (Number(point) > 0) {
      data.reward_program = rewardProgram;
      if (Number(point) > Number(max_point)) {
        data.redeem_point = Number(max_point);
      } else {
        data.redeem_point = Number(point);
      }
      const discount_by_redeem_point = data.redeem_point * data.reward_program.money;
      if ((currentOrder?.payments ?? []).length === 1 && currentOrder.total >= discount_by_redeem_point) {
        currentOrder.payments[0].amount = currentOrder.total - discount_by_redeem_point;
      }
    } else {
      data.reward_program = null;
      data.redeem_point = null;
    }
    onChangeValue(data);
  };

  useEffect(() => {
    setTempGivenCash(currentOrder?.given_cash ?? 0);
  }, [currentOrder?.given_cash]);

  useEffect(() => {
    setTempRedeemPoint(currentOrder?.redeem_point ?? 0);
  }, [currentOrder?.redeem_point]);

  useImperativeHandle(refShortcutButtonRightMenu, () => ({
    toggleDiscount: () => {
      setShowDiscountModal(!showDiscountModal);
    },
    onPay: () => {
      const validate = validateOrder();
      validate && setSubmitPayModal(true);
    },
    togglePaymentMethod: () => {
      toggleOnPaymentModal();
    },
    onFocusCustomerSearch: () => {
      customerRef.current.onFocusCustomerSearch();
    },
  }));

  const [submitPayModal, setSubmitPayModal] = useState(false);
  const [isDelivery, setIsDelivery] = useState(false);
  const [isInvoiceIE, setIsInvoiceIE] = useState(false);
  const [selectCustomerLoading, setSelectCustomerLoading] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [dataVoucher, setDataVoucher] = useState(null);

  const [isOpenQR, setIsOpenQR] = useState(false);
  const [payingOrderId, setPayingOrderId] = useState("");
  const toggleQr = () => {
    setIsOpenQR(false);
    setPayingOrderId("");
  };

  const componentRef = useRef(null);

  const amountSubTotal = useMemo(() => {
    const defaultValue = (currentOrder?.order_line_items ?? []).reduce((total, item, index) => {
      if (
        currentOrder?.discount_id &&
        discount &&
        currentOrder?.discount_details &&
        (currentOrder?.discount_details ?? []).length > 0 &&
        [DiscountTypes.BY_PRODUCT, DiscountTypes.BY_QUANTITY].includes(discount?.type)
      ) {
        const itemInPromotion = (currentOrder?.discount_details ?? []).find((subItem) => subItem.sku === item.sku);
        if (itemInPromotion) {
          return total + itemInPromotion.quantity * itemInPromotion.sale_price;
        }
      }
      return total + item.quantity * item.sale_price;
    }, 0);
    if (
      currentOrder?.discount_id &&
      discount &&
      currentOrder?.discount_details &&
      (currentOrder?.discount_details ?? []).length > 0 &&
      [
        DiscountTypes.BY_PURCHASE_PRODUCT,
        DiscountTypes.PRODUCT_BY_ORDER_TOTAL,
        DiscountTypes.GIFT_BY_PURCHASE_PRODUCT,
        DiscountTypes.GIFT_BY_ORDER_TOTAL,
      ].includes(discount.type)
    ) {
      const extraProductValue = (currentOrder?.discount_details ?? []).reduce(
        (total, item, index) => total + item.quantity * item.sale_price,
        0,
      );
      return defaultValue + extraProductValue;
    }
    return defaultValue;
  }, [discount, currentOrder, currentOrders]);

  const orderQuantity = useMemo(() => {
    const defaultValue = (currentOrder?.order_line_items ?? []).reduce((total, item, index) => {
      return total + +item.quantity;
    }, 0);
    const newQuantity = (currentOrder?.discount_details ?? []).reduce((total, item, index) => {
      return total + +item.quantity;
    }, 0);
    return defaultValue + newQuantity;
  }, [currentOrders, currentOrder?.discount_details]);

  const amountShippingFee = useMemo(() => currentOrder?.shipping_fee ?? 0, [currentOrder?.shipping_fee]);
  const amountTax = useMemo(() => currentOrder?.tax ?? 0, [currentOrder?.tax]);
  const amountFeeTotal = useMemo(
    () => (currentOrder?.other_fees ?? []).reduce((total, item) => total + +item.amount, 0),
    [currentOrder?.other_fees],
  );
  const amountDiscount = useMemo(() => {
    let newAmountDiscount = currentOrder?.discount;
    if (!currentOrder?.discount_id) {
      return newAmountDiscount;
    }
    if (discount?.type === DiscountTypes.BY_ORDER_TOTAL) {
      const condition = discount?.discount_by_order_total.find((item) => {
        if (item?.amount_to) {
          return item.amount_to >= amountSubTotal && amountSubTotal >= item.amount_from;
        } else {
          return amountSubTotal >= item.amount_from;
        }
      });
      if (condition) {
        condition.type === DiscountAmountType.VALUE
          ? (newAmountDiscount = condition.amount)
          : (newAmountDiscount = (condition.rate * amountSubTotal) / 100);
        return newAmountDiscount;
      }
    }
    return newAmountDiscount;
  }, [amountSubTotal, currentOrder?.discount, currentOrder?.discount_id, discount]);

  const amountLoyaltyDiscount = useMemo(() => {
    return currentOrder?.applying_loyal_discount ? currentOrder?.discount_by_customer_group : 0;
  }, [currentOrder?.applying_loyal_discount, currentOrder?.discount_by_customer_group]);

  const amountTotal = useMemo(
    () =>
      amountSubTotal +
      +amountShippingFee +
      +amountTax -
      amountFeeTotal -
      amountDiscount -
      (!dataVoucher?.total_apply_voucher && Number(amountDiscount) > 0 ? 0 : dataVoucher?.sub_voucher_total ?? 0) -
      amountLoyaltyDiscount,
    [
      amountSubTotal,
      amountShippingFee,
      amountTax,
      amountFeeTotal,
      amountDiscount,
      // discount,
      // currentOrder,
      dataVoucher?.sub_voucher_total,
      dataVoucher?.total_apply_voucher,
      amountLoyaltyDiscount,
    ],
  );

  const onChangeValue = (data) => {
    let newData = deepCopy(currentOrder);
    onChangeCurrentOrder({ ...newData, ...data });
  };

  const onChangeDiscountPrice = (data) => {
    let newData = deepCopy(currentOrder);
    onChangeCurrentOrder({ ...newData, ...data });
  };

  const toggleOffPaymentModal = () => {
    setShowPaymentModal(false);
  };

  const toggleOnPaymentModal = () => {
    setShowPaymentModal(true);
  };

  const toggleCustomerModal = () => {
    setShowCustomerModal(false);
  };
  const toggleOnCustomerModal = () => {
    setShowCustomerModal(true);
  };

  const toggleDiscountModal = () => {
    setShowDiscountModal(false);
  };

  const handleChangeSource = (data) => {
    let newData = deepCopy(currentOrder);
    newData.source = data;
    onChangeCurrentOrder(newData);
  };

  const handleSelectCustomer = (data) => {
    let newData = deepCopy(currentOrder);
    newData.customer = data;
    const discount_by_customer_group = calculateDiscountByCustomerGroup(
      newData?.customer?.customer_group,
      newData?.customer,
      newData?.sub_total,
      newData?.applying_loyal_discount,
    );
    newData.discount_by_customer_group = discount_by_customer_group;
    newData.total = newData.sub_total - newData.discount - newData.discount_by_customer_group;
    if ((newData?.payments ?? []).length === 1) {
      newData.payments[0].amount = newData.total;
      if (newData.reward_program && newData.redeem_point) {
        const discount_by_redeem_point = newData.reward_program.money * newData.redeem_point;
        if (discount_by_redeem_point > newData.total) {
          newData.reward_program = null;
          newData.redeem_point = 0;
          toast.error("Redeem amount must less than or equal to order total!");
        } else {
          newData.payments[0].amount -= discount_by_redeem_point;
        }
      }
    } else {
      newData.payments = null;
    }
    onChangeCurrentOrder(newData);
  };

  const handleRemoveCustomer = () => {
    let newData = deepCopy(currentOrder);
    newData.customer = null;
    newData.billing_address = null;
    newData.shipping_address = null;
    newData.redeem_point = null;
    newData.reward_program = null;
    newData.discount_by_customer_group = 0;
    newData.total = newData.sub_total - newData.discount - newData.discount_by_customer_group;
    if ((newData?.payments ?? []).length === 1) {
      newData.payments[0].amount = newData.total;
      if (newData.reward_program && newData.redeem_point) {
        const discount_by_redeem_point = newData.reward_program.money * newData.redeem_point;
        if (discount_by_redeem_point > newData.total) {
          newData.reward_program = null;
          newData.redeem_point = 0;
          toast.error("Redeem amount must less than or equal to order total!");
        } else {
          newData.payments[0].amount -= discount_by_redeem_point;
        }
      }
    } else {
      newData.payments = null;
    }
    onChangeCurrentOrder(newData);
  };

  const handleSavePayments = (data) => {
    let newData = deepCopy(currentOrder);
    onChangeCurrentOrder({ ...newData, ...data });
  };
  const [dataPrintTemplate, setDataPrintTemplate] = useState("");

  useEffect(() => {
    // const location = JSON.parse(localStorage.getItem("location"))?.id;
    const template =
      version?.data?.dict?.default_print_template?.setting_value?.["ORDER"]?.[
        selectedLocation?.value ?? selectedLocation?.id
      ];
    setDataPrintTemplate(template ?? "");
  }, [version, selectedLocation]);

  const [payloadOrder, setPayloadOrder] = useState({});

  const handlePay = async (order, data) => {
    try {
      setPayLoading(true);
      const orderId = order.id;
      const newPayments = data.map((item) => {
        const res = { amount: Number(item.amount), payment_method_id: item.payment_method_id };
        return res;
      });
      const isQRCode = data.find((item) => item.payment.account.type === "QR");
      if (!isQRCode) {
        const res = await ordersAPI.createCustomPath(`${orderId}/pay_pos`, { payments: newPayments });
        if (res) {
          deleteCurrentOrder();
          toast.success(t("Order Added Successfully"));
          setSubmitPayModal(false);
          setTimeout(() => {
            if (dataPrintTemplate) {
              var pri = document.getElementById("edit").contentWindow;
              pri.print();
            }
          }, 1000);
        }
      } else {
        toast.error(t("This feature is coming soon!"));
      }
      cloneOrder && navigate("/pos");
    } catch (e) {
      console.error(e);
    } finally {
      setPayLoading(false);
    }
  };

  const validateOrder = () => {
    let payload = deepCopy(currentOrder);
    if (!shift?.id) {
      toast.error(t("Required shift id"));
      return false;
    }
    if (dataVoucher?.voucher_code && dataVoucher?.voucher_id) {
      payload["voucher_code"] = dataVoucher?.voucher_code;
      payload["voucher_id"] = dataVoucher?.voucher_id;
    }
    if (!payload?.customer) {
      toast.error(t("Required customer"));
      return false;
    }
    if (!payload?.payments) {
      toast.error(t("Required payment method"));
      return false;
    }
    const totalPayments = payload.payments.reduce((total, item) => total + Number(item.amount), 0);
    const totalPointExchange =
      payload?.redeem_point && payload?.reward_program ? payload.redeem_point * payload.reward_program.money : 0;
    console.log(totalPayments);
    console.log(totalPointExchange);
    console.log(payload?.total);
    if (totalPayments + totalPointExchange !== Number(payload?.total)) {
      toast.error(t("Invalid payment amount"));
      return false;
    }
    return true;
  };

  const handleCreateOrder = () => {
    let payload = deepCopy(currentOrder);
    delete payload.staff_id;
    delete payload.order_number;
    delete payload.excess_cash;
    delete payload.given_cash;
    delete payload.applying_loyal_discount;
    delete payload.percent_order_discount;
    payload["order_type"] = OrderTypes.POS;
    payload["shift_id"] = shift.id;

    const new_order_line_items = payload.order_line_items
      .filter((item) => item.quantity > 0)
      .map((item) => {
        const { available, prices, variants_with_same_original_sku, unit, original_sku, ...res } = item;
        return res;
      });
    payload.order_line_items = new_order_line_items;
    const { payments, ...data } = payload;
    delete payload["discount_object"];
    dispatch(
      addNewOrder(data, (dataOrder) => {
        setPayloadOrder(dataOrder);
        sendPayNotification(dataOrder);
        handlePay(dataOrder, payments);
      }),
    );
    // setPayloadOrder({});
  };

  const handleAddUpdateCustomer = (newCustomer) => {
    let newData = deepCopy(currentOrder);
    newData.customer = newCustomer;
    newData.billing_address = newCustomer?.billing_address;
    newData.shipping_address = newCustomer?.billing_address;
    onChangeCurrentOrder(newData);
  };

  useEffect(() => {
    setDataVoucher({});
  }, [amountTotal]);

  useEffect(() => {
    dispatch(listApp({ limit: 20, status: "ACTIVE" }));
  }, [dispatch]);

  const toggle = (tab) => {
    setIsQuickActionTab(tab === "eat-in");
  };

  return (
    <Fragment>
      <ViewTemplatePDF
        componentRef={componentRef}
        dataForm={{ ...(version?.data?.dict?.shop_info?.setting_value ?? {}), ...payloadOrder }}
        dataTemplate={dataPrintTemplate}
        isShow={false}
      />
      <SubmitModal
        title={"Order details"}
        show={submitPayModal}
        onSubmitClick={handleCreateOrder}
        onCloseClick={() => {
          if (!ordersLoading ?? !payLoading) {
            setSubmitPayModal(false);
          }
        }}
        loading={ordersLoading || payLoading}
        text={"Are you sure?"}
        description={""}
      >
        <p className="hstack justify-content-between">
          {t("Customer")}:{" "}
          <span className="fw-bold">
            <FullNameRender
              first_name={currentOrder?.customer?.first_name}
              last_name={currentOrder?.customer?.last_name}
            />
          </span>
        </p>
        <p className="hstack justify-content-between">
          {t("Subtotal")}: <span className="fw-bold">{formatVNDCurrency(currentOrder?.sub_total)}</span>
        </p>
        <p className="hstack justify-content-between">
          {t("Discount")}:{" "}
          <span className="fw-bold">
            {formatVNDCurrency(
              currentOrder?.discount +
                (currentOrder?.applying_loyal_discount ? currentOrder.discount_by_customer_group : 0),
            )}
          </span>
        </p>
        <p className="hstack justify-content-between">
          {t("Total")}: <span className="fw-bold">{formatVNDCurrency(currentOrder?.total)}</span>
        </p>

        <p>{t("Payment method")}:</p>
        <ul>
          {currentOrder?.redeem_point && currentOrder?.redeem_point > 0 && currentOrder?.reward_program && (
            <li>
              <div className="hstack justify-content-between">
                {t("Point")}:{" "}
                <Tooltip title={`1 ${t("point")} = ${formatVNDCurrency(currentOrder?.reward_program?.money)}`}>
                  <span className="fw-bold">
                    {formatVNDCurrency(currentOrder?.redeem_point * currentOrder?.reward_program?.money)}
                  </span>
                </Tooltip>
              </div>
            </li>
          )}
          {(currentOrder?.payments ?? []).map((item, index) => (
            <li key={index}>
              <span className="hstack justify-content-between">
                {t(item?.payment?.name)}: <span className="fw-bold">{formatVNDCurrency(item?.amount)}</span>
              </span>
            </li>
          ))}
        </ul>
        {currentOrder?.given_cash > 0 && (
          <Fragment>
            <p className="hstack justify-content-between">
              {t("Given cash")}: <span className="fw-bold">{formatVNDCurrency(currentOrder?.given_cash)}</span>
            </p>
            <p className="hstack justify-content-between">
              {t("Excess cash")}: <span className="fw-bold">{formatVNDCurrency(currentOrder?.excess_cash)}</span>
            </p>
          </Fragment>
        )}
      </SubmitModal>

      {showCustomerModal && (
        <AddEditCustomerModal
          isOpen={showCustomerModal}
          toggle={toggleCustomerModal}
          customerId={currentOrder?.customer?.id}
          onAddUpdateCustomer={handleAddUpdateCustomer}
          isInPOS={true}
        />
      )}
      {/* <QRModal isOpen={isOpenQR} toggle={toggleQr} order_id={payingOrderId} deleteCurrentOrder={deleteCurrentOrder} /> */}
      <PaymentsModal
        isOpen={showPaymentModal}
        toggle={toggleOffPaymentModal}
        currentCustomer={currentOrder?.customer}
        payments={currentOrder?.payments}
        onSave={handleSavePayments}
        amountTotal={amountTotal}
        givenCash={currentOrder?.given_cash ?? 0}
        excessCash={currentOrder?.excess_cash ?? 0}
        rewardProgram={rewardProgram}
        redeemPoint={currentOrder?.redeem_point ?? 0}
      />
      <DiscountModal
        isOpen={showDiscountModal}
        toggle={toggleDiscountModal}
        onChangeDiscountPrice={onChangeDiscountPrice}
        discountPrice={currentOrder?.discount ?? 0}
        maxDiscount={
          (currentOrder?.order_line_items ?? []).reduce((total, item) => total + item.quantity * item.sale_price, 0) ??
          0
        }
        currentOrder={currentOrder}
        onChangeVoucher={(data) => {
          setDataVoucher(data);
        }}
      />
      <SimpleBar className="h-100">
        <div className="hstack gap-3 p-4 justify-content items-center">
          <ButtonTheme
            className={`btn btn-light w-100 ${isQuickActionTab && "border-primary text-primary"}`}
            onClick={() => toggle("eat-in")}
          >
            Eat in
          </ButtonTheme>
          <ButtonTheme
            className={`btn btn-light w-100 ${!isQuickActionTab && "border-primary text-primary"}`}
            onClick={() => toggle("delivery")}
          >
            Delivery
          </ButtonTheme>
        </div>
        <Card className="d-flex flex-column mb-0" style={{ height: "calc(100vh - 50px)" }}>
          <CardBody className="flex-grow-1 py-1">
            {selectCustomerLoading ? (
              <Loader />
            ) : currentOrder?.customer ? (
              <Fragment>
                <div className="hstack">
                  <i className="ri-user-3-line fs-2 me-3"></i>
                  <div className="flex-grow-1">
                    <div className="hstack">
                      <span className="flex-grow-1">
                        <span className="text-primary cursor-pointer" onClick={toggleOnCustomerModal}>
                          <FullNameRender
                            first_name={currentOrder?.customer.first_name}
                            last_name={currentOrder?.customer.last_name}
                          />
                        </span>
                        {currentOrder?.customer.phone && <span>{" - " + currentOrder?.customer.phone}</span>}
                      </span>
                      <ButtonTheme
                        type="button"
                        className="btn btn-icon btn-ghost-danger rounded-circle"
                        onClick={handleRemoveCustomer}
                      >
                        <i className="ri-close-line fs-22"></i>
                      </ButtonTheme>
                    </div>
                    <div className="hstack">
                      <span className="flex-grow-1">
                        {t("Debt")}: {formatVNDCurrency(0)}
                      </span>
                      {currentOrder?.customer?.loyal_customer ? (
                        <UncontrolledDropdown>
                          <DropdownToggle href="#" className="btn" tag="button" caret>
                            {t("Current point")}: {currentOrder?.customer?.loyal_customer?.point ?? 0}
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end dropdown-menu-lg p-0" toggle={false}>
                            <div className="hstack p-2">
                              <span className="flex-grow-1 fw-semibold">{t("Accumulated points")}</span>
                              <span>{currentOrder?.customer?.loyal_customer?.point ?? 0}</span>
                            </div>
                            {rewardProgram && (
                              <div className="hstack p-2">
                                <span className="flex-grow-1 fw-semibold">{t("Exchange value")}</span>
                                <span>
                                  {(currentOrder?.customer?.loyal_customer?.point ?? 0) * (rewardProgram?.money ?? 0)}
                                </span>
                              </div>
                            )}
                            {currentOrder?.customer?.customer_group && (
                              <div className="hstack p-2">
                                <span className="flex-grow-1 fw-semibold">{t("Group name")}</span>
                                <span>{currentOrder?.customer?.customer_group?.name ?? "---"}</span>
                              </div>
                            )}
                            {/* <div className="hstack p-2">
                              <span className="flex-grow-1 fw-semibold">{t("Active card date")}</span>
                              <span>{new Date().toLocaleString()}</span>
                            </div>
                            <div className="hstack p-2">
                              <span className="flex-grow-1 fw-semibold">{t("Point to upgrade")}</span>
                              <span>0</span>
                            </div> */}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      ) : (
                        <>
                          <span className="me-3 hstack">
                            {t("Accumulated points")}:{" "}
                            <i className="text-danger ri-information-line fs-16 align-bottom ms-1"></i>
                          </span>
                          <ButtonTheme className="btn btn-ghost-primary fw-bold hstack p-0">
                            {t("Activate")} <i className="ri-arrow-right-s-line align-bottom fs-16"></i>
                          </ButtonTheme>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              <CustomersSearchDropdown
                customerRef={customerRef}
                onSelectCustomer={handleSelectCustomer}
                toggleOnCustomerModal={toggleOnCustomerModal}
              />
            )}
            <div className="d-flex justify-content-between border-top border-bottom py-1 mb-2">
              <div className="form-check form-check-primary hstack">
                <Input
                  id="delivery-checkbox"
                  type="checkbox"
                  className="mt-0"
                  checked={isDelivery}
                  onChange={(e) => {
                    setIsDelivery(e.target.checked);
                  }}
                />
                <Label for="delivery-checkbox" className="ms-1 mb-0">
                  {t("Delivery")}
                </Label>
              </div>
              <div className="hstack gap-3" style={{ visibility: isDelivery ? "visible" : "hidden" }}>
                <Label className="me-1 mb-0">{t("Source")}:</Label>
                <SelectTheme
                  id="app-options"
                  className="w-lg"
                  options={apps.map((item) => {
                    return { label: item.name, value: item.id, raw: item };
                  })}
                  isForm={false}
                  classWrapper="flex-1 w-lg"
                  value={
                    currentOrder?.source
                      ? {
                          label: currentOrder?.source.name,
                          value: currentOrder?.source.id,
                          raw: currentOrder?.source,
                        }
                      : null
                  }
                  // onChange={(value) => {
                  //   const data = value.raw;
                  //   const { updated_at, company_id, created_at, ...newSource } = data;
                  //   handleChangeSource(newSource);
                  // }}
                />
              </div>
            </div>
            <div className="hstack mb-1">
              <span className="flex-grow-1">
                {t("Total")}: (<strong>{orderQuantity}</strong> {t(`product${orderQuantity > 1 ? "s" : ""}`)})
                {discount && (
                  <Tooltip title={discount.name}>
                    <i
                      className="ri-gift-fill fw-bold align-bottom cursor-pointer ms-3"
                      onClick={togglePromotionModal}
                    ></i>
                  </Tooltip>
                )}
              </span>{" "}
              <span className="flex-shrink-0 fw-bold">{formatVNDCurrency(amountSubTotal)}</span>
            </div>
            {dataVoucher?.total_apply_voucher && (
              <div className="hstack mb-1">
                <div className="flex-grow-1">
                  <span>{t("Voucher")}</span>
                  {!dataVoucher?.apply_with_other_promotion && Number(amountDiscount) > 0 && (
                    <Tooltip
                      title={t(
                        "The applied code does not count towards customer discounts, point discounts, automatic discounts for orders and promotional programs.",
                      )}
                    >
                      <i className="ms-2 ri-information-line text-danger align-bottom cursor-pointer"></i>
                    </Tooltip>
                  )}
                </div>
                <div className="flex-shrink-0">
                  {formatVNDCurrency(
                    !dataVoucher?.apply_with_other_promotion && Number(amountDiscount) > 0
                      ? 0
                      : dataVoucher?.total_apply_voucher ?? 0,
                  )}
                </div>
              </div>
            )}
            <div className="hstack mb-1">
              <span className="flex-grow-1">{t("Discount")} (F6)</span>
              <div className="flex-shrink-0">
                <Button
                  style={{ color: "var(--vz-body-color)" }}
                  className="btn btn-outline-light border-0 border-bottom w-sm p-0 py-1 text-end rounded-0"
                  onClick={() => {
                    setShowDiscountModal(true);
                  }}
                >
                  {formatVNDCurrency(Number(amountDiscount) + Number(amountLoyaltyDiscount))}
                </Button>
              </div>
            </div>
            <div className="hstack mb-1">
              <span className="flex-grow-1 text-uppercase">{t("Customer must pay")}</span>{" "}
              <span className="flex-shrink-0 fw-bold">{formatVNDCurrency(amountTotal)}</span>
            </div>
            {rewardProgram && currentOrder?.customer?.loyal_customer && (
              <div className="hstack justify-content-between mb-1">
                <div className="hstack gap-2">
                  {t("Pay")}
                  <Tooltip title={`1 ${t("point")} = ${formatVNDCurrency(rewardProgram?.money)}`}>
                    <div style={{ width: "75px" }}>
                      <InputTheme
                        type="number"
                        isForm={false}
                        value={tempRedeemPoint}
                        max={currentOrder?.customer?.loyal_customer?.point ?? 0}
                        min={0}
                        formNoMargin={true}
                        className="text-end border-0 border-bottom fw-medium px-0 rounded-0"
                        onChange={(data) => {
                          setTempRedeemPoint(data);
                        }}
                      />
                    </div>
                  </Tooltip>
                  {t("Point")}
                </div>
                <span className="flex-shrink-0 fw-bold">
                  {formatVNDCurrency(currentOrder?.redeem_point ?? 0 * rewardProgram?.money * 0)}
                </span>
              </div>
            )}
            <div className="hstack mb-1">
              <div className="flex-grow-1">
                <p className="mb-0">{t("Payment")}</p>
                <ButtonTheme className="btn btn-ghost-primary p-0 border-0 fw-bold" onClick={toggleOnPaymentModal}>
                  {currentOrder?.payments
                    ? currentOrder?.payments
                        .map((item) => {
                          const name = paymentMethods.find(
                            (paymentItem) => paymentItem.id === item.payment_method_id,
                          )?.name;
                          return t(name);
                        })
                        .join(", ")
                    : t("Add payment method")}
                </ButtonTheme>
              </div>
              <div className="flex-shrink-0 fw-bold">
                {formatVNDCurrency((currentOrder?.payments ?? []).reduce((total, item) => total + +item.amount, 0))}
              </div>
            </div>
            <div className="hstack mb-1">
              <div className="flex-grow-1">
                <p className="mb-0">{t("Amount due")}</p>
              </div>
              <div className="flex-shrink-0 fw-bold text-danger">
                {formatVNDCurrency(
                  (currentOrder?.total ?? 0) -
                    ((currentOrder?.payments ?? []).reduce((total, item) => total + +item.amount, 0) +
                      (currentOrder?.redeem_point && currentOrder?.reward_program
                        ? Number(currentOrder.redeem_point) * Number(currentOrder.reward_program.money)
                        : 0)),
                )}
              </div>
            </div>
            <div className="hstack mb-1">
              <span className="flex-grow-1">{t("Given cash")}</span>{" "}
              <div style={{ width: "100px" }}>
                <InputTheme
                  isForm={false}
                  format="price"
                  className="text-end border-0 border-bottom fw-medium px-0 rounded-0"
                  value={tempGivenCash}
                  onChange={(data) => {
                    setTempGivenCash(data);
                  }}
                  // onBlur={(e) => {
                  //   const paymentTotal = (currentOrder?.payments ?? []).reduce(
                  //     (total, item) => total + +item.amount,
                  //     0,
                  //   );
                  //   const data = { excess_cash: tempGivenCash - paymentTotal, given_cash: tempGivenCash };
                  //   onChangeValue(data);
                  // }}
                />
              </div>
              {/* <span className="flex-shrink-0 fw-bold">{formatVNDCurrency(currentOrder?.given_cash ?? 0)}</span> */}
            </div>
            <PriceSuggestion
              currentPrice={(currentOrder?.payments ?? []).reduce((total, item) => total + +item.amount, 0)}
              givenCash={tempGivenCash}
              onSelectPrice={(data) => {
                calculateExcessCash(data);
              }}
            />
            <div className="hstack border-top py-1">
              <span className="flex-grow-1">{t("Excess cash")}</span>{" "}
              <span className="flex-shrink-0 fw-bold">{formatVNDCurrency(currentOrder?.excess_cash ?? 0)}</span>
            </div>
          </CardBody>
          <CardFooter className="flex-shrink-0 border-0 pt-0 pb-1">
            <OrderNoteInput onChangeValue={onChangeValue} currentOrder={currentOrder} />
            <div className="form-check form-check-primary hstack py-2 border-bottom border-top mb-3">
              <Input
                id="invoice-ie-checkbox"
                type="checkbox"
                className="mt-0"
                checked={isInvoiceIE}
                onChange={(e) => {
                  setIsInvoiceIE(e.target.checked);
                }}
              />
              <Label for="invoice-ie-checkbox" className="ms-1 mb-0 hstack">
                {t("Invoice issuance electronically")}{" "}
                <Tooltip title={t("Invoice issuance electronically")}>
                  <i className="ri-information-line mx-1 text-secondary"></i>
                </Tooltip>
              </Label>
            </div>
            <Row>
              <Col md={5}>
                <ButtonTheme type="button" className="btn btn-light w-100 h-100 px-1" onClick={toggleOnPaymentModal}>
                  {t("Change payment method")} (F7)
                </ButtonTheme>
              </Col>
              <Col md={7}>
                <ButtonTheme
                  type="button"
                  className={"btn btn-primary w-100 h-100 text-uppercase fs-3"}
                  onClick={() => {
                    const validate = validateOrder();
                    validate && setSubmitPayModal(true);
                  }}
                >
                  {t("Pay (F1)")}
                </ButtonTheme>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </SimpleBar>
    </Fragment>
  );
};

const OrderNoteInput = ({ currentOrder, onChangeValue }) => {
  const [note, setNote] = useState("");
  useEffect(() => {
    setNote(currentOrder?.note ?? "");
  }, [currentOrder?.note]);
  return (
    <div className="form-icon mb-3">
      <InputTheme
        isForm={false}
        value={note}
        className="form-control form-control-icon rounded-1"
        placeholder={"Enter order note"}
        onChange={(data) => setNote(data)}
        onBlur={() => onChangeValue({ note: note })}
      />
      <i className="ri-edit-2-line"></i>
    </div>
  );
};
export default RightSideBar;
