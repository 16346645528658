import { useTranslation } from "react-i18next";
import { Card, Input, Row, Col, Collapse } from "reactstrap";
import SimpleBar from "simplebar-react";
import ImageCustom from "./ImageCustom";
import { Tooltip } from "antd";
import InputStep from "./InputStep";
import { formatVNDCurrency } from "../../helpers/format_helper";
import { DiscountTypes } from "../constants/common";
import ButtonTheme from "./ButtonTheme";
import { Fragment, useEffect, useState } from "react";
import PriceInput from "./Input/PriceInput";
import ServiceNameInput from "./Input/ServiceNameInput";
import RemoveProductModal from "../../pages/POS/Components/Modal/RemoveProductModal";
import InputTheme from "./InputTheme";
import SelectTheme from "./SelectTheme";
import { useDebounce } from "../Hooks/UseDebounce";

const POSFnBOrderCardItemsTable = ({
  discount_id,
  products,
  divClass,
  discount,
  discount_details,
  removeProductFromCart,
  onChangeQuantity,
  selectedLocation,
  selectedPriceGroup,
  onChangeOrderLineItem = () => {},
  onChangeOrderLineItemUnit = () => {},
}) => {
  const { t } = useTranslation();
  const ViewConditionsCase = [DiscountTypes.BY_PRODUCT, DiscountTypes.BY_QUANTITY];
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [show, setShow] = useState(false);

  const toggleOn = (product, index) => {
    setShow(true);
    setSelectedProduct(product);
    setSelectedIndex(index);
  };

  const toggleOff = () => {
    setShow(false);
    setSelectedIndex(null);
    setSelectedProduct(null);
  };

  const onDeleteClick = () => {
    removeProductFromCart(selectedIndex);
    toggleOff();
  };

  return (
    <div className="tw-mt-3">
      <Row>
        {/* Left sidebar column */}
        <Col md={2} className="p-4 h-full">
          <SimpleBar className="overflow-auto">
            <div className="d-flex flex-column gap-4">
              <button
                className="px-2 py-4 border rounded hover:bg-gray-200 flex items-center justify-center"
                // onClick={() => setFilter("All")}
              >
                All
              </button>
              <button
                className="px-2 py-4 border rounded hover:bg-gray-200 flex items-center justify-center"
                // onClick={() => setFilter("Available")}
              >
                Fast Food
              </button>
              <button
                className="px-2 py-4 border rounded hover:bg-gray-200 flex items-center justify-center"
                // onClick={() => setFilter("Used")}
              >
                Drink
              </button>
              <button
                className="px-2 py-4 border rounded hover:bg-gray-200 flex items-center justify-center"
                // onClick={() => setFilter("Ordering")}
              >
                Sea Food
              </button>
            </div>
          </SimpleBar>
        </Col>

        {/* Products column */}
        <Col md={10}>
          <SimpleBar className="px-3 overflow-auto">
            <Row>
              {products.map((product, index) => {
                const units = (product?.variants_with_same_original_sku ?? []).map((item) =>
                  !item?.unit ? { id: "Default", name: "Default", data: null } : { ...item.unit, data: item },
                );
                let discountValue = product.unit_price - product.sale_price;
                if (ViewConditionsCase.includes(discount?.type)) {
                  const effectiveItem = discount_details.find((item) => item.sku === product.sku);
                  if (effectiveItem) {
                    discountValue = effectiveItem.unit_price - effectiveItem.sale_price;
                  }
                }
                let total = product.quantity * product.sale_price;
                if (discount_id && ViewConditionsCase.includes(discount?.type)) {
                  const effectiveItem = discount_details.find((item) => item.sku === product.sku);
                  if (effectiveItem) {
                    total = effectiveItem.quantity * effectiveItem.sale_price;
                  }
                }
                return (
                  <Col xxl={2} xl={3} sm={6} xs={12} key={index} className="fs-11 mb-2">
                    <Card className="h-100 d-flex flex-column justify-content-between">
                      <div className="mb-4 w-full">
                        {!product?.custom ? (
                          <>
                            <div className="mb-2 w-full">
                              <ImageCustom image={product.image_url} name={product.name} avatarSize={"avatar-lg"} />
                            </div>
                            <Tooltip title={product.name}>
                              <div className="text-center font-semibold text-gray-700 truncate">{product.name}</div>
                            </Tooltip>
                          </>
                        ) : (
                          <ServiceNameInput
                            className="form-control border-0 border-b"
                            placeholder={"Service name"}
                            onChangeItem={onChangeOrderLineItem}
                            index={index}
                            product={product}
                          />
                        )}
                      </div>
                      <div className="text-lg font-semibold text-blue-600 mb-2">
                        <PriceInput index={index} product={product} onChangeItem={onChangeOrderLineItem} />
                      </div>
                      <div className="text-sm text-gray-500">{formatVNDCurrency(total)}</div>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </SimpleBar>
        </Col>
      </Row>
    </div>
  );
};

const NoteInput = ({ product, onChangeOrderLineItem = () => {}, index }) => {
  const [note, setNote] = useState("");
  const debouncedNote = useDebounce(note, 1000);
  useEffect(() => {
    setNote(product?.note ?? "");
  }, [product?.note]);
  useEffect(() => {
    onChangeOrderLineItem(index, { ...product, note: debouncedNote });
  }, [debouncedNote]);
  return (
    <InputTheme
      className="border-0 border-bottom rounded-0 px-0"
      placeholder="Enter note"
      isForm={false}
      value={note}
      onChange={(data) => {
        setNote(data);
      }}
    />
  );
};

const UnitSelect = ({
  product,
  onChangeItem = () => {},
  index,
  unitOptions = [],
  selectedLocation,
  selectedPriceGroup,
}) => {
  const [unit, setUnit] = useState(null);
  useEffect(() => {
    const selectedProduct = product.variants_with_same_original_sku.find((item) => {
      return item.sku === product.sku;
    });
    setUnit(selectedProduct?.unit?.id ?? "Default");
  }, [product?.unit]);

  const handleSelectOption = (data) => {
    const selectedProduct = product.variants_with_same_original_sku.find((item) => {
      if (data.value === "Default") {
        return !item?.unit;
      } else {
        return item?.unit?.id === data.value;
      }
    });
    const available =
      (selectedProduct?.inventories || []).find((item) => item.location_id === selectedLocation.value)?.available || 0;
    const price = (selectedProduct?.prices || []).find(
      (priceItem) => priceItem.price_group.id === (selectedPriceGroup?.value || selectedPriceGroup?.id),
    )?.price;
    const newItem = {
      prices: selectedProduct.prices ?? [],
      unit_price: price || selectedProduct?.prices?.[0]?.price || 0,
      sale_price: price || selectedProduct?.prices?.[0]?.price || 0,
      sku: selectedProduct?.sku ?? "",
      name: selectedProduct?.name ?? "",
      variant_name: selectedProduct?.name ?? "",
      image_url: selectedProduct.images?.[0]?.url ?? "",
      location: selectedLocation?.value
        ? { id: selectedLocation?.value, name: selectedLocation?.label }
        : selectedLocation,
      category: selectedProduct?.category
        ? { id: selectedProduct?.category?.id, name: selectedProduct?.category?.name }
        : null,
      brand: selectedProduct?.brand ? { id: selectedProduct?.brand?.id, name: selectedProduct?.brand?.name } : null,
      quantity: 1,
      unit: data?.data,
      variants_with_same_original_sku: product?.variants_with_same_original_sku ?? null,
      available,
    };
    onChangeItem(index, newItem);
  };
  return (
    <SelectTheme
      placeholder="Select unit"
      isForm={false}
      options={unitOptions}
      value={unit}
      onChange={(data) => {
        handleSelectOption(data);
      }}
      isClearable={false}
    />
  );
};

export default POSFnBOrderCardItemsTable;
