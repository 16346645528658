import { useTranslation } from "react-i18next";
import { Input, Table } from "reactstrap";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import { Tooltip } from "antd";
import InputStep from "../../../../Components/Common/InputStep";
import { formatVNDCurrency } from "../../../../helpers/format_helper";
import { DiscountTypes } from "../../../../Components/constants/common";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { Fragment, useEffect, useState } from "react";
import PriceInput from "../../../../Components/Common/Input/PriceInput";
import ServiceNameInput from "../../../../Components/Common/Input/ServiceNameInput";
import RemoveProductModal from "../Modal/RemoveProductModal";
import InputTheme from "../../../../Components/Common/InputTheme";
import SelectTheme from "../../../../Components/Common/SelectTheme";
import { useDebounce } from "../../../../Components/Hooks/UseDebounce";
import SearchDropdown from "../../../../Components/Common/ProductsSearchDropDown";

const DeliveryList = ({
  discount_id,
  products,
  divClass,
  tableClass,
  theadClass,
  trClass,
  discount,
  discount_details,
  removeProductFromCart,
  onChangeQuantity,
  selectedLocation,
  selectedPriceGroup,
  onChangeOrderLineItem = () => {},
  onChangeOrderLineItemUnit = () => {},
}) => {
  const { t } = useTranslation();
  const ViewConditionsCase = [DiscountTypes.BY_PRODUCT, DiscountTypes.BY_QUANTITY];
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [show, setShow] = useState(false);
  const toggleOn = (product, index) => {
    setShow(true);
    setSelectedProduct(product);
    setSelectedIndex(index);
  };
  const toggleOff = () => {
    setShow(false);
    setSelectedIndex(null);
    setSelectedProduct(null);
  };
  const onDeleteClick = () => {
    removeProductFromCart(selectedIndex);
    toggleOff();
  };
  return (
    <div className={divClass}>
      <RemoveProductModal show={show} onDeleteClick={onDeleteClick} onCloseClick={0} product={selectedProduct} />
      <div className="px-3 flex-shrink-0 p-4 d-flex align-items-center">
        <input
          type="text"
          className="form-control me-2"
          placeholder={t("Search products")}
          aria-label="Search products"
        />
        <button className="btn btn-primary" type="button">
          {t("Thêm order")}
        </button>
      </div>
      <Table hover className={tableClass}>
        <thead className={theadClass}>
          <tr className={trClass}>
            <th style={{ width: "5%" }} className="text-truncate">
              {t("Order ID")}
            </th>
            <th style={{ width: "5%" }}></th>
            <th style={{ width: "5%" }} className="text-truncate">
              {t("Date")}
            </th>
            <th style={{ width: "35%", minWidth: "300px" }} className="text-truncate">
              {t("Total Sales")}
            </th>
            <th style={{ width: "15%" }} className="text-center text-truncate">
              {t("Quantity")}
            </th>
            <th style={{ width: "5%" }} className="text-center text-truncate">
              {t("Available")}
            </th>
            <th style={{ width: "10%", minWidth: "150px" }} className="text-center text-truncate">
              {t("Unit")}
            </th>
            <th style={{ width: "10%" }} className="text-end text-truncate">
              {t("Price")}
            </th>
            <th style={{ width: "5%" }} className="text-end text-truncate">
              {t("Discount")}
            </th>
            <th style={{ width: "5%" }} className="text-end text-truncate">
              {t("Total Amount")}
            </th>
          </tr>
        </thead>

        <tbody>
          {products.map((product, index) => {
            const units = (product?.variants_with_same_original_sku ?? []).map((item) =>
              !item?.unit ? { id: "Default", name: "Default", data: null } : { ...item.unit, data: item },
            );
            let discountValue = product.unit_price - product.sale_price;
            if (ViewConditionsCase.includes(discount?.type)) {
              const effectiveItem = discount_details.find((item) => item.sku === product.sku);
              if (effectiveItem) {
                discountValue = effectiveItem.unit_price - effectiveItem.sale_price;
              }
            }
            let total = product.quantity * product.sale_price;
            if (discount_id && ViewConditionsCase.includes(discount?.type)) {
              const effectiveItem = discount_details.find((item) => item.sku === product.sku);
              if (effectiveItem) {
                total = effectiveItem.quantity * effectiveItem.sale_price;
              }
            }
            return (
              <tr
                style={{ verticalAlign: "middle" }}
                key={index}
                onClick={() => {
                  // onRowClick(row);
                }}
              >
                <td>{products.length - index}</td>
                <td>
                  <ButtonTheme
                    type="button"
                    className="btn btn-outline-danger btn-icon waves-effect waves-light"
                    onClick={() => {
                      toggleOn(product, index);
                    }}
                  >
                    <i className="ri-delete-bin-5-line"></i>
                  </ButtonTheme>
                </td>
                {!product?.custom ? (
                  <Fragment>
                    <td>
                      <ImageCustom image={product.image_url} name={product.name} avatarSize={"avatar-sm"} />
                    </td>
                    <td>
                      <Tooltip title={product.name}>
                        <div className="text-truncate d-block fw-semibold">{product.name}</div>
                      </Tooltip>
                      <NoteInput product={product} index={index} onChangeOrderLineItem={onChangeOrderLineItem} />
                    </td>
                  </Fragment>
                ) : (
                  <td colSpan={2}>
                    <ServiceNameInput
                      className={"form-control border-0 border-bottom"}
                      placeholder={"Service name"}
                      onChangeItem={onChangeOrderLineItem}
                      index={index}
                      product={product}
                    />
                  </td>
                )}
                <td className="text-center">
                  <InputStep
                    id={`pos-item-${products.length - index}`}
                    index={index}
                    onChangeQuantity={onChangeQuantity}
                    quantity={Number(product?.quantity) || 0}
                  />
                </td>
                <td>
                  <div className="fw-medium text-center">{product?.available || 0}</div>
                </td>
                <td>
                  {units.length > 1 ? (
                    <UnitSelect
                      index={index}
                      product={product}
                      onChangeItem={onChangeOrderLineItemUnit}
                      unitOptions={units}
                      selectedLocation={selectedLocation}
                      selectedPriceGroup={selectedPriceGroup}
                    />
                  ) : (
                    <div className="text-center">{t("Default")}</div>
                  )}
                </td>
                <td>
                  <PriceInput index={index} product={product} onChangeItem={onChangeOrderLineItem} />
                </td>
                <td>
                  <div className="fw-medium text-end">{formatVNDCurrency(discountValue)}</div>
                </td>
                <td>
                  <div className="fw-medium text-end">{formatVNDCurrency(total)}</div>
                </td>
              </tr>
            );
          })}
          {discount_details &&
            [
              DiscountTypes.GIFT_BY_ORDER_TOTAL,
              DiscountTypes.GIFT_BY_PURCHASE_PRODUCT,
              DiscountTypes.BY_PURCHASE_PRODUCT,
              DiscountTypes.PRODUCT_BY_ORDER_TOTAL,
            ].includes(discount?.type) &&
            discount_details.map((product, index) => {
              let discountValue = product.unit_price - product.sale_price;
              if (ViewConditionsCase.includes(discount?.type)) {
                const effectiveItem = discount_details.find((item) => item.sku === product.sku);
                if (effectiveItem) {
                  discountValue = effectiveItem.unit_price - effectiveItem.sale_price;
                }
              }
              let total = product.quantity * product.sale_price;
              if (discount_id && ViewConditionsCase.includes(discount?.type)) {
                const effectiveItem = discount_details.find((item) => item.sku === product.sku);
                if (effectiveItem) {
                  total = effectiveItem.quantity * effectiveItem.sale_price;
                }
              }
              return (
                <tr key={index} onClick={() => {}} className="bg-soft-primary" style={{ verticalAlign: "middle" }}>
                  <td>
                    <i className="mdi mdi-brightness-percent text-primary fs-2"></i>
                  </td>
                  <td>
                    <ImageCustom image={product.image_url} name={product.name} avatarSize={"avatar-sm"} />
                  </td>
                  <td>
                    <Tooltip title={product.sku}>{product.sku}</Tooltip>
                  </td>
                  <td>
                    <Tooltip title={product.name}>
                      <div className="text-truncate d-block mb-0">{product.name}</div>
                    </Tooltip>
                  </td>
                  <td className="text-center">{product?.quantity || 0}</td>
                  <td>
                    <div className="text-end">{formatVNDCurrency(product?.unit_price - discountValue)}</div>
                  </td>
                  <td>
                    <div className="fw-medium text-end">{formatVNDCurrency(total)}</div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};
const NoteInput = ({ product, onChangeOrderLineItem = () => {}, index }) => {
  const [note, setNote] = useState("");
  const debouncedNote = useDebounce(note, 1000);
  useEffect(() => {
    setNote(product?.note ?? "");
  }, [product?.note]);
  useEffect(() => {
    onChangeOrderLineItem(index, { ...product, note: debouncedNote });
  }, [debouncedNote]);
  return (
    <InputTheme
      className="border-0 border-bottom rounded-0 px-0"
      placeholder="Enter note"
      isForm={false}
      value={note}
      onChange={(data) => {
        setNote(data);
      }}
    />
  );
};
const UnitSelect = ({
  product,
  onChangeItem = () => {},
  index,
  unitOptions = [],
  selectedLocation,
  selectedPriceGroup,
}) => {
  const [unit, setUnit] = useState(null);
  useEffect(() => {
    const selectedProduct = product.variants_with_same_original_sku.find((item) => {
      return item.sku === product.sku;
    });
    setUnit(selectedProduct?.unit?.id ?? "Default");
  }, [product?.unit]);

  const handleSelectOption = (data) => {
    const selectedProduct = product.variants_with_same_original_sku.find((item) => {
      if (data.value === "Default") {
        return !item?.unit;
      } else {
        return item?.unit?.id === data.value;
      }
    });
    const available =
      (selectedProduct?.inventories || []).find((item) => item.location_id === selectedLocation.value)?.available || 0;
    const price = (selectedProduct?.prices || []).find(
      (priceItem) => priceItem.price_group.id === (selectedPriceGroup?.value || selectedPriceGroup?.id),
    )?.price;
    const newItem = {
      prices: selectedProduct.prices ?? [],
      unit_price: price || selectedProduct?.prices?.[0]?.price || 0,
      sale_price: price || selectedProduct?.prices?.[0]?.price || 0,
      sku: selectedProduct?.sku ?? "",
      name: selectedProduct?.name ?? "",
      variant_name: selectedProduct?.name ?? "",
      image_url: selectedProduct.images?.[0]?.url ?? "",
      location: selectedLocation?.value
        ? { id: selectedLocation?.value, name: selectedLocation?.label }
        : selectedLocation,
      category: selectedProduct?.category
        ? { id: selectedProduct?.category?.id, name: selectedProduct?.category?.name }
        : null,
      brand: selectedProduct?.brand ? { id: selectedProduct?.brand?.id, name: selectedProduct?.brand?.name } : null,
      quantity: 1,
      unit: data?.data,
      variants_with_same_original_sku: product?.variants_with_same_original_sku ?? null,
      available,
    };
    onChangeItem(index, newItem);
  };
  return (
    <SelectTheme
      placeholder="Select unit"
      isForm={false}
      options={unitOptions}
      value={unit}
      onChange={(data) => {
        handleSelectOption(data);
      }}
      isClearable={false}
    />
  );
};
export default DeliveryList;
