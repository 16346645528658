import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Redux States
import { LIST_INSTALLED_CHANNEL } from "./actionType";

import { listInstalledChannelSuccess, listInstalledChannelFail } from "./action";

import { connectionsAPI } from "../../../../helpers/resource_helper";

function* listInstalledChannel({ payload: { ...res } }) {
  try {
    const response = yield call(connectionsAPI.customList, "list", { ...res });
    yield put(listInstalledChannelSuccess(response));
  } catch (error) {
    yield put(listInstalledChannelFail(error));
  }
}

export function* watchListInstalledChannel() {
  yield takeEvery(LIST_INSTALLED_CHANNEL, listInstalledChannel);
}

function* installedChannelsSaga() {
  yield all([fork(watchListInstalledChannel)]);
}

export default installedChannelsSaga;
