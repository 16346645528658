import { Empty, Tabs } from "antd";
import React, { useEffect, useRef, useState } from "react";
import FormBlogLanguage from "./FormBlogLanguage";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import { t } from "i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getListBlogLocale } from "../../../../helpers/service_helper";
import BreadCrumbCustom from "../../../../Components/Common/BreadCrumbCustom";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import languagesCountry from "../../../../common/data/languagesCountry";
import { toast } from "react-toastify";
import Loader from "../../../../Components/Common/Loader";

export default function BlogLanguage() {
  const { id } = useParams();
  const formRef = useRef();
  const navigator = useNavigate();
  const [tabs, setTabs] = useState([]);
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getListBlog = async () => {
      setLoading(true);
      try {
        const response = await getListBlogLocale({ id });
        const mapItems = response?.items.map((i) => {
          return {
            label: (
              <div className="d-flex align-items-center">
                <img
                  style={{ width: "40px", height: "20px" }}
                  src={languagesCountry?.find((item) => item.code === i?.locale)?.flag}
                  alt={i?.locale}
                />
                <div className="text-truncate-two-lines mr-1">
                  {languagesCountry?.find((item) => item.code === i?.locale)?.name || "---"}
                </div>
              </div>
            ),
            key: i.id,
            children: <FormBlogLanguage formRef={formRef} dataDefault={i} />,
          };
        });
        setLoading(false);
        setTabs(mapItems);
      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
    };
    getListBlog();
  }, [id]);
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumbCustom text="Back to blog list" navigateTo="/blogs" t={t}>
          <div className="hstack gap-2">
            <ButtonTheme
              type="button"
              className="btn btn-outline-primary w-sm"
              onClick={() => {
                navigator(`/blogs-language/${id}/new`);
              }}
            >
              {t("Create")}
            </ButtonTheme>
            {tabs.length > 0 && (
              <ButtonTheme
                type="button"
                className="btn btn-outline-primary w-sm"
                onClick={() => formRef.current.handleSubmit()}
              >
                {t("Update")}
              </ButtonTheme>
            )}
          </div>
        </BreadCrumbCustom>
        <Card id="basic-information">
          <CardHeader>
            <h5 className="card-title">{t("Lists locale blog")}</h5>
          </CardHeader>
          <CardBody>
            {loading ? (
              <Loader />
            ) : (
              <div>
                {tabs.length > 0 ? (
                  <Tabs defaultActiveKey={state?.id || "1"} items={tabs} />
                ) : (
                  <Empty description={false} />
                )}
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}
