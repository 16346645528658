// Actions
export const LIST_NOTIFICATION = "LIST_NOTIFICATION";
export const LIST_NOTIFICATION_SUCCESS = "LIST_NOTIFICATION_SUCCESS";
export const LIST_NOTIFICATION_FAIL = "LIST_NOTIFICATION_FAIL";

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL";

/**
 * Delete NOTIFICATION
 */
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAIL = "DELETE_NOTIFICATION_FAIL";

export const RESET_NOTIFICATION_STATE = "RESET_NOTIFICATION_STATE";