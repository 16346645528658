import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import OfflineMode from "../../../../Components/Common/OfflineMode";
import RemoteScreen from "../../../../Components/Common/RemoteScreen";
import AdminHomepage from "../../../../Components/Common/AdminHomepage";
import StaffSelectForm from "./Components/StaffSelectForm";
import LightDark from "../../../../Components/Common/LightDark";
import LocationSelectForm from "./Components/LocationSelectForm";
import FullScreenDropdown from "../../../../Components/Common/FullScreenDropdown";
import SearchDropdown from "../../../../Components/Common/ProductsSearchDropDown";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import DeleteOrderModal from "../Modal/DeleteOrderModal";
import HorizontalScrollingMenu from "../../../../Components/Common/ScrollMenu/HorizontalScrollingMenu";
import LanguageDropdown from "../../../../Components/Common/LanguageDropdown";

import TableSelection from "../Table";

import { changeLayoutMode } from "../../../../store/actions";
import ShortcutGuide from "../../../../Components/Common/ShortcutGuide";

const POSFnBHeader = ({
  selectedPriceGroup,
  selectedLocation,
  currentOrders,
  handleChangeLocation,
  handleChangeStaff,
  selectedStaff,
  selectedOrder,
  handleChangeSelectedOrder,
  deleteOrder,
  addNewCurrentOrders,
  addProductToCart,
  showDropDownProductMenu,
  toggleOffDropDownProductMenu,
  toggleOnDropDownProductMenu,
  scrollContainerRef,
  refShortcutButtonHeader,
  activePage, // Receive activePage from parent
  setActivePage, // Receive setActivePage from parent
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const refFullScreenBtn = useRef(null);
  const refLocationBtn = useRef(null);
  const refRemoteBtn = useRef(null);
  const { layoutModeType } = useMemoizedSelector((state) => ({
    layoutModeType: state.Layout.layoutModeType,
  }));

  const onChangeLayoutMode = (value) => {
    if (changeLayoutMode) {
      localStorage.setItem("local_layout_mode_type", value);
      dispatch(changeLayoutMode(value));
    }
  };

  // Adding new state for the toggle pages

  useImperativeHandle(refShortcutButtonHeader, () => ({
    onFullScreen: () => refFullScreenBtn.current.onFullScreen(),
    onToggleOnLocation: () => refLocationBtn.current.onToggleOnLocation(),
    onToggleRemote: () => refRemoteBtn.current.onToggleRemote(),
  }));

  useEffect(() => {
    const localLayoutModeType = localStorage.getItem("local_layout_mode_type");
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(localLayoutModeType));
    }
  }, []);

  // Function to toggle between pages
  const handlePageChange = (page) => {
    setActivePage(page); // Update the active page when a new tab is clicked
    console.log("This is page:", page);
    console.log("This is page set:", activePage);
  };

  return (
    <header className="pos-header hstack w-100 bg-primary" style={{ height: "50px" }}>
      <div className="h-100" style={{ width: "calc(100% - 452px)" }}>
        <div className="d-flex align-items-center w-100 h-100">
          {/* New Toggle Buttons for Page Switch */}
          <div className="flex-grow-1 h-100 ps-4">
            <HorizontalScrollingMenu className={"h-100"}>
              {["order", "table", "delivery"].map((page, index) => (
                <div
                  key={index}
                  tabIndex={0}
                  className={`h-100 hstack`}
                  style={{
                    backgroundColor:
                      (activePage === "order" || activePage === "table" || activePage === "delivery") &&
                      page === activePage
                        ? "var(--vz-topbar-search-bg)"
                        : "transparent",
                  }}
                >
                  <ButtonTheme
                    className={`btn w-lg h-100 ${activePage === page ? "btn-light text-dark" : "btn-light"}`}
                    onClick={() => handlePageChange(page)}
                  >
                    <span className="hstack gap-2 p-2">
                      {page === "order" ? "Orders" : page === "table" ? "Table" : "Delivery"}
                    </span>
                  </ButtonTheme>
                </div>
              ))}
            </HorizontalScrollingMenu>
          </div>
        </div>
      </div>
      <div className="pe-3 h-100" style={{ width: "452px" }}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="ms-1 d-flex flex-column">
            <LocationSelectForm
              selectedLocation={selectedLocation}
              handleChangeLocation={handleChangeLocation}
              refBtn={refLocationBtn}
            />
            <StaffSelectForm handleChangeStaff={handleChangeStaff} selectedStaff={selectedStaff} />
          </div>
          <div className="hstack" style={{ height: "50px" }}>
            <LanguageDropdown />
            <RemoteScreen refBtn={refRemoteBtn} />
            <FullScreenDropdown refBtn={refFullScreenBtn} />
            <OfflineMode />
            <AdminHomepage />
            <ShortcutGuide />
          </div>
        </div>
      </div>
    </header>
  );
};

const Header = ({ currentOrders, deleteOrder, handleChangeSelectedOrder, selectedOrder, t, scrollContainerRef }) => {
  return (
    <div className="w-100 h-100">
      <HorizontalScrollingMenu className={"h-100"} scrollContainerRef={scrollContainerRef}>
        {currentOrders.map((item, index) => (
          <div
            key={index}
            tabIndex={0}
            className={`h-100 hstack`}
            style={{ backgroundColor: selectedOrder === index && "var(--vz-topbar-search-bg)" }}
          >
            <ButtonTheme
              className={`btn w-lg p-0 ${selectedOrder === index ? "" : "btn-light"} ${
                selectedOrder === index ? "text-dark" : ""
              }`}
              onClick={() => {
                handleChangeSelectedOrder(index);
              }}
            >
              <span className="hstack gap-2 p-2">{t("Menu")}</span>
            </ButtonTheme>
          </div>
        ))}
      </HorizontalScrollingMenu>
    </div>
  );
};

export default POSFnBHeader;
