import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

import withRouter from "../../../Components/Common/withRouter";
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import TableContainerCustom from "../../../Components/Common/TableContainerCustom";
import useMemoizedSelector from "../../../Components/Hooks/useMemoizedSelector";
import ButtonTheme from "../../../Components/Common/ButtonTheme";
import BadgeCustom from "../../../Components/Common/BadgeCustom";
import { Actions, OrderStatusOptions, PaymentStatusOptions } from "../../../Components/constants/common";

import { listOrder } from "../../../store/actions";
import ExpandRowDetails from "./Components/ExpandRowDetails";
import FullNameRender from "../../../Components/Common/FullnameRender";
import { ADD_ORDER } from "../../../common/data/Permission/order";
import ActionDropdown from "../../../Components/Common/ActionDropdown";
import ImageCustom from "../../../Components/Common/ImageCustom";
import LogoTheme from "../../../assets/images/logo-theme.png";

const Orders = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Order List")} | OptiWarehouse`;
  const dispatch = useDispatch();
  const { orders, ordersLoading, ordersAction, ordersLimit, ordersPage, ordersTotal, ordersMessage } =
    useMemoizedSelector((state) => ({
      orders: state.Orders.orders,
      ordersMessage: state.Orders.message,
      ordersAction: state.Orders.action,
      ordersLoading: state.Orders.loading,
      ordersSuccess: state.Orders.success,
      ordersError: state.Orders.error,
      ordersLimit: state.Orders.limit,
      ordersPage: state.Orders.page,
      ordersTotal: state.Orders.total,
    }));

  const { saleChannels } = useMemoizedSelector((state) => ({
    saleChannels: state.SaleChannels.saleChannels,
  }));
  const { version } = useMemoizedSelector((state) => ({
    version: state.Version.version,
  }));
  const [lightLogo, setLightLogo] = useState(null);
  useEffect(() => {
    setLightLogo(version?.data?.dict?.shop_info?.setting_value?.store_logo_light?.url ?? LogoTheme);
  }, [version]);

  const [isExportCSV, setIsExportCSV] = useState(false);

  const defaultSaveFilter = [
    {
      name: (
        <>
          <i className="ri-store-2-fill me-1 align-bottom"></i> {t("All Orders")}
        </>
      ),
      filter: {},
    },
    {
      name: (
        <>
          <i className="ri-checkbox-circle-line me-1 align-bottom"></i> {t("Delivered")}
        </>
      ),
      filter: { status: "DELIVERED" },
    },
    {
      name: (
        <>
          <i className="ri-truck-line me-1 align-bottom"></i> {t("Shipping")}
        </>
      ),
      filter: { status: "SHIPPING" },
    },
    {
      name: (
        <>
          <i className="ri-arrow-left-right-fill me-1 align-bottom"></i> {t("Returns")}
        </>
      ),
      filter: { status: "RETURNED" },
    },
    {
      name: (
        <>
          <i className="ri-close-circle-line me-1 align-bottom"></i> {t("Cancelled")}
        </>
      ),
      filter: { status: "CANCELLED" },
    },
  ];

  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={orders} />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Order List")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-3 flex-wrap">
                      <ButtonTheme
                        type="button"
                        className="btn btn-light"
                        icon="ri-file-text-line"
                        onClick={() => setIsExportCSV(true)}
                      >
                        {t("Export")}
                      </ButtonTheme>
                      <ButtonTheme
                        // keyPermission={ADD_ORDER}
                        className="btn btn-primary"
                        id="create-btn"
                        icon="ri-add-line text-white"
                        onClick={() => {
                          navigate("/orders/new");
                        }}
                      >
                        {t("Add Order")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <TableContainerCustom
                  filterType={"orders"}
                  searchPlaceHolder="Search order..."
                  listFilter={[
                    {
                      id: "source.channel_name",
                      type: "selectBox",
                      title: `${t("Source")}`,
                      dataOption: [
                        {
                          id: "system",
                          name: version?.data?.dict?.shop_info?.setting_value?.store_name || "OptiWarehouse",
                        },
                        ...(saleChannels ?? []).map((item) => {
                          return { id: item.key, name: item.name };
                        }),
                      ],
                    },
                    {
                      id: "status",
                      type: "selectBox",
                      title: `${t("Order status")}`,
                      dataOption: OrderStatusOptions,
                    },
                    {
                      id: "payment_status",
                      type: "selectBox",
                      title: `${t("Payment status")}`,
                      dataOption: PaymentStatusOptions,
                    },
                    {
                      id: "shift_id",
                      type: "selectBox",
                      title: "Shift",
                      remote: true,
                      pathUrlLoad: "pos/shifts",
                    },
                  ]}
                  defaultSaveFilter={defaultSaveFilter}
                  headers={[
                    "source",
                    "number",
                    "customer",
                    "staff",
                    "updated_at",
                    "created_at",
                    "status",
                    "payment_status",
                    "action",
                  ]}
                  contents={orders}
                  limitPage={Number(ordersLimit)}
                  currentPage={Number(ordersPage) + 1}
                  totalItems={ordersTotal}
                  loading={ordersLoading && ordersAction === Actions.LIST}
                  showPagination={true}
                  scrollHeight="50vh"
                  onCallData={(dataPayload) => {
                    dispatch(listOrder(dataPayload));
                  }}
                  renderExpandedComponent={(data) => <ExpandRowDetails data={data} />}
                  isSettings={true}
                  listHeader={[
                    {
                      title: t("Order details"),
                      key: "detail-variant",
                      children: [
                        {
                          title: t("Source"),
                          dataIndex: "source",
                          width: "70px",
                          key: "source",
                          render: (value, row, index) => {
                            const saleChannel = saleChannels.find((item) => item.key === row?.source?.channel_name);
                            if (saleChannel) {
                              return (
                                <ImageCustom
                                  image={saleChannel?.logo}
                                  name={saleChannel?.name}
                                  avatarSize={"avatar-xs"}
                                  isPreview={false}
                                />
                              );
                            } else {
                              return (
                                <ImageCustom
                                  image={lightLogo}
                                  name={row?.source?.name}
                                  avatarSize={"avatar-xs"}
                                  isPreview={false}
                                />
                              );
                            }
                          },
                        },
                        {
                          title: t("Order Number"),
                          dataIndex: "number",
                          key: "number",
                          render: (value, row, index) => {
                            return (
                              <Link
                                to={`/orders/${row.id}`}
                                className="text-truncate d-block h5 fs-14 mb-0 link-primary"
                              >
                                {value}
                              </Link>
                            );
                          },
                        },
                        {
                          title: t("Staff"),
                          dataIndex: "staff",
                          key: "staff",
                          render: (value, row, index) => {
                            return <div className="text-truncate d-block">{value?.name ?? "---"}</div>;
                          },
                        },
                        {
                          title: t("Customer"),
                          dataIndex: "customer",
                          key: "customer",
                          render: (value, row, index) => {
                            return (
                              <div className="text-truncate d-block">
                                <FullNameRender first_name={value?.first_name} last_name={value?.last_name} />
                              </div>
                            );
                          },
                        },
                        {
                          title: t("Updated at"),
                          dataIndex: "updated_at",
                          key: "updated_at",
                          sorter: true,
                        },
                        {
                          title: t("Created at"),
                          dataIndex: "created_at",
                          key: "created_at",
                          sorter: true,
                          defaultSortOrder: "desc",
                        },
                        {
                          title: t("Order status"),
                          dataIndex: "status",
                          key: "status",
                          sorter: true,
                          render: (value, row, index) => (
                            <>
                              <BadgeCustom status={value} />
                            </>
                          ),
                        },
                        {
                          title: t("Payment status"),
                          dataIndex: "payment_status",
                          key: "payment_status",
                          sorter: true,
                          render: (value, row, index) => <>{value ? <BadgeCustom status={value} /> : "---"}</>,
                        },
                        {
                          title: t("Action"),
                          dataIndex: "action",
                          key: "action",
                          render: (value, row, index) => {
                            return (
                              <ActionDropdown
                                actions={[
                                  {
                                    type: "view",
                                    onClick: () => {
                                      navigate(`/orders/${row.id}`);
                                    },
                                  },
                                  {
                                    type: "edit",
                                    onClick: () => {
                                      navigate(`/orders/${row.id}/edit`);
                                    },
                                  },
                                ]}
                              />
                            );
                          },
                        },
                      ],
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(Orders);
