import React, { useState, useEffect } from "react";
import { Progress } from "reactstrap";

const APIProgressBar = ({ loading }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
      let intervalId;
  
      if (loading && progress < 100) {
        intervalId = setInterval(() => {
          setProgress((prevProgress) => {
            const newProgress = prevProgress + 1;
            return newProgress > 100 ? prevProgress : newProgress;
          });
        }, 50);
      } else if (!loading) {
        setProgress(100);
      }
  
      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    }, [loading, progress]);

  return (
    <div className="w-full max-w-md mx-auto mt-8">
      <p className="font-semibold mb-2">{`Progress: ${Math.round(progress)}%`}</p>
      <Progress value={progress} min={0} max={100} className="w-full" animated={true} color={"primary"} />
    </div>
  );
};

export default APIProgressBar;
