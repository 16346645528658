import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { Container, Row, CardHeader, Col, CardBody } from "reactstrap";
import "nouislider/distribute/nouislider.css";

import { convertValidDate, convertValidTime } from "../../../../helpers/format_helper";

import withRouter from "../../../../Components/Common/withRouter";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import ImportCSVModal from "../../../../Components/Common/ImportCSVModal";
import { Actions } from "../../../../Components/constants/common";

import { deleteNotification, getInfoShop, listNotification, setConfigTour } from "../../../../store/actions";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import ActionDropdown from "../../../../Components/Common/ActionDropdown";
import { Tooltip } from "antd";
import { toast } from "react-toastify";

const NotificationsPage = (props) => {
  const {
    router: { navigate, locations, params, t },
  } = props;
  document.title = `${t("Notifications List")} | OptiWarehouse`;
  const dispatch = useDispatch();

  const {
    notifications,
    notificationsLoading,
    notificationsAction,
    notificationsLimit,
    notificationsSuccess,
    notificationsPage,
    notificationsTotal,
    notificationsMessage,
  } = useMemoizedSelector((state) => ({
    notifications: state.Notifications.notifications,
    notificationsMessage: state.Notifications.message,
    notificationsAction: state.Notifications.action,
    notificationsLimit: state.Notifications.limit,
    notificationsPage: state.Notifications.page,
    notificationsTotal: state.Notifications.total,
    notificationsLoading: state.Notifications.loading,
    notificationsSuccess: state.Notifications.success,
    notificationsError: state.Notifications.error,
  }));

  const { version } = useMemoizedSelector((state) => ({
    version: state.Version.version,
  }));

  const [notification, setNotification] = useState(null);

  const [deleteModal, setDeleteModal] = useState(false);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [isImportCSV, setIsImportCSV] = useState(false);

  const onClickDelete = (notification) => {
    setNotification(notification);
    setDeleteModal(true);
  };

  const handleDeleteNotification = () => {
    if (notification) {
      dispatch(deleteNotification(notification, handleCloseDeleteModal));
    }
  };

  const handleCloseDeleteModal = () => {
    if (!notificationsLoading) {
      setDeleteModal(false);
      setNotification(null);
    }
  };

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const steps = [
    {
      title: t("Upload File"),
      description: t("Put your files here."),
      target: () => ref1.current,
    },
    {
      title: t("Save"),
      description: t("Save your changes."),
      target: () => ref2.current,
    },
  ];

  useEffect(() => {
    dispatch(
      setConfigTour({
        typeTour: "notification",
        steps,
      }),
    );
    return () => {
      dispatch(
        setConfigTour({
          typeTour: "notification",
          steps: [],
        }),
      );
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getInfoShop());
  }, [dispatch]);

  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={notifications} />
      <ImportCSVModal show={isImportCSV} onCloseClick={() => setIsImportCSV(false)} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteNotification}
        onCloseClick={handleCloseDeleteModal}
        loading={notificationsLoading && notificationsAction === Actions.DELETE}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div>
              <div className="card">
                <CardHeader className="border-0">
                  <Row className="align-items-center gy-3">
                    <div className="col-sm">
                      <h5 className="card-title mb-0">{t("Notification List")}</h5>
                    </div>
                    <div className="col-sm-auto">
                      <div className="d-flex gap-3 flex-wrap">
                        <ButtonTheme
                          type="button"
                          className="btn btn-light"
                          icon="ri-external-link-fill"
                          onClick={() => {
                            setIsImportCSV(true);
                          }}
                          refTour={ref2}
                        >
                          {t("Import")}
                        </ButtonTheme>
                        <ButtonTheme
                          type="button"
                          className="btn btn-light"
                          icon="ri-file-text-line"
                          onClick={() => setIsExportCSV(true)}
                        >
                          {t("Export")}
                        </ButtonTheme>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0">
                  <TableContainerCustom
                    filterType={"notifications"}
                    searchPlaceHolder={"Search notification..."}
                    listFilter={[]}
                    headers={["title", "content_title", "content_description", "updated_at", "created_at", "action"]}
                    contents={notifications}
                    limitPage={Number(notificationsLimit)}
                    currentPage={Number(notificationsPage) + 1}
                    totalItems={notificationsTotal}
                    loading={notificationsLoading && notificationsAction === Actions.LIST}
                    showPagination={true}
                    scrollHeight="50vh"
                    onCallData={(dataPayload) => {
                      dispatch(listNotification(dataPayload));
                    }}
                    isSettings={true}
                    listHeader={[
                      {
                        title: t("Notification details"),
                        key: "detail-notification",
                        children: [
                          {
                            title: t("Title"),
                            dataIndex: "title",
                            key: "title",
                            sorter: true,
                            render: (value, row, index) => {
                              return (
                                <Tooltip title={t(row.title)}>
                                  <h5 className="fs-14 mb-1">
                                    <Link
                                      to={`/notifications/${row.id}`}
                                      className="text-truncate-two-lines link-primary"
                                    >
                                      {t(row.title)}
                                    </Link>
                                  </h5>
                                </Tooltip>
                              );
                            },
                          },
                          {
                            title: t("Content title"),
                            dataIndex: "content_title",
                            key: "content_title",
                            render: (value, row, index) => {
                              return <div className="text-truncate-two-lines">{t(value) || "---"}</div>;
                            },
                          },
                          {
                            title: t("Content description"),
                            dataIndex: "content_description",
                            key: "content_description",
                            render: (value, row, index) => {
                              return <div className="text-truncate-two-lines">{t(value) || "---"}</div>;
                            },
                          },
                          {
                            title: t("Updated at"),
                            dataIndex: "updated_at",
                            key: "updated_at",
                            sorter: true,

                            render: (value, row, index) => {
                              return (
                                <>
                                  {convertValidDate(value)},
                                  <small className="text-muted"> {convertValidTime(value)}</small>
                                </>
                              );
                            },
                          },
                          {
                            title: t("Created at"),
                            dataIndex: "created_at",
                            key: "created_at",
                            sorter: true,
                            defaultSortOrder: "desc",
                            render: (value, row, index) => {
                              return (
                                <>
                                  {convertValidDate(value)},
                                  <small className="text-muted"> {convertValidTime(value)}</small>
                                </>
                              );
                            },
                          },
                          {
                            title: t("Action"),
                            dataIndex: "action",
                            key: "action",
                            render: (value, row, index) => {
                              return (
                                <ActionDropdown
                                  index={index}
                                  actions={[
                                    { type: "view", onClick: () => navigate(`/notifications/${row.id}`) },
                                    {
                                      type: "delete",
                                      onClick: () => {
                                        onClickDelete(row);
                                      },
                                    },
                                  ]}
                                />
                              );
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </CardBody>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(NotificationsPage);
