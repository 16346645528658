import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

import { convertValidDate, convertValidTime } from "../../../../helpers/format_helper";

import withRouter from "../../../../Components/Common/withRouter";
import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";
import TableContainerCustom from "../../../../Components/Common/TableContainerCustom";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import { StockAdjustmentStatusOptions } from "../../../../Components/constants/common";

import { listStockAdjustment } from "../../../../store/actions";
import { Link } from "react-router-dom";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import LogoTheme from "../../../../assets/images/logo-theme.png";

const StockAdjustmentsPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  document.title = `${t("Stock Adjustment List")} | OptiWarehouse`;
  const dispatch = useDispatch();

  const {
    stockAdjustments,
    stockAdjustmentsLoading,
    stockAdjustmentsMessage,
    stockAdjustmentsError,
    stockAdjustmentsLimit,
    stockAdjustmentsTotal,
    stockAdjustmentsPage,
  } = useMemoizedSelector((state) => ({
    stockAdjustments: state.StockAdjustments.stockAdjustments,
    stockAdjustmentsLoading: state.StockAdjustments.loading,
    stockAdjustmentsSuccess: state.StockAdjustments.success,
    stockAdjustmentsError: state.StockAdjustments.error,
    stockAdjustmentsLimit: state.StockAdjustments.limit,
    stockAdjustmentsTotal: state.StockAdjustments.total,
    stockAdjustmentsPage: state.StockAdjustments.page,
  }));
  const { saleChannels } = useMemoizedSelector((state) => ({
    saleChannels: state.SaleChannels.saleChannels,
  }));
  const { version } = useMemoizedSelector((state) => ({
    version: state.Version.version,
  }));
  const [lightLogo, setLightLogo] = useState(null);
  useEffect(() => {
    setLightLogo(version?.data?.dict?.shop_info?.setting_value?.store_logo_light?.url ?? LogoTheme);
  }, [version]);

  const [isExportCSV, setIsExportCSV] = useState(false);

  return (
    <div className="page-content">
      <ExportCSVModal show={isExportCSV} onCloseClick={() => setIsExportCSV(false)} data={stockAdjustments} />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="stockAdjustment-list">
              <CardHeader className="border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("Stock Adjustment List")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-3 flex-wrap">
                      <ButtonTheme
                        type="button"
                        className="btn btn-light"
                        icon="ri-file-text-line"
                        onClick={() => setIsExportCSV(true)}
                      >
                        {t("Export")}
                      </ButtonTheme>
                      <ButtonTheme
                        className="btn btn-primary"
                        id="create-btn"
                        icon="ri-add-line text-white"
                        onClick={() => {
                          navigate("/stock-adjustments/new");
                        }}
                      >
                        {t("Add Stock Adjustment")}
                      </ButtonTheme>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <TableContainerCustom
                  filterType={"stockAdjustments"}
                  searchPlaceHolder="Search stock adjustments..."
                  listFilter={[
                    {
                      id: "source.channel_name",
                      type: "selectBox",
                      title: `${t("Source")}`,
                      dataOption: [
                        {
                          id: "system",
                          name: version?.data?.dict?.shop_info?.setting_value?.store_name || "OptiWarehouse",
                        },
                        ...(saleChannels ?? []).map((item) => {
                          return { id: item.key, name: item.name };
                        }),
                      ],
                    },
                    {
                      id: "status",
                      type: "selectBox",
                      title: "Status",
                      dataOption: StockAdjustmentStatusOptions,
                    },
                    {
                      id: "location.id",
                      type: "selectBox",
                      title: "Location",
                      remote: true,
                      pathUrlLoad: "inventory/locations",
                    },
                    {
                      id: "balanced_at",
                      type: "date",
                      title: "Balanced at",
                    },
                  ]}
                  headers={[
                    "source",
                    "code",
                    "status",
                    "created_at",
                    "balanced_at",
                    "creating_staff",
                    "checking_staff",
                    "balancing_staff",
                    "note",
                  ]}
                  contents={stockAdjustments}
                  limitPage={Number(stockAdjustmentsLimit)}
                  currentPage={Number(stockAdjustmentsPage) + 1}
                  totalItems={stockAdjustmentsTotal}
                  loading={stockAdjustmentsLoading}
                  showPagination={true}
                  scrollHeight="50vh"
                  onCallData={(dataPayload) => {
                    dispatch(listStockAdjustment(dataPayload));
                  }}
                  listHeader={[
                    {
                      title: t("Purchase order details"),
                      key: "detail-variant",
                      children: [
                        {
                          title: t("Source"),
                          dataIndex: "source",
                          width: "70px",
                          key: "source",
                          render: (value, row, index) => {
                            const saleChannel = saleChannels.find((item) => item.key === row?.source?.channel_name);
                            if (saleChannel) {
                              return (
                                <ImageCustom
                                  image={saleChannel?.logo}
                                  name={saleChannel?.name}
                                  avatarSize={"avatar-xs"}
                                  isPreview={false}
                                />
                              );
                            } else {
                              return (
                                <ImageCustom
                                  image={lightLogo}
                                  name={row?.source?.name}
                                  avatarSize={"avatar-xs"}
                                  isPreview={false}
                                />
                              );
                            }
                          },
                        },
                        {
                          title: t("Code"),
                          dataIndex: "code",
                          key: "code",
                          render: (value, row, index) => {
                            return <Link to={`/stock-adjustments/${row.id}`}>{value}</Link>;
                          },
                        },
                        {
                          title: t("Status"),
                          dataIndex: "status",
                          key: "status",
                          sorter: true,
                          render: (value, row, index) => (
                            <>
                              <BadgeCustom status={value} />
                            </>
                          ),
                        },
                        {
                          title: t("Created at"),
                          dataIndex: "created_at",
                          key: "created_at",
                          defaultSortOrder: "desc",
                          sorter: true,
                        },
                        {
                          title: t("Balanced at"),
                          dataIndex: "balanced_at",
                          key: "balanced_at",
                          sorter: true,
                        },
                        {
                          title: t("Creating staff"),
                          dataIndex: "creating_staff",
                          key: "creating_staff",
                          render: (value, row, index) => {
                            return <>{value?.name || "---"}</>;
                          },
                        },
                        {
                          title: t("Checking staff"),
                          dataIndex: "checking_staff",
                          key: "checking_staff",
                          render: (value, row, index) => {
                            return <>{value?.name || "---"}</>;
                          },
                        },
                        {
                          title: t("Balancing staff"),
                          dataIndex: "balancing_staff",
                          key: "balancing_staff",
                          render: (value, row, index) => {
                            return <>{value?.name || "---"}</>;
                          },
                        },
                        {
                          title: t("Note"),
                          dataIndex: "note",
                          key: "note",
                          render: (value, row, index) => {
                            return <div className="text-truncate">{value || "---"}</div>;
                          },
                        },
                      ],
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default withRouter(StockAdjustmentsPage);
