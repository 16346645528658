import { useState, useEffect, useMemo, Fragment, useRef, useImperativeHandle } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Link, useSearchParams } from "react-router-dom";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";

import { Popover, Tooltip } from "antd";
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Label, Row } from "reactstrap";
import { Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash";

import {
  checkVaidlVoucherCode,
  getEffectiveRewardProgramAPI,
  getSapoQRCodeDetails,
  listVariantsByOriginalSku,
  updateOrderAPI,
} from "../../../../helpers/service_helper";
import {
  calculateDiscountByCustomerGroup,
  convertValidDate,
  convertValidTime,
  deepCopy,
  formatVNDCurrency,
} from "../../../../helpers/format_helper";

import CustomerDetails from "../../../../Components/Common/CustomerDetails";
import PackagesModal from "../../../../Components/Common/PackagesModal";
import withRouter from "../../../../Components/Common/withRouter";
import { useProfile } from "../../../../Components/Hooks/useProfile";
import ButtonTheme from "../../../../Components/Common/ButtonTheme";
import BreadCrumbCustom from "../../../../Components/Common/BreadCrumbCustom";
import useMemoizedSelector from "../../../../Components/Hooks/useMemoizedSelector";
import SearchDropdown from "../../../../Components/Common/ProductsSearchDropDown";
import TagsInputCustom from "../../../../Components/Common/TagsInputCustom";
import Loader from "../../../../Components/Common/Loader";
import BadgeCustom from "../../../../Components/Common/BadgeCustom";
import cart from "../../../../assets/images/svg/cart.svg";
import ActivitiesModal from "../../../../Components/Common/ActivitiesModal";
import AddFeesModal from "../../../../Components/Common/AddFeesModal";
import OrderHistoryModal from "../../../../Components/Common/OrderHistoryModal";
import CopyableText from "../../../../Components/Common/CopyableText";
import PromotionModal from "../Components/PromotionModal";
import ImageCustom from "../../../../Components/Common/ImageCustom";
import InputTheme from "../../../../Components/Common/InputTheme";
import OrderLineItemsTable from "../../../../Components/Common/OrderLineItemsTable";
import {
  Actions,
  DefaultOrderSteps,
  DefaultPaymentSteps,
  DiscountAmountType,
  DiscountTypes,
  OrderActions,
  OrderStatuses,
  PaymentStatuses,
} from "../../../../Components/constants/common";

import {
  listLocation,
  getOrder,
  updateOrder,
  resetOrderFlag,
  listPaymentMethod,
  addNewOrder,
  listPriceGroup,
  getDiscount,
} from "../../../../store/actions";
import ScrollToError from "../../../../Components/Common/ScrollToError";
import CopyOrderModal from "../Components/CopyOrderModal";
import { useDebounce } from "../../../../Components/Hooks/UseDebounce";
import OrderDiscountDropDown from "../../../../Components/Common/OrderDiscountDropDown";
import OrderAddPaymentsModal from "../../../../Components/Common/OrderAddPaymentsModal";

const OrdersDetailsPage = (props) => {
  const {
    router: { navigate, location, params, t },
  } = props;
  const { userProfile } = useProfile();
  const { id } = params;
  const [searchParams] = useSearchParams();
  const cloneOrderId = searchParams.get("clone_order_id");
  const isEdit = location.pathname.split("/")?.[3] === "edit" || location.pathname.split("/")?.[2] === "new";
  const dispatch = useDispatch();
  document.title = `${t(
    location.pathname.split("/")?.[3] === "edit"
      ? "Edit"
      : location.pathname.split("/")?.[2] === "new"
      ? "Create"
      : "View",
  )} ${t("Order Details")} | OptiWarehouse`;

  const { order, ordersLoading, ordersAction, ordersSuccess, ordersError, ordersMessage } = useMemoizedSelector(
    (state) => ({
      order: state.Orders.order,
      ordersLoading: state.Orders.loading,
      ordersAction: state.Orders.action,
      ordersSuccess: state.Orders.success,
      ordersError: state.Orders.error,
      ordersMessage: state.Orders.message,
    }),
  );

  const { locations, locationsLoading, locationsSuccess, locationsError } = useMemoizedSelector((state) => ({
    locations: state.Locations.locations,
    locationsLoading: state.Locations.loading,
    locationsSuccess: state.Locations.success,
    locationsError: state.Locations.error,
  }));

  const { paymentMethods, paymentMethodsLoading, paymentMethodsSuccess, paymentMethodsError } = useMemoizedSelector(
    (state) => ({
      paymentMethods: state.PaymentMethods.paymentMethods,
      paymentMethodsLoading: state.PaymentMethods.loading,
      paymentMethodsSuccess: state.PaymentMethods.success,
      paymentMethodsError: state.PaymentMethods.error,
    }),
  );

  const { priceGroups, priceGroupsLoading, priceGroupsSuccess, priceGroupsError } = useMemoizedSelector((state) => ({
    priceGroups: state.PriceGroups.priceGroups,
    priceGroupsLoading: state.PriceGroups.loading,
    priceGroupsSuccess: state.PriceGroups.success,
    priceGroupsError: state.PriceGroups.error,
  }));

  const { discount, discountsLoading, discountsSuccess, discountsError } = useMemoizedSelector((state) => ({
    discount: state.Discounts.discount,
    discountsLoading: state.Discounts.loading,
    discountsSuccess: state.Discounts.success,
    discountsError: state.Discounts.error,
  }));

  const { loadingVersion, version } = useMemoizedSelector((state) => ({
    loadingVersion: state.Version.loading,
    version: state.Version.version,
  }));

  const [tempRedeemPoint, setTempRedeemPoint] = useState(null);
  const debouncedRedeemPoint = useDebounce(tempRedeemPoint, 1000);

  const [rewardProgram, setRewardProgram] = useState(null);
  const [applyCustomerGroupDiscount, setApplyCustomerGroupDiscount] = useState(true);
  const [selectedPriceGroup, setSelectedPriceGroup] = useState(null);
  const [statusLoading, setStatusLoading] = useState(OrderActions.EMPTY);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [discountType, setDiscountType] = useState("value");
  const [discountPercent, setDiscountPercent] = useState(0);
  const [copyModal, setCopyModal] = useState(false);

  const [isPackagesModalOpen, setIsPackagesModalOpen] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [orderActivityModal, setOrderActivityModal] = useState(false);
  const [promotionModal, setPromotionModal] = useState(false);
  const [orderHistoryModal, setOrderHistoryModal] = useState(false);
  const [paymentHistoryModal, setPaymentHistoryModal] = useState(false);
  const [showDropDownProductMenu, setShowDropDownProductMenu] = useState(false);
  const [feesModal, setFeesModal] = useState(false);
  const [dataVoucher, setDataVoucher] = useState({});

  const [discountDetails, setDiscountDetails] = useState([]);

  const refToggleDiscount = useRef(null);
  const refCustomerSearch = useRef(null);

  const ViewConditionsCase = [DiscountTypes.BY_PRODUCT, DiscountTypes.BY_QUANTITY];
  const ChooseGiftCase = [DiscountTypes.GIFT_BY_ORDER_TOTAL, DiscountTypes.GIFT_BY_PURCHASE_PRODUCT];
  const ChooseDiscountCase = [DiscountTypes.BY_PURCHASE_PRODUCT, DiscountTypes.PRODUCT_BY_ORDER_TOTAL];
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      customer: order?.customer || null,
      billing_address: order?.billing_address || null,
      location: order?.location || null,
      staff: order?.staff || { id: userProfile?.UserAttributes?.["sub"], name: userProfile?.Username },
      source: order?.source || null,
      shipping_address: order?.shipping_address || null,
      order_line_items: (order?.order_line_items || []).map((item) => {
        if (cloneOrderId) {
          delete item.id;
        }
        return item;
      }),
      note: order?.note || "",
      tags: order?.tags || "",
      total: order?.total || 0,
      sub_total: order?.sub_total || 0,
      discount: order?.discount || 0,
      payments: cloneOrderId ? null : order?.payments || null,
      other_fees: order?.other_fees || null,
      shipping_fee: order?.shipping_fee || 0,
      tax: order?.tax || 0,
      discount_id: order?.discount_id || null,
      discount_by_customer_group: order?.discount_by_customer_group || null,
      redeem_point: order?.redeem_point || null,
      reward_program: order?.reward_program || null,
    },
    onSubmit: (values) => {
      let payload = deepCopy(values);
      payload.order_line_items = payload.order_line_items
        .filter((item) => item.quantity > 0)
        .map((item) => {
          const { available, prices, variants_with_same_original_sku, unit, original_sku, ...res } = item;
          return res;
        });
      if (!payload.customer) {
        toast.error(t("Required customer"), { autoClose: 3000 });
        return;
      }
      if (!selectedPriceGroup) {
        toast.error(t("Required price group"), { autoClose: 3000 });
        return;
      }
      if (!selectedLocation) {
        toast.error(t("Required branch"), { autoClose: 3000 });
        return;
      }
      payload["location"] = selectedLocation;
      if (payload.order_line_items.length === 0) {
        toast.error(t("This order has no items"), { autoClose: 3000 });
        return;
      }
      if (payload.discount_id) {
        payload.discount_details = discountDetails.map((item) => {
          const { available, ...res } = item;
          return res;
        });
      }
      if (!id) {
        dispatch(
          addNewOrder({ ...payload, ...(Object.keys(dataVoucher).length > 0 && { ...dataVoucher }) }, (data) => {
            navigate(`/orders/${data?.id}`);
          }),
        );
      }
    },
  });

  const toggleCopyModal = () => {
    setCopyModal(!copyModal);
  };
  const handleChangePriceGroup = (data) => {
    setSelectedPriceGroup(data);
    const newData = deepCopy(validation.values);
    reCalculateOrderDetails(newData);
    validation.setValues(newData);
  };

  const handleKeyDown = (e) => {
    const keyPressed = e.key;
    if (keyPressed === "F1") {
      e.preventDefault();
      !id && validation.handleSubmit();
    }
    if (keyPressed === "F3") {
      e.preventDefault();
      handleClickAddProductNow();
    }
    if (keyPressed === "F4") {
      e.preventDefault();
      refCustomerSearch.current.onFocusCustomerSearch();
    }
    if (keyPressed === "F6") {
      e.preventDefault();
      refToggleDiscount.current.toggleDiscount();
    }
    // if (keyPressed === "F7") {
    //   e.preventDefault();
    //   refShortcutBtn.current.togglePaymentMethod();
    // }
    if (keyPressed === "F9") {
      e.preventDefault();
      addProductToCart();
    }
    if (keyPressed === "F10") {
      e.preventDefault();
      return;
    }
  };

  useEffect(() => {
    setDataVoucher({
      sub_voucher_total: order?.sub_voucher_total,
    });
  }, [order?.sub_voucher_total]);

  const handleChangeDiscountDetails = (data) => {
    setDiscountDetails(data);
  };

  const handleChangeDiscountId = (data) => {
    validation.setFieldValue("discount_id", data);
  };

  const amountSubTotal = useMemo(() => {
    if (id) {
      return validation.values.order_line_items.reduce((total, item) => total + item.quantity * item.sale_price, 0);
    }
    const defaultValue = validation.values.order_line_items.reduce((total, item, index) => {
      if (
        validation.values.discount_id &&
        discount &&
        discountDetails.length > 0 &&
        [DiscountTypes.BY_PRODUCT, DiscountTypes.BY_QUANTITY].includes(discount.type)
      ) {
        const itemInPromotion = discountDetails.find((subItem) => subItem.sku === item.sku);
        if (itemInPromotion) {
          return total + itemInPromotion.quantity * itemInPromotion.sale_price;
        }
      }
      return total + item.quantity * item.sale_price;
    }, 0);
    if (
      validation.values.discount_id &&
      discount &&
      discountDetails.length > 0 &&
      [...ChooseDiscountCase, ...ChooseGiftCase].includes(discount.type)
    ) {
      const extraProductValue = discountDetails.reduce(
        (total, item, index) => total + item.quantity * item.sale_price,
        0,
      );
      return defaultValue + extraProductValue;
    }
    return defaultValue;
  }, [validation.values.order_line_items, validation.values.discount_id, discount, discountDetails, id]);

  const orderQuantity = useMemo(() => {
    const defaultValue = validation.values.order_line_items.reduce((total, item, index) => {
      return total + +item.quantity;
    }, 0);
    if (!id) {
      const newQuantity = discountDetails.reduce((total, item, index) => {
        return total + +item.quantity;
      }, 0);
      return defaultValue + newQuantity;
    }
    return defaultValue;
  }, [validation.values.order_line_items, validation.values.discount_id, discount, discountDetails]);

  const amountShippingFee = useMemo(() => validation.values.shipping_fee, [validation.values.shipping_fee]);
  const amountFeeTotal = useMemo(
    () =>
      validation.values.other_fees ? validation.values.other_fees.reduce((total, item) => total + +item.amount, 0) : 0,
    [validation.values.other_fees],
  );
  const amountDiscount = useMemo(() => {
    let newAmountDiscount = Number(validation.values.discount);
    if (!validation.values.discount_id) {
      return newAmountDiscount;
    }
    if (discount?.type === DiscountTypes.BY_ORDER_TOTAL) {
      const condition = discount?.discount_by_order_total.find((item) => {
        if (item?.amount_to) {
          return item.amount_to >= amountSubTotal && amountSubTotal >= item.amount_from;
        } else {
          return amountSubTotal >= item.amount_from;
        }
      });
      if (condition) {
        condition.type === DiscountAmountType.VALUE
          ? (newAmountDiscount = condition.amount)
          : (newAmountDiscount = (condition.rate * amountSubTotal) / 100);
        return newAmountDiscount;
      }
    }
    return newAmountDiscount;
  }, [amountSubTotal, validation.values.discount, validation.values.discount_id, discount]);

  const customerGroupDiscount = useMemo(() => {
    if (applyCustomerGroupDiscount && validation.values.discount_by_customer_group) {
      return validation.values.discount_by_customer_group;
    } else {
      return 0;
    }
  }, [validation.values.discount_by_customer_group, applyCustomerGroupDiscount]);

  const amountRedeem = useMemo(() => {
    if (validation.values.reward_program && validation.values.redeem_point) {
      return validation.values.reward_program.money * validation.values.redeem_point;
    } else {
      return 0;
    }
  }, [validation.values]);
  const amountTotal = useMemo(
    () =>
      amountSubTotal +
      +amountShippingFee +
      +validation.values.tax -
      amountFeeTotal -
      amountDiscount -
      customerGroupDiscount -
      (dataVoucher?.sub_voucher_total || 0) -
      amountRedeem,
    [
      amountSubTotal,
      amountShippingFee,
      validation.values.tax,
      amountFeeTotal,
      amountDiscount,
      dataVoucher?.sub_voucher_total,
      amountRedeem,
      customerGroupDiscount,
    ],
  );

  const amountPayments = useMemo(
    () =>
      validation.values.payments ? validation.values.payments.reduce((total, item) => total + +item.amount, 0) : 0,
    [validation.values.payments],
  );
  const amountStillDue = useMemo(() => amountTotal - amountPayments, [amountTotal, amountPayments]);

  const reCalculateOrderDetails = (item) => {
    item.sub_total = item.order_line_items.reduce((total, item) => total + item.sale_price * item.quantity, 0);

    const loyaltyDiscount = calculateDiscountByCustomerGroup(
      item?.customer?.customer_group,
      item?.customer,
      item?.sub_total,
      item?.applying_loyal_discount,
    );
    item.discount_by_customer_group = loyaltyDiscount;

    discountType === "percent" && (item.discount = (item.total * discountPercent) / 100);

    item.total = item.sub_total - item.discount - item.discount_by_customer_group;

    if ((item?.payments ?? []).length === 1) {
      item.payments[0].amount = item.total;
      if (item.reward_program && item.redeem_point) {
        const discount_by_redeem_point = item.reward_program.money * item.redeem_point;
        if (discount_by_redeem_point > item.total) {
          item.reward_program = null;
          item.redeem_point = 0;
          toast.error("Redeem amount must less than or equal to order total!");
        } else {
          item.payments[0].amount -= discount_by_redeem_point;
        }
      }
    } else {
      item.payments = null;
    }
  };

  const addProductToCart = async (selectedProduct) => {
    let newData = deepCopy(validation.values);
    if (selectedProduct) {
      const available =
        (selectedProduct?.inventories || []).find(
          (item) => item.location_id === (selectedLocation?.id || selectedLocation?.value),
        )?.available || 0;
      if (selectedProduct && available <= 0) {
        toast.error(t("This product is currently out of stock"));
        return;
      }
      const isGreaterThanInv =
        (validation.values["order_line_items"].find((item) => item.sku === selectedProduct?.sku)?.quantity || 0) >=
        available;
      if (isGreaterThanInv) {
        toast.error(t("This product is currently not enough stock"));
        return;
      }

      if (selectedProduct?.original_sku) {
        const hasSameOriginSku = validation.values["order_line_items"].find(
          (item) => item?.original_sku === selectedProduct.original_sku && item?.sku !== selectedProduct.sku,
        );
        if (hasSameOriginSku) {
          toast.error(t("This product is already in the cart!"));
          return;
        }
      }

      let variants_with_same_original_sku;
      if (!selectedProduct?.custom && selectedProduct?.original_sku) {
        const variantWithOtherUnits = await listVariantsByOriginalSku(selectedProduct.original_sku);
        if (variantWithOtherUnits) {
          variants_with_same_original_sku = variantWithOtherUnits?.items ?? [];
        }
      }

      let cartItemIndex = newData.order_line_items.findIndex((item) => item.sku === selectedProduct.sku);
      if (cartItemIndex > -1) {
        newData.order_line_items[cartItemIndex].prices = selectedProduct?.prices ?? [];
        newData.order_line_items[cartItemIndex].quantity = Number(newData.order_line_items[cartItemIndex].quantity) + 1;
        reCalculateOrderDetails(newData);
      } else {
        const price = (selectedProduct?.prices || []).find(
          (priceItem) => priceItem.price_group.id === (selectedPriceGroup?.value || selectedPriceGroup?.id),
        )?.price;
        const newItem = {
          prices: selectedProduct.prices ?? [],
          unit_price: price || selectedProduct?.prices?.[0]?.price || 0,
          sale_price: price || selectedProduct?.prices?.[0]?.price || 0,
          sku: selectedProduct?.sku || "",
          name: selectedProduct?.name || "",
          variant_name: selectedProduct?.name || "",
          location: selectedLocation,
          image_url: selectedProduct.images?.[0]?.url || "",
          product_id: selectedProduct.product_id || null,
          variant_id: selectedProduct.id || null,
          category: selectedProduct?.category
            ? { id: selectedProduct?.category?.id, name: selectedProduct?.category?.name }
            : null,
          brand: selectedProduct?.brand ? { id: selectedProduct?.brand?.id, name: selectedProduct?.brand?.name } : null,
          quantity: 1,
          original_sku: selectedProduct?.original_sku ?? "",
          variants_with_same_original_sku: variants_with_same_original_sku ?? null,
          available,
        };
        newItem.product_id = selectedProduct.product_id;
        newItem.variant_id = selectedProduct.id;
        newData.order_line_items.unshift(newItem);
        reCalculateOrderDetails(newData);
      }
    } else {
      const newItem = {
        unit_price: 0,
        sale_price: 0,
        sku: crypto.randomUUID(),
        name: "",
        variant_name: "",
        image_url: null,
        location: selectedLocation,
        quantity: 1,
        custom: true,
      };
      newData.order_line_items.unshift(newItem);
    }
    validation.setValues(newData);
  };
  const handleChangeQuantity = (number, dataIndex) => {
    let newData = deepCopy(validation.values);
    const selectedProduct = newData?.order_line_items?.[dataIndex];
    const available = selectedProduct?.available || 0;
    if (!selectedProduct?.custom && available < number) {
      toast.error(t("This product is currently not enough stock"));
    }
    let orderItemIndex = newData.order_line_items.findIndex((item, index) => index === dataIndex);
    if (orderItemIndex > -1) {
      newData.order_line_items[orderItemIndex].quantity =
        !selectedProduct?.custom && available < number ? available : number;
      reCalculateOrderDetails(newData);
    }
    validation.setValues(newData);
  };
  const removeProductFromCart = (selectedProductIndex) => {
    let newData = deepCopy(validation.values);
    newData.order_line_items = newData.order_line_items.filter((item, i) => i !== selectedProductIndex);
    reCalculateOrderDetails(newData);
    validation.setValues(newData);
  };

  const handleChangeOrderLineItem = (index, data) => {
    let newData = deepCopy(validation.values);
    newData.order_line_items[index] = data;
    reCalculateOrderDetails(newData);
    validation.setValues(newData);
  };
  const handleChangeOrderLineItemUnit = (index, data) => {
    let newData = deepCopy(validation.values);
    const check = newData.order_line_items.find((item, i) => i !== index && item.sku === data.sku);
    if (check) {
      toast.error("Available product in cart!");
      newData.order_line_items.splice(index, 1);
    } else {
      newData.order_line_items[index] = data;
    }
    reCalculateOrderDetails(newData);
    validation.setValues(newData);
  };

  const handleChangeFees = (data) => {
    let newData = deepCopy(validation.values);
    newData.other_fees = data;
    let totalFees = newData.other_fees ? newData.other_fees.reduce((total, item) => total + item.amount, 0) : 0;
    let totalSalePrice = newData.order_line_items.reduce((total, item) => total + item.sale_price * item.quantity, 0);
    newData.total = totalSalePrice + totalFees;
    discountType === "percent" && (newData.discount = (newData.total * discountPercent) / 100);
    newData.sub_total = newData.total - newData.discount;
    validation.setValues(newData);
  };

  const toggleFeesModal = () => {
    setFeesModal(false);
  };

  const handleChangeLocation = (data) => {
    setSelectedLocation({ id: data.value, name: data.label });
    validation.resetForm();
    validation.setValues({
      ...validation.values,
      order_line_items: [],
      payments: null,
      sub_total: 0,
      shipping_fee: 0,
      discount: 0,
      total: 0,
      tax: 0,
      discount_by_customer_group: 0,
      redeem_point: null,
      reward_program: null,
    });
  };

  const handleChangeDiscountPrice = (data) => {
    let newData = deepCopy(validation.values);
    validation.setValues({ ...newData, ...data });
  };
  const handleChangeDiscountPercent = (data) => {
    setDiscountPercent(data);
  };
  const handleChangeDiscountType = (data) => {
    setDiscountType(data);
  };
  const handleSelectCustomer = (data) => {
    const newData = deepCopy(validation.values);
    if (data) {
      let { company_id, updated_at, created_at, ...customerData } = data;
      let billing_address = data.addresses?.[0] || null;
      newData.customer = customerData;
      newData.billing_address = billing_address;
      newData.shipping_address = billing_address;
    } else {
      newData.customer = null;
      newData.billing_address = null;
      newData.shipping_address = null;
    }

    const loyaltyDiscount = calculateDiscountByCustomerGroup(
      newData?.customer?.customer_group,
      newData?.customer,
      newData?.sub_total,
      newData?.applying_loyal_discount,
    );
    newData.discount_by_customer_group = loyaltyDiscount;

    newData.total = newData.sub_total - newData.discount - newData.discount_by_customer_group;
    if ((newData?.payments ?? []).length === 1) {
      newData.payments[0].amount = newData.total;
      if (newData.reward_program && newData.redeem_point) {
        const discount_by_redeem_point = newData.reward_program.money * newData.redeem_point;
        if (discount_by_redeem_point > newData.total) {
          newData.reward_program = null;
          newData.redeem_point = 0;
          toast.error("Redeem amount must less than or equal to order total!");
        } else {
          newData.payments[0].amount -= discount_by_redeem_point;
        }
      }
    } else {
      newData.payments = null;
    }
    validation.setValues(newData);
  };
  const handleChangeCustomerBilling = (data) => {
    validation.setFieldValue("billing_address", data);
  };
  const handleChangeCustomerShipping = (data) => {
    validation.setFieldValue("shipping_address", data);
  };

  const togglePaymentModal = () => {
    statusLoading !== OrderActions.PAY && setPaymentModal(false);
  };

  const togglePackagesModal = () => {
    statusLoading !== OrderActions.PACK && setIsPackagesModalOpen(false);
  };

  const toggleOrderActivitiesModal = () => {
    setOrderActivityModal(false);
  };
  const toggleOrderHistoryModal = () => {
    setOrderHistoryModal(false);
  };
  const togglePaymentHistoryModal = () => {
    setPaymentHistoryModal(false);
  };
  const handleUpdateStatus = async (action, data) => {
    try {
      setStatusLoading(action);
      const res = await updateOrderAPI({ id: order.id, action, data });
      if (res.message) {
        toast.success(res.message);
      } else {
        toast.success(`${action} successfully!`);
      }
      paymentModal && togglePaymentModal();
      isPackagesModalOpen && togglePackagesModal();
      dispatch(getOrder(order.id));
    } catch (e) {
      console.error(e);
    } finally {
      setStatusLoading(OrderActions.EMPTY);
    }
  };

  const toggleOffDropDownProductMenu = () => {
    setShowDropDownProductMenu(false);
  };
  const toggleOnDropDownProductMenu = () => {
    setShowDropDownProductMenu(true);
  };
  const handleClickAddProductNow = () => {
    toggleOnDropDownProductMenu();
    document.getElementById("search-products").focus();
  };

  const onChangePoint = (point, max_point) => {
    if (!rewardProgram) {
      return;
    }
    const data = {};
    if (Number(point) > 0) {
      data.reward_program = rewardProgram;
      if (Number(point) > Number(max_point)) {
        data.redeem_point = Number(max_point);
      } else {
        data.redeem_point = Number(point);
      }
      const discount_by_redeem_point = data.redeem_point * data.reward_program.money;
      if ((validation.values?.payments ?? []).length === 1 && validation.values.total >= discount_by_redeem_point) {
        validation.values.payments[0].amount = validation.values.total - discount_by_redeem_point;
      }
    } else {
      data.reward_program = null;
      data.redeem_point = null;
    }
    validation.setValues({ ...validation.values, ...data });
  };
  useEffect(() => {
    if (validation.values?.customer?.loyal_customer) {
      onChangePoint(debouncedRedeemPoint, validation.values?.customer?.loyal_customer?.point ?? 0);
    }
  }, [debouncedRedeemPoint]);

  const onChangeTagInputs = (e) => {
    let newTags = e.map((item) => item.trim());
    validation.setFieldValue("tags", newTags.join());
  };

  // const handlePackPackage = async (id) => {
  //   //  setOrder(true);
  //   try {
  //     await packagesAPI.createCustomPath(`${id}/pack`, {});
  //     handleSetStatus(id, "PACKING");
  //   } catch (error) {
  //     toast.error(error.toString());
  //   }
  //   //  setLoading(false);
  // };
  const handleAddPromotion = () => {
    if (validation.values.order_line_items.length === 0) {
      toast.error(t("At least 1 product to add promotion"));
      return;
    }
    setPromotionModal(true);
  };
  const handleRemovePromotion = () => {
    validation.setFieldValue("discount_id", null);
    handleChangeDiscountDetails([]);
  };
  const [dataQr, setDataQr] = useState(false);
  useEffect(() => {
    const getQr = async () => {
      const res = await getSapoQRCodeDetails(`SON123123`);
      const response = await fetch(res?.data?.qr_url);
      const blob = await response.blob();
      // Convert the image blob to base64
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64Data = reader.result;
        setDataQr(base64Data);
      };
    };
    id && getQr();
  }, []);

  useEffect(() => {
    if (validation.values.discount_id) {
      dispatch(getDiscount(validation.values.discount_id));
    }
  }, [validation.values.discount_id]);

  useEffect(() => {
    if (id) {
      dispatch(getOrder(id));
    } else {
      dispatch(resetOrderFlag());
      if (cloneOrderId) {
        dispatch(getOrder(cloneOrderId));
      }
    }
  }, [cloneOrderId, id]);

  const getEffectiveRewardProgram = async () => {
    try {
      const res = await getEffectiveRewardProgramAPI();
      if (res) {
        const { updated_at, created_at, company_id, user, ...rest } = res;
        setRewardProgram(rest);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    dispatch(listLocation({ limit: 20 }));
    dispatch(listPaymentMethod({ limit: 20 }));
    dispatch(listPriceGroup({ limit: 20 }));
    getEffectiveRewardProgram();
  }, [dispatch, id]);

  useEffect(() => {
    if (!priceGroupsLoading) {
      const retailPrice = priceGroups.find((item) => item.name === "Giá bán lẻ");
      const newData = {
        label:
          version?.data?.dict?.shop_info?.setting_value?.store_default_price_group?.name ||
          retailPrice?.name ||
          priceGroups?.[0]?.name ||
          null,
        value:
          version?.data?.dict?.shop_info?.setting_value?.store_default_price_group?.id ||
          retailPrice?.id ||
          priceGroups?.[0]?.id ||
          null,
      };
      setSelectedPriceGroup(newData);
    }
  }, [version, priceGroups, priceGroupsLoading]);

  useEffect(() => {
    if (order?.id) {
      setSelectedLocation(order?.location || null);
    } else {
      const new_location = locations?.[0] ? { id: locations[0].id, name: locations[0].name } : null;
      setSelectedLocation(new_location);
    }
  }, [order, locations]);

  useEffect(() => {
    if (!id) {
      validation.setFieldValue("discount_id", null);
      handleChangeDiscountDetails([]);
    }
  }, [validation.values.order_line_items]);

  useEffect(() => {
    if (!id) {
      setDataVoucher({});
    }
  }, [validation.values.order_line_items, amountDiscount]);

  return (
    <div className="page-content" onKeyDown={handleKeyDown} tabIndex="0">
      <Container fluid>
        {isPackagesModalOpen && (
          <PackagesModal
            isOpen={isPackagesModalOpen}
            toggle={togglePackagesModal}
            items={order?.order_line_items || []}
            packages={order?.packages || []}
            onPacking={(data) => {
              handleUpdateStatus(OrderActions.PACK, data);
            }}
            loading={statusLoading === OrderActions.PACK}
          />
        )}
        {feesModal && (
          <AddFeesModal
            toggle={toggleFeesModal}
            isOpen={feesModal}
            handleChangeFees={handleChangeFees}
            fees={validation.values.other_fees}
          />
        )}
        {paymentModal && (
          <OrderAddPaymentsModal
            isOpen={paymentModal}
            toggle={togglePaymentModal}
            paymentMethods={paymentMethods}
            validation={validation}
            onAddPayment={handleUpdateStatus}
            payLoading={statusLoading === OrderActions.PAY}
            payments={order?.payments || []}
          />
        )}
        <CopyOrderModal isOpen={copyModal} toggle={toggleCopyModal} orderId={id} />
        <ActivitiesModal isOpen={orderActivityModal} toggle={toggleOrderActivitiesModal} itemId={order?.id} />
        <OrderHistoryModal
          isOpen={orderHistoryModal}
          toggle={toggleOrderHistoryModal}
          title={"Order history"}
          histories={order?.histories || []}
          defaultStep={DefaultOrderSteps}
        />
        <OrderHistoryModal
          isOpen={paymentHistoryModal}
          toggle={togglePaymentHistoryModal}
          title={"Payment history"}
          histories={order?.payment_histories || []}
          defaultStep={DefaultPaymentSteps}
        />
        <PromotionModal
          isOpen={promotionModal}
          toggle={() => {
            setPromotionModal(false);
          }}
          selectedDiscountId={validation.values.discount_id}
          onChangeDiscountId={handleChangeDiscountId}
          order={validation.values}
          discountDetails={discountDetails}
          onChangeDiscountDetails={handleChangeDiscountDetails}
          selectedLocation={selectedLocation}
          selectedPriceGroup={selectedPriceGroup}
        />
        <BreadCrumbCustom
          navigateTo="/orders"
          isPrint={!!id}
          dataPrint={{ ...order, qr_code_total: dataQr?.data?.qr_url }}
        >
          {!id ? (
            <ButtonTheme
              type="button"
              className="btn btn-outline-primary w-md"
              onClick={validation.handleSubmit}
              loading={ordersLoading && ordersAction === Actions.CREATE}
              disabled={ordersLoading && ordersAction === Actions.CREATE}
            >
              {t("Create")} (F1)
            </ButtonTheme>
          ) : (
            <div className="hstack gap-2">
              {!!isEdit ? (
                <Fragment>
                  <ButtonTheme
                    type="button"
                    className="btn btn-outline-primary w-md"
                    onClick={validation.handleSubmit}
                    loading={ordersLoading && ordersAction === Actions.UPDATE}
                    disabled={ordersLoading || ordersAction === Actions.UPDATE}
                  >
                    {t("Update")}
                  </ButtonTheme>
                  <ButtonTheme
                    type="button"
                    className="btn btn-light w-md"
                    onClick={() => {
                      validation.resetForm();
                      navigate(`/orders/${id}`);
                    }}
                    disabled={ordersLoading}
                  >
                    {t("Close")}
                  </ButtonTheme>
                </Fragment>
              ) : (
                <Fragment>
                  <ButtonTheme
                    type="button"
                    className="btn btn-outline-primary w-md"
                    onClick={toggleCopyModal}
                    disabled={statusLoading !== OrderActions.EMPTY || ordersLoading}
                  >
                    <i class="ri-file-copy-line me-1"></i>
                    {t("Copy")}
                  </ButtonTheme>
                  {[OrderStatuses.DRAFT].includes(order?.status) && (
                    <ButtonTheme
                      type="button"
                      className="btn btn-outline-primary w-md"
                      onClick={() => {
                        handleUpdateStatus(OrderActions.CONFIRM, {});
                      }}
                      loading={statusLoading === OrderActions.CONFIRM}
                      disabled={statusLoading !== OrderActions.EMPTY || ordersLoading}
                    >
                      {t("Confirm")}
                    </ButtonTheme>
                  )}
                  {[
                    OrderStatuses.DRAFT,
                    OrderStatuses.PENDING,
                    OrderStatuses.IN_CANCEL,
                    OrderStatuses.AWAIT_PACKING,
                    OrderStatuses.PACKING,
                    OrderStatuses.PARTIAL_PACKING,
                    OrderStatuses.READY,
                    OrderStatuses.PARTIAL_READY,
                  ].includes(order?.status) && (
                    <ButtonTheme
                      type="button"
                      className="btn btn-outline-danger w-md"
                      onClick={() => {
                        handleUpdateStatus(OrderActions.CANCEL, {});
                      }}
                      loading={statusLoading === OrderActions.CANCEL}
                      disabled={statusLoading !== OrderActions.EMPTY || ordersLoading}
                    >
                      {t("Cancel")}
                    </ButtonTheme>
                  )}

                  {[OrderStatuses.DRAFT, OrderStatuses.PENDING].includes(order?.status) && (
                    <ButtonTheme
                      type="button"
                      className="btn btn-outline-primary w-md"
                      onClick={() => {
                        setIsPackagesModalOpen(true);
                      }}
                      disabled={statusLoading !== OrderActions.EMPTY || ordersLoading}
                    >
                      {t("Pack")}
                    </ButtonTheme>
                  )}
                  {![
                    OrderStatuses.PAID,
                    OrderStatuses.CANCELLED,
                    OrderStatuses.IN_CANCEL,
                    OrderStatuses.SYNC_ERROR,
                  ].includes(order?.status) &&
                    order?.payment_status !== PaymentStatuses.PAID && (
                      <ButtonTheme
                        type="button"
                        className="btn btn-outline-primary w-md"
                        onClick={() => {
                          setPaymentModal(true);
                        }}
                        disabled={
                          statusLoading !== OrderActions.EMPTY ||
                          ordersLoading ||
                          order?.payment_status === PaymentStatuses.PAID
                        }
                      >
                        {t("Pay")}
                      </ButtonTheme>
                    )}
                  {/* {[
                    OrderStatuses.DRAFT,
                    OrderStatuses.PENDING,
                    OrderStatuses.PAID,
                    OrderStatuses.PARTIAL_PAID,
                  ].includes(order.status) && (
                    <UncontrolledDropdown>
                      <div className="btn-group">
                        <ButtonTheme
                          type="button"
                          className="btn btn-primary w-sm"
                          onClick={() => {
                            navigate(`/orders/${id}/edit`);
                          }}
                          disabled={statusLoading !== OrderActions.EMPTY || ordersLoading}
                        >
                          {t("Edit")}
                        </ButtonTheme>
                        <DropdownToggle
                          className="btn dropdown-toggle-split"
                          color="primary"
                          split
                          disabled={statusLoading !== OrderActions.EMPTY || ordersLoading}
                        ></DropdownToggle>
                        <DropdownMenu>
                          {(order?.status === OrderStatuses.DRAFT ||
                            order?.status === OrderStatuses.PENDING ||
                            order?.status === OrderStatuses.PAID) && (
                            <DropdownItem
                              onClick={() => {
                                handleUpdateStatus(OrderActions.CANCEL, {});
                              }}
                            >
                              <i className="ri-truck-line align-bottom me-2"></i>
                              {t("Cancel")}
                            </DropdownItem>
                          )}
                          {order?.status === OrderStatuses.DRAFT && (
                            <DropdownItem
                              onClick={() => {
                                handleUpdateStatus(OrderActions.CONFIRM, {});
                              }}
                            >
                              <i className="ri-takeaway-line align-bottom me-2"></i>
                              {t("Confirm")}
                            </DropdownItem>
                          )}
                          {order?.status === OrderStatuses.PENDING && (
                            <DropdownItem
                              onClick={() => {
                                handleUpdateStatus(OrderActions.PAY, {});
                              }}
                            >
                              <i className="ri-store-2-line align-bottom me-2"></i>
                              {t("Pay")}
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </div>
                    </UncontrolledDropdown>
                  )} */}
                </Fragment>
              )}
            </div>
          )}
        </BreadCrumbCustom>

        {ordersLoading && ordersAction === Actions.GET ? (
          <Loader />
        ) : (
          <Row>
            <Col className={`${!id ? "col-12" : "col-9"}`}>
              <Row>
                <Col className={`${!id ? "col-9" : "col-12"}`}>
                  <CustomerDetails
                    refCustomerSearch={refCustomerSearch}
                    isEdit={isEdit}
                    selectedCustomer={validation.values.customer}
                    shipping_address={validation.values.shipping_address}
                    billing_address={validation.values.billing_address}
                    onSelectCustomer={handleSelectCustomer}
                    addresses={validation.values.customer?.addresses || []}
                    onChangeCustomerBilling={handleChangeCustomerBilling}
                    onChangeCustomerShipping={handleChangeCustomerShipping}
                  />
                </Col>
                {!id && (
                  <Col className="col-3">
                    <Card>
                      <CardHeader>
                        <h5 className="card-title mb-0">{t("Additional info")}</h5>
                      </CardHeader>
                      <CardBody>
                        <div className="mb-3">
                          <Label>{t("Location")}</Label>
                          <ReactSelect
                            id="location-options"
                            classNamePrefix="select"
                            value={{ label: selectedLocation?.name, value: selectedLocation?.id }}
                            onChange={handleChangeLocation}
                            options={locations.map((item) => {
                              return { label: item.name, value: item.id };
                            })}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                )}
                <Col className="col-12">
                  <Card>
                    <CardHeader>
                      <div className="d-flex align-items-center">
                        <h5 className="card-title flex-grow-1 mb-0">
                          {`${t("Order")} ${order?.order_number ? `#${order?.order_number || "---"}` : ""}  `}
                        </h5>
                      </div>
                    </CardHeader>
                    <CardBody>
                      {isEdit && (
                        <div className="hstack gap-3">
                          <div className="flex-grow-1">
                            <SearchDropdown
                              onClickItem={addProductToCart}
                              isOpen={showDropDownProductMenu}
                              toggleOff={toggleOffDropDownProductMenu}
                              toggleOn={toggleOnDropDownProductMenu}
                              location={selectedLocation}
                              priceGroup={selectedPriceGroup}
                            />
                          </div>
                          <div className="flex-shrink-0" style={{ zIndex: "1001" }}>
                            <ReactSelect
                              id="price-group-options"
                              classNamePrefix="select"
                              value={selectedPriceGroup}
                              onChange={(value) => {
                                handleChangePriceGroup(value);
                              }}
                              options={priceGroups.map((item) => {
                                return { label: item.name, value: item.id };
                              })}
                            />
                          </div>
                        </div>
                      )}
                      <div className="my-3 w-100">
                        <CardBody>
                          <OrderLineItemsTable
                            isEdit={isEdit}
                            discount_id={validation.values.discount_id}
                            products={validation.values.order_line_items || []}
                            divClass="table-card table-responsive"
                            tableClass="table table-nowrap align-middle mb-0"
                            theadClass="table-light text-muted"
                            trClass=""
                            discount={discount}
                            removeProductFromCart={removeProductFromCart}
                            onChangeQuantity={handleChangeQuantity}
                            discount_details={discountDetails}
                            onChangeOrderLineItem={handleChangeOrderLineItem}
                            onChangeOrderLineItemUnit={handleChangeOrderLineItemUnit}
                            selectedLocation={selectedLocation}
                            selectedPriceGroup={selectedPriceGroup}
                            noDataElement={
                              <div className="d-flex justify-content-center align-items-center py-5">
                                <div className="text-center">
                                  <img src={cart} className="img-fluid" alt="" />
                                  <h6 className="fs-3 mb-3 text-muted">{t("Order has no products")}</h6>
                                  <ButtonTheme
                                    id="add-product-now-button"
                                    type="button"
                                    className="btn btn-light waves-effect"
                                    onClick={handleClickAddProductNow}
                                  >
                                    {t("Add product")}
                                  </ButtonTheme>
                                </div>
                              </div>
                            }
                          />
                          <Row className="mt-3 border-top border-top-dashed">
                            {isEdit && (
                              <Col>
                                <ButtonTheme
                                  className="btn btn-ghost-primary"
                                  onClick={() => {
                                    addProductToCart();
                                  }}
                                >
                                  <i className="ri-add-circle-line align-bottom me-2" />
                                  {t("Add service (F9)")}
                                </ButtonTheme>
                              </Col>
                            )}
                            {validation.values.discount_id ? (
                              discountsLoading ? (
                                <Loader />
                              ) : (
                                <Col className="py-3">
                                  <h5>{t("Promotion details")}</h5>
                                  <div className="hstack">
                                    <div className="flex-shrink-0 p-1">
                                      <ImageCustom
                                        image={discount?.image?.url}
                                        name={discount.name}
                                        avatarSize={"avatar-md"}
                                      />
                                    </div>
                                    <div className="flex-grow-1 ms-3 d-flex flex-column justify-content-center">
                                      <h5 className="fs-15">
                                        <Tooltip title={t(discount?.name)}>{t(discount?.name)}</Tooltip>
                                      </h5>
                                      <p className="text-muted fw-medium mb-1 text-truncate-two-lines">
                                        {t("Description")}: {discount.description ? t(discount.description) : "---"}
                                      </p>
                                      <p className="text-muted fw-medium mb-1">
                                        {t("Start at")}: {convertValidDate(discount.start_at)},{" "}
                                        <small className="text-muted"> {convertValidTime(discount.start_at)}</small>
                                      </p>
                                      <p className="text-muted fw-medium mb-1">
                                        {t("Expire at")}:{" "}
                                        {discount.expire_at ? (
                                          <>
                                            {convertValidDate(discount.expire_at)},{" "}
                                            <small className="text-muted">
                                              {" "}
                                              {convertValidTime(discount.expire_at)}
                                            </small>
                                          </>
                                        ) : (
                                          t("Limitless")
                                        )}
                                      </p>
                                    </div>
                                    {!id && (
                                      <ButtonTheme className="btn btn-ghost-danger" onClick={handleRemovePromotion}>
                                        {t("Remove promotion")}
                                      </ButtonTheme>
                                    )}
                                  </div>
                                </Col>
                              )
                            ) : (
                              !id && (
                                <Col className="text-end">
                                  <ButtonTheme className="btn btn-ghost-primary" onClick={handleAddPromotion}>
                                    <i className="ri-gift-2-fill align-bottom me-2" />
                                    {t("Add promotion")}
                                  </ButtonTheme>
                                </Col>
                              )
                            )}
                          </Row>
                          <Row className="border-top border-top-dashed">
                            <Col lg={6}>
                              <div className="my-3">
                                <Label>{t("Note")}</Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="note"
                                  onChange={validation.handleChange}
                                  value={validation.values.note}
                                  placeholder={!isEdit ? "" : t("Enter note")}
                                  disabled={!isEdit}
                                />
                              </div>
                              <div className="mb-3">
                                <Label>{t("Tags")}</Label>
                                <TagsInputCustom
                                  value={isEmpty(validation.values.tags) ? [] : validation.values.tags.split(",")}
                                  onChangeTagInputs={onChangeTagInputs}
                                  name="tags"
                                  placeHolder={!isEdit ? "" : t("Enter tags")}
                                  disabled={!isEdit}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <table className="table table-borderless mb-0">
                                <tbody>
                                  <tr>
                                    <td>{`${t("Subtotal")} (${orderQuantity} ${t(
                                      `product${orderQuantity > 1 ? "s" : ""}`,
                                    )})`}</td>
                                    <td className="text-end">{formatVNDCurrency(amountSubTotal)}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {isEdit ? (
                                        <OrderDiscountDropDown
                                          refToggleDiscount={refToggleDiscount}
                                          discount={discount}
                                          maxDiscount={validation.values.sub_total}
                                          amountDiscount={amountDiscount}
                                          values={validation.values}
                                          discountType={discountType}
                                          discountPercent={discountPercent}
                                          discountPrice={validation.values.discount}
                                          onChangeDiscountType={handleChangeDiscountType}
                                          onChangeDiscountPercent={handleChangeDiscountPercent}
                                          onChangeDiscountPrice={handleChangeDiscountPrice}
                                          applyCustomerGroupDiscount={applyCustomerGroupDiscount}
                                          setApplyCustomerGroupDiscount={setApplyCustomerGroupDiscount}
                                        />
                                      ) : (
                                        <span>{t("Discount")}</span>
                                      )}
                                    </td>
                                    <td className="text-end">
                                      {formatVNDCurrency(amountDiscount + customerGroupDiscount)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {isEdit ? (
                                        <PopoverVoucherCode
                                          order={{
                                            total_order: amountSubTotal,
                                            order_line_items: validation.values.order_line_items,
                                          }}
                                          onAddVoucherToOrder={(data) => {
                                            if (
                                              !data.apply_with_other_promotion &&
                                              (Number(amountDiscount) > 0 ||
                                                validation.values.order_line_items.every(
                                                  (order) => Number(order.discount) > 0,
                                                ))
                                            ) {
                                              setDataVoucher({
                                                voucher_id: data.voucher_id,
                                                voucher_code: data.voucher_code,
                                                sub_voucher_total: 0,
                                              });
                                            } else {
                                              setDataVoucher({
                                                voucher_id: data.voucher_id,
                                                voucher_code: data.voucher_code,
                                                sub_voucher_total: data.sub_voucher_total,
                                              });
                                            }
                                          }}
                                        />
                                      ) : (
                                        <span>{t("Voucher code")}</span>
                                      )}
                                      <>
                                        {dataVoucher?.sub_voucher_total === 0 && dataVoucher?.voucher_code && (
                                          <Tooltip
                                            title={t(
                                              "The applied code cannot be used to calculate customer discounts, point discounts, self-entered discounts for orders and promotional programs.",
                                            )}
                                          >
                                            <i className="ms-2 ri-information-line text-danger align-bottom cursor-pointer"></i>
                                          </Tooltip>
                                        )}
                                      </>
                                    </td>
                                    <td className="text-end">{formatVNDCurrency(dataVoucher?.sub_voucher_total)}</td>
                                  </tr>
                                  {id && (
                                    <Fragment>
                                      <tr>
                                        <td>{t("Shipping fee")}</td>
                                        <td className="text-end">
                                          {order?.shipping_fee ? formatVNDCurrency(order?.shipping_fee) : "---"}
                                        </td>
                                      </tr>
                                      <tr className="fw-semibold">
                                        <td>{t("Other fees")}</td>
                                        <td className="text-end">{formatVNDCurrency(amountFeeTotal)}</td>
                                      </tr>
                                      {validation.values.other_fees &&
                                        validation.values.other_fees.map((item, key) => (
                                          <tr key={key}>
                                            <td>
                                              <span className="ms-4">
                                                {key + 1}. {t(item.name)}
                                              </span>
                                            </td>
                                            <td className="text-end">{formatVNDCurrency(item.amount)}</td>
                                          </tr>
                                        ))}
                                    </Fragment>
                                  )}
                                  <tr>
                                    <td>
                                      {t("Tax")}
                                      <i className="ms-2 ri-information-line text-primary align-bottom"></i>
                                    </td>
                                    <td className="text-end">{formatVNDCurrency(validation.values?.tax || 0)}</td>
                                  </tr>
                                  {rewardProgram && validation.values?.customer?.loyal_customer && (
                                    <tr>
                                      <td className="hstack gap-2">
                                        {t("Pay")}
                                        <Tooltip title={`1 ${t("point")} = ${formatVNDCurrency(rewardProgram?.money)}`}>
                                          <div style={{ width: "75px" }}>
                                            <InputTheme
                                              type="number"
                                              isForm={false}
                                              value={tempRedeemPoint}
                                              max={validation.values?.customer?.loyal_customer?.point ?? 0}
                                              min={0}
                                              formNoMargin={true}
                                              className="text-end border-0 border-bottom fw-medium px-0 rounded-0"
                                              onChange={(data) => {
                                                setTempRedeemPoint(data);
                                              }}
                                              disabled={!isEdit}
                                            />
                                          </div>
                                        </Tooltip>
                                        {t("Point")}
                                      </td>
                                      <td className="text-end">
                                        {formatVNDCurrency(
                                          validation.values?.redeem_point ?? 0 * rewardProgram?.money * 0,
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                  <tr>
                                    <td>{t("Amount to pay")}</td>
                                    <td className="text-end">{formatVNDCurrency(amountTotal)}</td>
                                  </tr>
                                  <tr className="border-top border-top-dashed">
                                    <th scope="row">{t("Amount still due")}</th>
                                    <th className="text-end">{formatVNDCurrency(amountStillDue)}</th>
                                  </tr>
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                        </CardBody>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                {id && (
                  <Col className="col-12">
                    <Card>
                      <CardHeader className="hstack">
                        <h5 className="card-title flex-grow-1">{t("Package")}</h5>
                      </CardHeader>
                      <CardBody>
                        {order?.packages && order.packages.length > 0 ? (
                          order?.packages.map((p, index) => (
                            <Card className="profile-project-card shadow-none profile-project-warning mb-3" key={index}>
                              <CardBody>
                                <div className="hstack">
                                  <CopyableText text={p.id}>
                                    <Link className="flex-shrink-0 text-primary" to={`/packages/${p?.id}`}>
                                      {p?.id}
                                    </Link>
                                  </CopyableText>
                                  <div className="flex-shrink-0 ms-3">
                                    <BadgeCustom status={p?.status} />
                                  </div>
                                  <div className="flex-shrink-0 ms-3">
                                    <small className="text-muted">
                                      {convertValidDate(p?.created_at)}, {convertValidTime(p?.created_at)}
                                    </small>
                                  </div>
                                  <div className="flex-grow-1 text-end">
                                    <ButtonTheme
                                      type="button"
                                      className="btn btn-icon btn-ghost-primary rounded-circle"
                                      onClick={() => {
                                        p.shipping_label && window.open(p.shipping_label, "_blank");
                                      }}
                                    >
                                      <i className="bx bxs-printer text-muted fs-22"></i>
                                    </ButtonTheme>
                                  </div>
                                </div>
                                <div>
                                  <div className="hstack py-2">
                                    <div style={{ width: "160px" }}>
                                      {t("Package number")}
                                      <span className="float-end">:</span>
                                    </div>
                                    <CopyableText text={p.package_number}>
                                      <span className="ms-3 text-primary">{p.package_number}</span>
                                    </CopyableText>
                                  </div>
                                  <div className="hstack py-2">
                                    <div style={{ width: "160px" }}>
                                      {t("Tracking number")}
                                      <span className="float-end">:</span>
                                    </div>
                                    <CopyableText text={p.tracking_number}>
                                      <span className="ms-3">{p.tracking_number || "---"}</span>
                                    </CopyableText>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          ))
                        ) : (
                          <div>{t("No package order info")}</div>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </Row>
            </Col>

            {id && (
              <Col className="col-3">
                <Card>
                  <CardHeader>
                    <div className="d-sm-flex align-items-center">
                      <h5 className="card-title flex-grow-1 mb-0">{t("Order Details")}</h5>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <table className="table table-borderless table-responsive mb-0">
                      <tbody>
                        <tr>
                          <td>{t("Sell at")}:</td>
                          <td>{validation.values?.location?.name || "---"}</td>
                        </tr>
                        <tr>
                          <td>{t("Sell by")}:</td>
                          <td>{validation.values?.staff?.name || "---"}</td>
                        </tr>
                        <tr>
                          <td>{t("Schedule delivery")}:</td>
                          <td>{validation.values.schedule_delivery || "---"}</td>
                        </tr>
                        <tr>
                          <td>{t("Source")}:</td>
                          <td>{validation.values?.source?.name || "---"}</td>
                        </tr>
                        <tr>
                          <td>{t("Sold date")}:</td>
                          <td>
                            {order?.created_at ? (
                              <>
                                {convertValidDate(order?.created_at)}, {convertValidTime(order?.created_at)}
                              </>
                            ) : (
                              "---"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>{t("Reference")}:</td>
                          <td>
                            <CopyableText text={order?.external_id || ""}>
                              {order?.external_link ? (
                                <Tooltip title={order?.external_link}>
                                  <Link
                                    to={order.external_link}
                                    target="_blank"
                                    className="link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover"
                                  >
                                    {order?.external_id}
                                  </Link>
                                </Tooltip>
                              ) : (
                                order?.external_id
                              )}
                            </CopyableText>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Link
                      to={"#"}
                      onClick={() => {
                        setOrderActivityModal(true);
                      }}
                    >
                      {t("View order history")}
                    </Link>
                  </CardFooter>
                </Card>
                <Card>
                  <CardHeader>
                    <div className="d-sm-flex align-items-center">
                      <h5 className="card-title flex-grow-1 mb-0">{t("Status History")}</h5>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="mb-3 hstack gap-2">
                      <span className="flex-grow-1">
                        {t("Order status")}: {order?.status ? <BadgeCustom status={order.status} /> : "---"}
                      </span>
                      <ButtonTheme
                        type="button"
                        className="btn btn-sm btn-light"
                        onClick={() => {
                          setOrderHistoryModal(true);
                        }}
                      >
                        {t("View")}
                      </ButtonTheme>
                    </div>
                    <div className="mb-3 hstack gap-2">
                      <span className="flex-grow-1">
                        {t("Payment status")}:{" "}
                        {order?.payment_status ? <BadgeCustom status={order.payment_status} /> : "---"}
                      </span>
                      <ButtonTheme
                        type="button"
                        className="btn btn-sm btn-light"
                        onClick={() => {
                          setPaymentHistoryModal(true);
                        }}
                      >
                        {t("View")}
                      </ButtonTheme>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">
                      <i className="ri-secure-payment-line align-bottom me-1 text-muted"></i> {t("Payment Details")}
                    </h5>
                  </CardHeader>
                  <CardBody>
                    {order.payments ? (
                      order.payments.map((item, index) => (
                        <Card className="profile-project-card shadow-none profile-project-warning mb-3" key={index}>
                          <CardBody>
                            <div className="hstack mb-2">
                              <div className="flex-shrink-0">
                                <p className="text-muted mb-0">{t("Transactions")}:</p>
                              </div>
                              <div className="flex-grow-1 ms-2">
                                <Link className="mb-0" to="/transactions" state={{ transactionID: item?.reference }}>
                                  {item?.reference || "---"}
                                </Link>
                              </div>
                            </div>
                            <div className="hstack mb-2">
                              <div className="flex-shrink-0">
                                <p className="text-muted mb-0">{t("Payment Method")}:</p>
                              </div>
                              <div className="flex-grow-1 ms-2">
                                <h6 className="mb-0">{item?.payment_method?.name || "---"}</h6>
                              </div>
                            </div>
                            <div className="hstack mb-2">
                              <div className="flex-shrink-0">
                                <p className="text-muted mb-0">{t("Card Holder Name")}:</p>
                              </div>
                              <div className="flex-grow-1 ms-2">
                                <h6 className="mb-0">{item?.from_account?.name || "---"}</h6>
                              </div>
                            </div>
                            <div className="hstack mb-2">
                              <div className="flex-shrink-0">
                                <p className="text-muted mb-0">{t("Card Number")}:</p>
                              </div>
                              <div className="flex-grow-1 ms-2">
                                <h6 className="mb-0">{item?.from_account?.account_number || "---"}</h6>
                              </div>
                            </div>
                            <div className="hstack mb-2">
                              <div className="flex-shrink-0">
                                <p className="text-muted mb-0">{t("Total Amount")}:</p>
                              </div>
                              <div className="flex-grow-1 ms-2">
                                <h6 className="mb-0">{formatVNDCurrency(item?.amount)}</h6>
                              </div>
                            </div>
                            <div className="hstack mb-2 gap-2">
                              <ButtonTheme className="btn btn-outline-danger w-sm">{t("Cancel")}</ButtonTheme>
                            </div>
                          </CardBody>
                        </Card>
                      ))
                    ) : (
                      <div>{t("No payment info")}</div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        )}
      </Container>
    </div>
  );
};

const PopoverVoucherCode = ({ order, onAddVoucherToOrder }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const validation = {
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      voucher: "",
    },
    validationSchema: Yup.object({
      voucher: Yup.string().required(t("Please enter voucher code!")),
    }),
    onSubmit: async (values, actions) => {
      try {
        setLoading(true);
        const res = await checkVaidlVoucherCode(values.voucher, order);
        onAddVoucherToOrder({
          sub_voucher_total: res.total_apply_voucher,
          voucher_id: res.voucher_id,
          voucher_code: res.voucher_id,
          apply_with_other_promotion: res.apply_with_other_promotion,
        });
        actions.resetForm();
        setOpen(false);
        setLoading(false);
      } catch (error) {
        toast.error(error.toString());
        setLoading(false);
      }
    },
  };

  return (
    <Popover
      content={
        <Formik
          enableReinitialize={true}
          initialValues={validation.initialValues}
          validationSchema={validation.validationSchema}
          onSubmit={validation.onSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <ScrollToError />
              <div className="d-flex align-items-baseline">
                <InputTheme name={"voucher"} placeholder={t("Enter code")} />
                <ButtonTheme type="submit" className="btn btn-primary mx-2" loading={loading}>
                  {t("Apply")}
                </ButtonTheme>
              </div>
            </Form>
          )}
        </Formik>
      }
      title=""
      trigger="click"
      open={open}
      onOpenChange={() => {
        setOpen(!open);
      }}
    >
      <span className="btn text-primary p-0">{t("Voucher code")}</span>
    </Popover>
  );
};

export default withRouter(OrdersDetailsPage);
