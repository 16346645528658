import {
  GET_IMPORT_SYNCRECORD,
  GET_IMPORT_SYNCRECORD_FAIL,
  GET_IMPORT_SYNCRECORD_SUCCESS,
  LIST_SYNCRECORD,
  LIST_SYNCRECORD_FAIL,
  LIST_SYNCRECORD_SUCCESS,
} from "./actionType";

export const listSyncRecord = (options) => ({
  type: LIST_SYNCRECORD,
  payload: options,
});

export const listSyncRecordFail = (error) => ({
  type: LIST_SYNCRECORD_FAIL,
  payload: error,
});

export const listSyncRecordSuccess = (syncRecords) => ({
  type: LIST_SYNCRECORD_SUCCESS,
  payload: syncRecords,
});

export const getImportSyncRecord = (sync_record_id, show_details) => ({
  type: GET_IMPORT_SYNCRECORD,
  payload: { sync_record_id, show_details },
});

export const getImportSyncRecordFail = (error) => ({
  type: GET_IMPORT_SYNCRECORD_FAIL,
  payload: error,
});

export const getImportSyncRecordSuccess = (importSyncRecord) => ({
  type: GET_IMPORT_SYNCRECORD_SUCCESS,
  payload: importSyncRecord,
});
