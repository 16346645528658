import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import { Card, Row, Col } from "reactstrap";
import table_icon from "../../../../assets/images/pos/table_icon.png";

const TableSelection = () => {
  const tablesData = [
    { id: 1, name: "Table 1", status: "Available", price: 100 },
    { id: 2, name: "Table 2", status: "Used", price: 200 },
    { id: 3, name: "Table 3", status: "Ordering", price: 150 },
    { id: 4, name: "Table 4", status: "Ordering", price: 180 },
    { id: 5, name: "Table 5", status: "Available", price: 120 },
    { id: 6, name: "Table 6", status: "Ordering", price: 170 },
    { id: 7, name: "Table 7", status: "Used", price: 210 },
    { id: 8, name: "Table 8", status: "Ordering", price: 160 },
    { id: 9, name: "Table 9", status: "Ordering", price: 190 },
    { id: 10, name: "Table 10", status: "Ordering", price: 140 },
  ];

  const [filter, setFilter] = useState("All");

  const filteredTables = filter === "All" ? tablesData : tablesData.filter((table) => table.status === filter);

  const statusColors = {
    Available: "border-green-500 text-green-500",
    Used: "border-blue-500 text-blue-500",
    Ordering: "border-yellow-500 text-yellow-500",
  };

  return (
    <Card className="mb-2 tw-flex-grow overflow-auto">
      <div className="tw-mt-3">
        <Row>
          <Col md={2} className="p-4">
            <SimpleBar className="overflow-auto">
              <div className="d-flex flex-column gap-4">
                <button
                  className="px-2 py-4 border rounded hover:bg-gray-200 flex items-center justify-center"
                  onClick={() => setFilter("All")}
                >
                  All ({tablesData.length})
                </button>
                <button
                  className="px-2 py-4 border rounded hover:bg-gray-200 flex items-center justify-center"
                  onClick={() => setFilter("Available")}
                >
                  Available ({tablesData.filter((t) => t.status === "Available").length})
                </button>
                <button
                  className="px-2 py-4 border rounded hover:bg-gray-200 flex items-center justify-center"
                  onClick={() => setFilter("Used")}
                >
                  Used ({tablesData.filter((t) => t.status === "Used").length})
                </button>
                <button
                  className="px-2 py-4 border rounded hover:bg-gray-200 flex items-center justify-center"
                  onClick={() => setFilter("Ordering")}
                >
                  Ordering ({tablesData.filter((t) => t.status === "Ordering").length})
                </button>
              </div>
            </SimpleBar>
          </Col>

          <Col md={9} className="p-4">
            <Row className="g-4">
              {filteredTables.map((table) => (
                <Col key={table.id} xxl={2} xl={3} sm={6} xs={12} className="mb-2">
                  <div className="h-full d-flex flex-column justify-content-between border-2 p-4 rounded-3 text-center">
                    <div className="mb-2">
                      <img src={table_icon} alt="Table Icon" className="w-16 h-16 mx-auto" />
                    </div>
                    <div className="font-bold text-center text-gray-700 truncate">{table.name}</div>
                    <div className={`text-lg font-semibold ${statusColors[table.status]} mb-2`}>{table.status}</div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default TableSelection;
