import { Actions } from "../../../../Components/constants/common";
import {
  LIST_NOTIFICATION,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
  LIST_NOTIFICATION_SUCCESS,
  LIST_NOTIFICATION_FAIL,
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
  RESET_NOTIFICATION_STATE,
} from "./actionType";

const INIT_STATE = {
  notifications: [],
  notification: {},
  error: null,
  success: false,
  loading: false,
  message: "",
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const Notifications = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_NOTIFICATION:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_NOTIFICATION_SUCCESS:
      return {
        ...state,
        message: "Get notifications success",
        loading: false,
        success: true,
        notifications: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_NOTIFICATION_FAIL:
      return {
        ...state,
        message: "Get notifications failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_NOTIFICATION:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        message: "Get notification success",
        loading: false,
        success: true,
        notification: action.payload,
        action: Actions.EMPTY,
      };

    case GET_NOTIFICATION_FAIL:
      return {
        ...state,
        message: "Get notification failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_NOTIFICATION:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        message: "Delete notification success",
        loading: false,
        success: true,
        notifications: state.notifications.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_NOTIFICATION_FAIL:
      return {
        ...state,
        message: "Delete notification failed",
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case RESET_NOTIFICATION_STATE:
      return INIT_STATE;

    default:
      return { ...state };
  }
};

export default Notifications;
