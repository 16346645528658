import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";

// Redux States
import { LIST_NOTIFICATION, DELETE_NOTIFICATION, GET_NOTIFICATION } from "./actionType";

import {
  deleteNotificationSuccess,
  deleteNotificationFail,
  listNotificationSuccess,
  listNotificationFail,
  getNotificationSuccess,
  getNotificationFail,
} from "./action";

import { notificationsAPI } from "../../../../helpers/resource_helper";

function* listNotification({ payload: { ...rest } }) {
  try {
    const response = yield call(notificationsAPI.customList, "list", { ...rest });
    yield put(listNotificationSuccess(response));
  } catch (error) {
    yield put(listNotificationFail(error));
  }
}

function* getNotification({ payload: id }) {
  try {
    const response = yield call(notificationsAPI.get, { id });
    yield put(getNotificationSuccess(response));
  } catch (error) {
    yield put(getNotificationFail(error));
  }
}

function* deleteNotifications({ payload: { notification, handleAfterSubmit } }) {
  try {
    const response = yield call(notificationsAPI.delete, { id: notification.id });
    yield put(deleteNotificationSuccess(notification));
    yield call(handleAfterSubmit);
    toast.success(response?.message, { autoClose: 3000 });
  } catch (error) {
    yield put(deleteNotificationFail(error));
    toast.error(error.toString(), { autoClose: 3000 });
  }
}

export function* watchGetNotifications() {
  yield takeEvery(LIST_NOTIFICATION, listNotification);
}

export function* watchDeleteNotifications() {
  yield takeEvery(DELETE_NOTIFICATION, deleteNotifications);
}

export function* watchGetNotification() {
  yield takeEvery(GET_NOTIFICATION, getNotification);
}

function* notificationsSaga() {
  yield all([fork(watchGetNotification), fork(watchGetNotifications), fork(watchDeleteNotifications)]);
}

export default notificationsSaga;
